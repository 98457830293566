import { Project } from '../common/contracts';

export const getSelectProjectOptions = (
  projectsDetails: Project[],
  allowedProjects: string[] = []
) => {
  const filteredProjectsData = allowedProjects.length > 0 ?
    projectsDetails.filter(project => allowedProjects.includes(project.publisherId)) : projectsDetails;

  return filteredProjectsData.map(({ publisherId, publisherName }) => ({
    content: publisherName,
    key: publisherId,
    value: publisherId,
    filterBy: publisherId
  }))
};

export const getProjectGUIDfromURL = () => {
  const regex = /project\/([a-fA-F0-9]+)\//;
  const urlObj = new URL(window.location.href);
  const path = urlObj.pathname;
  const match = path.match(regex);

  if (match) {
    return match[1];
  } else {
    return null;
  }
};
