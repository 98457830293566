import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IPermissions } from 'common/permissions.types';
import { IntegrationSettingsData } from 'components/Settings/Settings.types';
import { permissionsUtil } from 'utils/permissionsUtil'

import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';
import { localStorageUtil } from '../utils/localStorageUtil';

export default function useIntegration(currentPublisherId?: string) {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const canAccessIntegration = currentPublisherId ? !!permissionsUtil.canAccessIntegration(
      localStorageUtil.get<IPermissions>(
          ELocalStorageKeys.PERMISSIONS
      ) || {},
      currentPublisherId
  ) : false

  const getIntegration = useQuery<IntegrationSettingsData, Error>({
    ...fetchConfig.general,
    queryKey: [EQueryKeys.INTEGRATION],
    queryFn: async () => {
      return await axios.get(
        getApiRouteOrMock(EApiRoutes.INTEGRATION),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    enabled: canAccessIntegration
  });

  const getPublisherEvents = useQuery<IntegrationSettingsData, Error>({
    ...fetchConfig.general,
    queryFn: async () => {
      return await axios.get(
        `${getApiRouteOrMock(EApiRoutes.INTEGRATION)}/publisher-webhook-events-name`,
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    enabled: canAccessIntegration
  });

  const updateIntegration = useMutation(
    async (data: Partial<IntegrationSettingsData>) => {
      return await axios.put(getApiRouteOrMock(EApiRoutes.INTEGRATION), data);
    },
    {
      onSuccess: (updatedIntegrationData) => {
        queryClient.setQueryData([EQueryKeys.INTEGRATION], (oldIntegrationData: any) => ({
          ...oldIntegrationData,
          ...updatedIntegrationData,
        }));
      }
     }
  );

  return {
    getIntegration,
    updateIntegration,
    getPublisherEvents
  };
}
