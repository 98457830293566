import { TRIMMED_INPUT_REGEX } from 'constants/constants';
import { required } from 'utils/errorsTextHelper';
import * as yup from 'yup';

export const useGetStoreValidationSchema = () => {
  let storeValidationSchema = yup.object().shape({
    defaultBanner: yup.string().notRequired(),
    sectionName: yup.string(),
    sectionId: yup.string(),
    buttonColor: yup.object().shape({
      colorOne: yup.string().required(required('Button main color')),
      colorTwo: yup.string()
    }),
    addToHomeScreen: yup.object().shape({
      active: yup.boolean().notRequired(),
      buttonImage: yup.string().notRequired(),
      iconImage: yup.string().required(required('Icon Image')),
      shortcutName: yup
        .string()
        .trim()
        .when('active', {
          is: true,
          then: yup
            .string()
            .trim()
            .matches(TRIMMED_INPUT_REGEX, 'Store Title should not be spaces')
            .required('Store Title is required'),
          otherwise: yup.string().notRequired()
        })
    }),
    bundleBorderColor: yup.object().shape({
      colorOne: yup.string().required(required('Bundle frame main color')),
      colorTwo: yup.string()
    })
  });

  return { storeValidationSchema };
};
