export const geo = [
  {
    currencyName: 'US Dollar',
    currencyCode: 'USD',
    currencySymbol: '$',
    multiplier: 100
  },
  {
    currencyName: 'Argentine Peso',
    currencyCode: 'ARS',
    currencySymbol: 'AR$',
    multiplier: 100
  },
  {
    currencyName: 'Australian Dollar',
    currencyCode: 'AUD',
    currencySymbol: 'AU$',
    multiplier: 100
  },
  {
    currencyName: 'Euro',
    currencyCode: 'EUR',
    currencySymbol: '€',
    multiplier: 100
  },
  {
    currencyName: 'Bosnia',
    currencyCode: 'Herzegovina Convertible Mark',
    currencySymbol: 'BAM',
    multiplier: null
  },
  {
    currencyName: 'Brazilian Real',
    currencyCode: 'BRL',
    currencySymbol: 'R$',
    multiplier: 100
  },
  {
    currencyName: 'Bulgarian Lev',
    currencyCode: 'BGN',
    currencySymbol: 'лв',
    multiplier: 100
  },
  {
    currencyName: 'Canadian Dollar',
    currencyCode: 'CAD',
    currencySymbol: 'CA$',
    multiplier: 100
  },
  {
    currencyName: 'Chilean Peso',
    currencyCode: 'CLP',
    currencySymbol: 'CL$',
    multiplier: 1
  },
  {
    currencyName: 'Chinese Yuan',
    currencyCode: 'CNY',
    currencySymbol: '¥',
    multiplier: 100
  },
  {
    currencyName: 'Czech Republic Koruna',
    currencyCode: 'CZK',
    currencySymbol: 'Kč',
    multiplier: 100
  },
  {
    currencyName: 'Danish Krone',
    currencyCode: 'DKK',
    currencySymbol: 'kr',
    multiplier: 100
  },
  {
    currencyName: 'Egyptian Pound',
    currencyCode: 'EGP',
    currencySymbol: 'E£',
    multiplier: 100
  },
  {
    currencyName: 'Hong Kong Dollar',
    currencyCode: 'HKD',
    currencySymbol: 'HK$',
    multiplier: 100
  },
  {
    currencyName: 'Hungarian Forint',
    currencyCode: 'HUF',
    currencySymbol: 'Ft',
    multiplier: 100
  },
  {
    currencyName: 'Icelandic Króna',
    currencyCode: 'ISK',
    currencySymbol: 'kr',
    multiplier: 100
  },
  {
    currencyName: 'Indian Rupee',
    currencyCode: 'INR',
    currencySymbol: '₹',
    multiplier: 100
  },
  {
    currencyName: 'Indonesian Rupiah',
    currencyCode: 'IDR',
    currencySymbol: 'Rp',
    multiplier: 100
  },
  {
    currencyName: 'Israeli New Sheqel',
    currencyCode: 'ILS',
    currencySymbol: '₪',
    multiplier: 100
  },
  {
    currencyName: 'Japanese Yen',
    currencyCode: 'JPY',
    currencySymbol: '¥',
    multiplier: 1
  },
  {
    currencyName: 'Malaysian Ringgit',
    currencyCode: 'MYR',
    currencySymbol: 'RM',
    multiplier: 100
  },
  {
    currencyName: 'Mexican Peso',
    currencyCode: 'MXN',
    currencySymbol: 'MX$',
    multiplier: 100
  },
  {
    currencyName: 'Moroccan Dirham',
    currencyCode: 'MAD',
    currencySymbol: 'د.م.',
    multiplier: 100
  },
  {
    currencyName: 'New Zealand Dollar',
    currencyCode: 'NZD',
    currencySymbol: 'NZ$',
    multiplier: 100
  },
  {
    currencyName: 'Nigerian Naira',
    currencyCode: 'NGN',
    currencySymbol: '₦',
    multiplier: 100
  },
  {
    currencyName: 'Norwegian Krone',
    currencyCode: 'NOK',
    currencySymbol: 'kr',
    multiplier: 100
  },
  {
    currencyName: 'Pakistani Rupee',
    currencyCode: 'PKR',
    currencySymbol: '₨',
    multiplier: 100
  },
  {
    currencyName: 'Peruvian Nuevo Sol',
    currencyCode: 'PEN',
    currencySymbol: 'S/',
    multiplier: 100
  },
  {
    currencyName: 'Philippine Peso',
    currencyCode: 'PHP',
    currencySymbol: '₱',
    multiplier: 100
  },
  {
    currencyName: 'Polish Zloty',
    currencyCode: 'PLN',
    currencySymbol: 'zł',
    multiplier: 100
  },
  {
    currencyName: 'Romanian Leu',
    currencyCode: 'RON',
    currencySymbol: 'lei',
    multiplier: 100
  },
  {
    currencyName: 'Saudi Riyal',
    currencyCode: 'SAR',
    currencySymbol: 'SAR',
    multiplier: 100
  },
  {
    currencyName: 'Serbian Dinar',
    currencyCode: 'RSD',
    currencySymbol: 'РСД',
    multiplier: 100
  },
  {
    currencyName: 'Singapore Dollar',
    currencyCode: 'SGD',
    currencySymbol: 'SG$',
    multiplier: 100
  },
  {
    currencyName: 'South African Rand',
    currencyCode: 'ZAR',
    currencySymbol: 'R',
    multiplier: 100
  },
  {
    currencyName: 'South Korean Won',
    currencyCode: 'KRW',
    currencySymbol: '₩',
    multiplier: 1
  },
  {
    currencyName: 'Swedish Krona',
    currencyCode: 'SEK',
    currencySymbol: 'kr',
    multiplier: 100
  },
  {
    currencyName: 'Swiss Franc',
    currencyCode: 'CHF',
    currencySymbol: 'CHF',
    multiplier: 100
  },
  {
    currencyName: 'New Taiwan Dollar',
    currencyCode: 'TWD',
    currencySymbol: 'NT$',
    multiplier: 100
  },
  {
    currencyName: 'Thai Baht',
    currencyCode: 'THB',
    currencySymbol: '฿',
    multiplier: 100
  },
  {
    currencyName: 'Turkish Lira',
    currencyCode: 'TRY',
    currencySymbol: '₺',
    multiplier: 100
  },
  {
    currencyName: 'United Arab Emirates Dirham',
    currencyCode: 'AED',
    currencySymbol: 'د.إ',
    multiplier: 100
  },
  {
    currencyName: 'Pound Sterling',
    currencyCode: 'GBP',
    currencySymbol: '£',
    multiplier: 100
  },
  {
    currencyName: 'Vietnamese Dong',
    currencyCode: 'VND',
    currencySymbol: '₫',
    multiplier: 1
  },
  {
    currencyName: 'Iraqi Dinar',
    currencyCode: 'IQD',
    currencySymbol: 'ع.د',
    multiplier: 1000
  },
  {
    currencyName: 'Jordanian Dinar',
    currencyCode: 'JOD',
    currencySymbol: 'د.أ',
    multiplier: 1000
  }
];
