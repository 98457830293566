import { FC, SyntheticEvent, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTopBar from 'components/Topbar/PageTopBar';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import { TabsPanel } from 'design-system/TabPanel/TabPanel';
import { permissionsUtil } from 'utils/permissionsUtil';
import usePricing from 'api/usePricing';

enum EPricingFormState {
  PRICING_POINTS = 'points',
  SETTINGS = 'settings'
}

export const PricingView: FC = () => {
  const navigate = useNavigate();
  const { publisherId } = useParams();
  const { getPricings } = usePricing(publisherId);
  const location = useLocation();
  const currentTab = location.pathname.split('/')[4];
  const [tab, setTab] = useState(currentTab);

  const handleTabChange = (
    event: SyntheticEvent,
    newValue: EPricingFormState
  ) => {
    setTab(newValue);
    const newPath = `/project/${publisherId}/pricing/${newValue}`;
    navigate(newPath);
  };

  const isSuperAdminUser = publisherId
    ? permissionsUtil.isSuperAdminByProject(publisherId)
    : false;

  const tabs = [
    { label: 'Price Points', value: EPricingFormState.PRICING_POINTS }
  ];

  if (isSuperAdminUser) {
    tabs.push({ label: 'Settings', value: EPricingFormState.SETTINGS });
  }

  return (
    <TabsPanel
      tabs={tabs}
      headerComponent={
        <PageTopBar
          withTabsDesign={true}
          disable={false}
          headline="Pricing"
          buttons={[
            {
              text: 'Create New Pricing',
              action: () => navigate('./form'),
              disabled: !permissionsUtil.canUserEdit() || getPricings.isLoading,
              hidden: !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.PRICING,
                EPermissionAction.MANAGE
              )
            }
          ]}
        />
      }
      handleTabChange={handleTabChange}
      activeTabVal={tab}
    />
  );
};
