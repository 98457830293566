import { useMemo, useState } from 'react';

import { Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { UpdateInfoData } from '../../views/Profile/ProfileView.types';
import AcCard from '../AcCard/AcCard';
import AcInput from '../AcInput/AcInput';
import ActionButton from '../ActionButton/ActionButton';

import './style.scss';
import useUsers from '../../api/useUsers';
import { ENotificationType } from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';

const PersonalInfo = () => {
  const { getUsers, updateUser } = useUsers({
    shouldFetchUsersData: true,
    enableFeatureFlags: false
  });
  const userData = getUsers?.data;
  const { enqueueSnackbar } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateInfo = (
      data: Partial<UpdateInfoData>,
      cb?: Function,
      errorCb?: Function
  ) => {
    updateUser.mutate(data, {
      onSuccess: (data: any) => {
        enqueueSnackbar(
            `User info updated successfully`,
            ENotificationType.SUCCESS
        );
        cb?.();
      },
      onError: (data: any) => {
        enqueueSnackbar(data.response?.data?.message, ENotificationType.ERROR);
        errorCb?.();
      }
    });
  };

  let updateInfoSchema = yup.object().shape({
    name: yup.string().required()
  });

  const initialValues = useMemo(() => ({name: userData?.name || ''}), [userData])
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    dirty,
    isValid
  } = useFormik({
    initialValues,
    validationSchema: updateInfoSchema,
    enableReinitialize: true,
    onSubmit: async (values, {resetForm}) => {
      setIsSubmitting(true);
      updateInfo(
        values,
        async () => {
          setIsSubmitting(false);
          await getUsers.refetch();
          resetForm();
        },
        () => setIsSubmitting(false)
      );
    }
  });

  return (
    <Grid container className="formContent">
      <Grid item xs={5} className="formContent-minWIdth">
        <Stack>
          <AcCard stackContainer={false} title="General">
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid className="formContent-item" item xs={12} sm={6} md={6}>
                <AcInput
                  header="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && !!errors.name}
                />
              </Grid>
              <Grid className="formContent-item" item xs={12} sm={6} md={6}>
                <AcInput
                  header="Email"
                  name="email"
                  value={userData.email}
                  disabled
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
        <Grid item mt={3} xs={12}>
          <ActionButton
            text="Update"
            variant="outlined"
            onClick={submitForm}
            disabled={!isValid || !dirty || isSubmitting}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
