import { useMutation, useQuery } from '@tanstack/react-query';

import { Product } from '../components/ProductsForm/ProductsForm.types';
import { EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export function useProduct(
  currentPublisherId?: string,
  productId?: string
) {
  const axios = useAxios();

  const getProduct = useQuery({
    ...fetchConfig.single,
    queryKey: [EQueryKeys.PRODUCT],
    queryFn: async () => {
      return await axios.get(
        `${getApiRouteOrMock(EApiRoutes.PRODUCTS)}/${productId}`,
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    enabled:!!currentPublisherId && !!productId
  });

  const getProducts = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.PRODUCTS],
    queryFn: async () => {
      return await axios.get(
        getApiRouteOrMock(EApiRoutes.PRODUCTS),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    enabled: !!currentPublisherId
  });

  const addProduct = useMutation(async (newProduct: Product) => {
    return await axios.post(getApiRouteOrMock(EApiRoutes.PRODUCTS), newProduct);
  });

  const deleteProduct = useMutation(async (productId: string) => {
    return await axios.del(
      `${getApiRouteOrMock(EApiRoutes.PRODUCTS)}/${productId}`
    );
  });

  const updateProduct = useMutation(
    async (updatedProductForm: { id: string; data: Partial<Product> }) => {
      return await axios.put(`${getApiRouteOrMock(EApiRoutes.PRODUCTS)}`, {
        id: updatedProductForm.id,
        ...updatedProductForm.data
      });
    }
  );

  return {
    getProduct,
    getProducts,
    addProduct,
    deleteProduct,
    updateProduct
  };
}
