import { IPermissions } from 'common/permissions.types';
import { ELocalStorageKeys } from 'constants/enums';
import { localStorageUtil } from 'utils/localStorageUtil';

export default function usePermissions(permissions?: any) {
  const getPermissions = () => {
    return localStorageUtil.get<IPermissions>(ELocalStorageKeys.PERMISSIONS);
  };

  const setPermissions = () => {
    const projects = localStorageUtil.get(
      ELocalStorageKeys.USER_DETAILS
    ).projects;
    if (projects) {
      const returnedProjects: any = {};
      projects.forEach((p: any) => {
        returnedProjects[p.publisherId] = {};
        p.permissions.forEach((permission: any) => {
          const splittedPermission = permission.split(':');
          if (returnedProjects[p.publisherId][splittedPermission[0]]) {
            returnedProjects[p.publisherId][splittedPermission[0]].push(
              splittedPermission[1]
            );
          } else {
            returnedProjects[p.publisherId][splittedPermission[0]] = [
              splittedPermission[1]
            ];
          }
        });
      });
      localStorageUtil.set(ELocalStorageKeys.PERMISSIONS, returnedProjects);
    }
  };

  return {
    getPermissions,
    setPermissions
  };
}
