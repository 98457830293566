import * as yup from 'yup';

import { required } from '../../../utils/errorsTextHelper';

export const storeLayoutValidationSchema = yup.object().shape({
  login: yup.object().shape({
    text: yup.string().required(required('Header')),
    textColor: yup.string().required(required('Font color')),
    textSize: yup.string().required(required('Font size')),
    textWeight: yup.string().required(required('Font weight')),
    otpButton: yup.object().shape({
      backgroundColor: yup.object().shape({
        colorOne: yup.string().required(required('Button main color')),
        colorTwo: yup.string()
      }),
      text: yup.string(),
      icon: yup.string(),
      textColor: yup.object().shape({
        colorOne: yup.string().required(required('Text main color')),
        colorTwo: yup.string()
      })
    }),
    consentSection: yup.object().shape({
      htmlText: yup.string(),
      consentRequired: yup.boolean()
    })
  }),
  general: yup.object().shape({
    productsQuantityFormat: yup.object().shape({
      milSeparator: yup.string().required(required('Million separator'))
    }),
    specialOfferQuantityFormat: yup.object().shape({
      milSeparator: yup.string().required(required('Million separator'))
    })
  }),
  completedScreen: yup.object().shape({
    headerText: yup.string().required(required('Header')),
    headerColor: yup.object().shape({
      colorOne: yup.string().required(required('Button main color')),
      colorTwo: yup.string()
    }),
    headerSize: yup.string().required(required('Header font size')),
    headerWeight: yup.string().required(required('Header font weight')),
    text: yup.string(),
    textColor: yup.string(),
    textSize: yup.string(),
    textWeight: yup.string(),
    backToGameButtonText: yup.string().required(required('Back to game text'))
  }),
  storeScreen: yup.object().shape({
    bundleBorderColor: yup.object().shape({
      colorOne: yup.string().required(required('Bundle frame main color')),
      colorTwo: yup.string()
    })
  }),
  integration: yup.object().shape({
    deepLinks: yup.object().shape({
      ios: yup
        .string()
        .matches(/.+:\/\/.*/, 'Apple deep link must be a valid url'),
      android: yup
        .string()
        .matches(/.+:\/\/.*/, 'Android deep link must be a valid url'),
      web: yup
        .string()
        .matches(/.+:\/\/.*/, 'Web deep link must be a valid url')
    })
  })
});
