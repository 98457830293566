import React, { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Box, InputAdornment, Stack } from '@mui/material';
import Decimal from 'decimal.js';

import { GeoItem } from '../../api/usePricing';
import AcInput from '../AcInput/AcInput';

const PricingTableInputCell = ({
  pricePoint,
  onPriceUpdate,
  countryData,
  minPrice,
  maxPrice,
  disabled = false
}: {
  pricePoint: number;
  onPriceUpdate: (price: Decimal, currencyCode: string) => void;
  countryData?: GeoItem;
  minPrice: string;
  maxPrice: string;
  disabled?: boolean;
}) => {
  const [isChangeable, setIsChangeable] = useState(false);
  const [updatedPricePoint, setUpdatedPricePoint] = useState(pricePoint);
  const [priceValue, setPriceValue] = useState(pricePoint);
  const ref = React.createRef();

  useEffect(() => {
    if (isChangeable && ref.current) {
      (ref.current as HTMLElement).querySelector('input')?.focus();
    }
  }, [isChangeable, ref]);

  useEffect(() => {
    setUpdatedPricePoint(pricePoint);
  }, [pricePoint]);

  return (
    <>
      {isChangeable ? (
        <Stack onClick={() => setIsChangeable(false)} mt={-1.25}>
          <AcInput
            ref={ref}
            size="small"
            type="number"
            variant="standard"
            helperText={
              priceValue < parseFloat(minPrice)
                ? `Price must be larger than ${minPrice}`
                : priceValue > parseFloat(maxPrice)
                  ? `Price must be smaller than ${maxPrice}`
                  : ''
            }
            error={priceValue < parseFloat(minPrice)}
            slotProps={{
              htmlInput: {
                min: minPrice,
                max: maxPrice,
                style: {
                  fontSize: '14px'
                }
              },
              input: {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{
                      marginRight:
                        countryData &&
                        countryData?.currencyDetails.currencySymbol.length >= 3
                          ? '28px'
                          : countryData?.currencyDetails.currencySymbol
                                .length === 2
                            ? '20px'
                            : '8px'
                    }}
                  >
                    {countryData?.currencyDetails.currencySymbol}
                  </InputAdornment>
                )
              }
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (
                countryData!.currencyDetails.multiplier === 1 &&
                (e.key === '.' || e.key === ',')
              ) {
                e.preventDefault();
                return;
              }
              const newPrice = parseFloat((e.target as HTMLInputElement).value);
              if (
                e.key === 'Enter' &&
                newPrice >= parseFloat(minPrice) &&
                newPrice <= parseFloat(maxPrice)
              ) {
                setIsChangeable(false);
                onPriceUpdate(
                  new Decimal(
                    countryData?.countryCode3 === 'ISL'
                      ? parseFloat(
                          (e.target as HTMLInputElement).value
                        ).toFixed(0)
                      : (e.target as HTMLInputElement).value
                  ).times(new Decimal(countryData!.currencyDetails.multiplier)),
                  countryData!.countryCode3
                );
              }
              e.stopPropagation();
            }}
            onKeyUp={(e) => {
              const newPrice = (e.target as HTMLInputElement).value;
              setPriceValue(parseFloat(newPrice));
            }}
            onBlur={(e) => {
              const newPrice = (e.target as HTMLInputElement).value;
              e.target.value &&
                parseFloat(newPrice) >= parseFloat(minPrice) &&
                parseFloat(newPrice) <= parseFloat(maxPrice) &&
                onPriceUpdate(
                  new Decimal((e.target as HTMLInputElement).value).times(
                    new Decimal(countryData!.currencyDetails.multiplier)
                  ),
                  countryData!.countryCode3
                );
              setIsChangeable(false);
            }}
          />
        </Stack>
      ) : (
        <Stack
          onClick={() => !disabled && setIsChangeable(true)}
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.5 : 1
          }}
          width="100%"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {`${countryData?.currencyDetails.currencySymbol}
    ${
      // If ICELAND
      countryData?.countryCode3 === 'ISL'
        ? pricePoint.toFixed(0)
        : updatedPricePoint
          ? countryData?.currencyDetails.multiplier === 1
            ? updatedPricePoint.toFixed(0)
            : updatedPricePoint.toFixed(2)
          : 0
    }`}
          <Box ml={1}>
            <EditIcon
              sx={{
                color: 'gray',
                fontSize: '14px'
              }}
            />
          </Box>
        </Stack>
      )}
    </>
  );
};

export default PricingTableInputCell;
