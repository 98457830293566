import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import useOffers from 'api/useOffers';
import { useProduct } from 'api/useProduct';
import {
  RollingOfferProductSequence,
  RollingSequenceProduct
} from 'common/contracts';
import { MIN_ROLLING_SUB_OFFERS, MIN_USD_PRICE } from 'constants/constants';
import { EProductType, OfferType } from 'constants/enums';
import {
  AutocompleteInput,
  ImgOption
} from 'design-system/AutocompleteInput/AutocompleteInput';
import { transformQuantityValue } from 'utils/formattingUtil';
import { v4 as uuidv4 } from 'uuid';
import { TabsProps } from 'views/RollingOffers/types';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import DataTable from 'components/DataTable/DataTable';
import { Product } from 'components/ProductsForm/ProductsForm.types';

import CardExtrasCollapse from './CardExtrasCollapse';

import './style.scss';

interface CardProps extends TabsProps {
  productSequenceItem: RollingOfferProductSequence;
  sequenceIndex: number;
}
const SubOfferCard: React.FC<CardProps> = ({
  formikProps,
  rollingOfferId,
  productSequenceItem,
  sequenceIndex,
  productsSequence,
  setProductsSequence
}) => {
  const { setFieldValue, handleChange, touched, errors, handleBlur } =
    formikProps;

  const { publisherId } = useParams();
  const { data: productsData, isLoading: isProductsLoading } =
    useProduct(publisherId).getProducts;
  const { formatProductQuantity } = useOffers(
    rollingOfferId,
    OfferType.ROLLING_OFFER
  );

  const isSubOfferFree =
    productSequenceItem?.setAsFree ||
    productSequenceItem?.priceInUsdCents === 0;

  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const [collapseOpen, setCollapseOpen] = useState(!isSubOfferFree);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [currentProductType, setCurrentProductType] = useState<EProductType>(
    EProductType.QUANTITY
  );
  const [localQuantities, setLocalQuantities] = useState<
    Record<number, string>
  >({});
  const addProductClickedRef = useRef(false);

  const hasMissingProductDetails = useMemo(() => {
    return (
      !productSequenceItem.products.length ||
      productSequenceItem.products.some(
        ({ product, quantity }) => !product?.publisherProductId || !quantity
      )
    );
  }, [productSequenceItem]);
  
  useEffect(() => {
    if (!productSequenceItem?.products?.length) {
      setProductsSequence((prevSequence: RollingOfferProductSequence[]) =>
        prevSequence.map((item, index) =>
          index === sequenceIndex
            ? { ...item, products: [{ product: { images: [] } }] }
            : item
        )
      );
    }
  }, [productSequenceItem?.products?.length]);

  const getProductOptions = useMemo(() => {
    if (!productsData?.result) return [];
    return productsData.result.map((product: Product) => ({
      ...product,
      url: product.images?.[0]?.url,
      name: product.name,
      key: product.name,
      value: product.productId,
      type: product.type,
      publisherProductId: product.publisherProductId
    }));
  }, [productsData?.result]);

  const rows = useMemo(() => {
    if (!productSequenceItem.products) {
      return [
        {
          _id: uuidv4(),
          images: []
        }
      ];
    }

    return productSequenceItem.products?.map((prod, index) => {
      const productDetails = productsData?.result.find(
        (product: Product) => product?.productId === prod?.product?.productId
      );
      return {
        ...prod.product,
        amount: prod.quantity,
        name: productDetails?.name || '',
        productIndex: index,
        sequenceIndex,
        _id: index + sequenceIndex
      };
    });
  }, [productsData, productSequenceItem.products, sequenceIndex]);

  const updateSubOfferData = (
    updatedData: Partial<RollingOfferProductSequence>,
    e?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setProductsSequence((prevSequence: RollingOfferProductSequence[]) => {
      const updatedSequence = [...prevSequence];
      updatedSequence[sequenceIndex] = {
        ...updatedSequence[sequenceIndex],
        ...updatedData
      };
      setFieldValue('productsSequence', updatedSequence);
      return updatedSequence;
    });
    handleChange(e);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Product',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const { productIndex } = params.row;

        return (
          <AutocompleteInput
            options={getProductOptions || []}
            onChange={(newValue) => {
              handleProductChange(params.row.productIndex, newValue);
            }}
            currentOption={getProductOptions.find(
              (option: ImgOption) => option.name === params.row.name
            )}
            placeholder="Search"
            autoComplete="off"
            dataTestId={`rollingOfferProduct${productIndex + 1}`}
            dataTestListId="dashRollingOfferProductList"
          />
        );
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const { productIndex } = params.row;
        return (
          <>
            <AcInput
              name="amount"
              value={
                localQuantities[productIndex] ??
                (productSequenceItem?.products?.[productIndex]?.quantity || '')
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleQuantityChange(e, productIndex)
              }
              onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                handleQuantityBlur(e, productIndex)
              }
              type="number"
              slotProps={{
                htmlInput: {
                  min: 1
                }
              }}
            />
            {productSequenceItem?.products?.length > 1 && (<DeleteOutlineTwoToneIcon
              onClick={() => handleRemoveProduct(productIndex)}
              className="danger"
              sx={{ cursor: 'pointer', position: 'absolute', right: '10px' }}
            />)}
          </>
        );
      }
    }
  ];

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const parsedValue = Number(inputValue);

    const invalidPrice = !inputValue || isNaN(parsedValue) || parsedValue < MIN_USD_PRICE;
    const updatedProductSequenceItem =
      invalidPrice
        ? { priceInUsdCents: undefined }
        : { priceInUsdCents: parsedValue, setAsFree: parsedValue === 0 };
    updateSubOfferData(updatedProductSequenceItem, e);
  };

  const handleSetAsFreeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setFieldValue('setAsFree', isChecked);
    const updatedProductSequenceItem = {
      setAsFree: isChecked,
      priceInUsdCents: isChecked
        ? 0
        : Math.max(MIN_USD_PRICE, productSequenceItem?.priceInUsdCents),
      ...(!isChecked &&
        productSequenceItem?.productSale && {
        productSale: productSequenceItem?.productSale
      },
        productSequenceItem?.priceDiscount && {
          priceDiscount: productSequenceItem?.priceDiscount
        })
    };
    updateSubOfferData(updatedProductSequenceItem, e);
    setCollapseOpen(!isChecked);
  };

  const handleProductChange = (
    productIndex: number,
    newValue: ImgOption | null
  ) => {
    if (productIndex == null) return;
    if (!newValue) {
      const updatedProductSequenceItem = {
        products: productSequenceItem?.products?.map((product, index) =>
          index === productIndex
            ? {
              ...product,
              product: undefined,
              images: { product: undefined }
            }
            : product
        )
      };
      updateSubOfferData(updatedProductSequenceItem);
      return;
    }

    const selectedProduct = getProductOptions.find(
      (option: ImgOption) => option.value === newValue.value
    );
    if (selectedProduct) {
      setCurrentProductType(selectedProduct.type);
      const updatedProductSequenceItem = {
        products: productSequenceItem?.products?.map(
          (product: RollingSequenceProduct, index: number) =>
            index === productIndex
              ? {
                ...product,
                product: {
                  ...selectedProduct
                },
                images: {
                  product: selectedProduct?.url
                }
              }
              : product
        )
      };
      updateSubOfferData(updatedProductSequenceItem);
    }
  };

  const handleQuantityChange = (
    e: ChangeEvent<HTMLInputElement>,
    productIndex: number
  ) => {
    if (productIndex == null) return;
    const inputValue = e.target.value;

    const newOffersQuantity =
      inputValue.length !== 0 ? transformQuantityValue(inputValue) : inputValue;

    if (Number(newOffersQuantity) < 1) return;
    const updatedProductSequenceItem = {
      products: productSequenceItem?.products?.map(
        (product: RollingSequenceProduct, index: number) =>
          index === productIndex
            ? {
              ...product,
              quantity: newOffersQuantity
            }
            : product
      )
    };
    updateSubOfferData(updatedProductSequenceItem);
    setLocalQuantities((prev) => ({
      ...prev,
      [productIndex]: inputValue
    }));
  };

  const handleQuantityBlur = async (
    e: React.FocusEvent<HTMLInputElement>,
    productIndex: number
  ) => {
    if (addProductClickedRef.current || productIndex == null) {
      return;
    }
    try {
      const formattedProductQuantity = await formatProductQuantity.mutateAsync({
        quantity: e.target.value,
        productType: currentProductType
      });

      const updatedProductSequenceItem = {
        products: productSequenceItem?.products?.map(
          (product: RollingSequenceProduct, index: number) =>
            index === productIndex
              ? {
                ...product,
                quantityDisplay: formattedProductQuantity.data
              }
              : product
        )
      };

      updateSubOfferData(updatedProductSequenceItem);
    } catch (error) {
      console.error('Error in handleQuantityBlur:', error);
    }
  };

  const handleRemoveProduct = (productIndex: number) => {
    if (productIndex == null) return;
    const updatedProductSequenceItem = {
      products: productSequenceItem?.products?.filter(
        (_: RollingSequenceProduct, index: number) => index !== productIndex
      )
    };
    updateSubOfferData(updatedProductSequenceItem);
  };

  const handleAddProduct = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    addProductClickedRef.current = true;

    const newProductIndex = productSequenceItem.products.length;
    const updatedProductSequenceItem = {
      products: [
        ...productSequenceItem.products,
        {
          product: {
            images: []
          }
        }
      ]
    };
    updateSubOfferData(updatedProductSequenceItem, e);

    setSelectionModel([newProductIndex]);

    setTimeout(() => {
      addProductClickedRef.current = false;
    }, 100);
  };

  const handleDeleteProductSequence = async (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    setProductsSequence((prevSequence: RollingOfferProductSequence[]) => {
      const updatedSequence = prevSequence
        .filter(
          (_: RollingOfferProductSequence, index: number) =>
            index !== sequenceIndex
        )
        .map((item: RollingOfferProductSequence, index: number) => ({
          ...item,
          index: index + 1
        }));
      setFieldValue('productsSequence', updatedSequence);
      return updatedSequence;
    });
    handleChange(e);
    setMenuAnchor(null);
  };

  const handleDuplicateProductSequence = async (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    setProductsSequence((prevSequence: RollingOfferProductSequence[]) => {
      const duplicatedSequence = {
        ...prevSequence[sequenceIndex],
        index: prevSequence.length + 1
      };

      const updatedSequence = [
        ...prevSequence.slice(0, sequenceIndex + 1),
        duplicatedSequence,
        ...prevSequence.slice(sequenceIndex + 1)
      ].map((item: RollingOfferProductSequence, index: number) => ({
        ...item,
        index: index + 1
      }));
      setFieldValue('productsSequence', updatedSequence);
      return updatedSequence;
    });
    handleChange(e);
    setMenuAnchor(null);
  };

  return (
    <AcCard stackContainer={false}>
      <Grid container className="sub-index-card">
        <Grid item xs={10} mb={2}>
          <Typography fontFamily="'Montserrat', sans-serif" fontWeight={500}>
            {`Offer ${productSequenceItem.index}`}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              setMenuAnchor(event.currentTarget)
            }
            sx={{ padding: 0 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchor}
            open={!!menuAnchor}
            onClose={() => setMenuAnchor(null)}
          >
            <MenuItem
              key={'duplicate'}
              onClick={handleDuplicateProductSequence}
              disabled={hasMissingProductDetails}
            >
              <div className="card-actions">
                <ContentCopyOutlinedIcon /> Duplicate
              </div>
            </MenuItem>
            <MenuItem
              key={'delete'}
              onClick={handleDeleteProductSequence}
              disabled={productsSequence.length <= MIN_ROLLING_SUB_OFFERS}
            >
              <div className="card-actions danger">
                <DeleteOutlineTwoToneIcon /> Delete
              </div>
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={6}>
          <AcInput
            header="Price"
            name="price"
            value={
              isSubOfferFree ? 'Free' : productSequenceItem?.priceInUsdCents
            }
            disabled={isSubOfferFree}
            onChange={handlePriceChange}
            onBlur={handleBlur}
            type={isSubOfferFree ? 'text' : 'number'}
            required
            slotProps={{
              htmlInput: { min: 0 },
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    {isSubOfferFree ? '' : '$'}
                  </InputAdornment>
                )
              }
            }}
            error={touched?.price && !!errors?.price}
            helperText={touched?.price ? errors.price?.toString() : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label="Set as Free"
            style={{ marginTop: '12px', marginLeft: '-2px' }}
            control={
              <Checkbox
                name={`setAsFree-${sequenceIndex}`}
                style={{ padding: '0' }}
                checked={isSubOfferFree}
                onChange={handleSetAsFreeChange}
              />
            }
            componentsProps={{
              typography: {
                fontSize: 14,
                sx: { marginLeft: '2px' },
                color: '#717188'
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={collapseOpen}>
            <Divider sx={{ width: '100%' }} />
            <CardExtrasCollapse
              formikProps={formikProps}
              currentPublisherId={publisherId}
              productSequenceItem={productSequenceItem}
              updateSubOfferData={updateSubOfferData}
              sequenceIndex={sequenceIndex}
              setProductsSequence={setProductsSequence}
            />
          </Collapse>
        </Grid>
        <Divider sx={{ width: '100%' }} />
        <Grid item xs={12}>
          <DataTable
            allowEmptyRows
            columns={columns}
            rows={rows}
            loading={isProductsLoading}
            className="custom-data-grid"
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleAddProduct}
            name="add-product"
            className="add-product-button"
            disabled={
              productSequenceItem?.products?.length > MIN_ROLLING_SUB_OFFERS
            }
          >
            + Add product
          </Button>
        </Grid>
      </Grid>
    </AcCard>
  );
};

export default SubOfferCard;
