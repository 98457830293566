import React from 'react';
import { useParams } from 'react-router-dom';
import useImages from '../../../api/useImages';
import DialogModal from '../../../components/Dialog/Dialog';
import { DialogButton } from '../../../components/Dialog/Dialog.types';
import { EButtonColor, ENotificationType } from '../../../constants/enums';
import { useNotifications } from '../../../hooks/useNotifications';

interface UploadAssetModalProps {
  isModalOpen: boolean;
  onModalClosed: () => void;
  setDeleteDialogOpen: (value: boolean) => void;
  selectedImageId: string;
}
const DeleteAssetModal = ({
  isModalOpen,
  onModalClosed,
  setDeleteDialogOpen,
  selectedImageId
}: UploadAssetModalProps) => {
  const { publisherId } = useParams();
  const { enqueueSnackbar } = useNotifications();
  const { getImages, deleteImage } = useImages(publisherId);

  const deleteImageHandler = async () => {
    deleteImage.mutate(selectedImageId, {
      onSuccess: () => {
        const deletedItemName =
          getImages.data.find((item: any) => item._id === selectedImageId)
            ?.name ?? '';
        getImages.refetch();
        enqueueSnackbar(
          `${deletedItemName} has been deleted`,
          ENotificationType.SUCCESS
        );
      },
      onError: (data: any) => {
        const errorMsg = `${data.response.data.message} ${data.response.data.data ? data.response.data.data.join(',') : ''}`;
        enqueueSnackbar(errorMsg, ENotificationType.ERROR);
      }
    });
  };

  const buttonsContent: DialogButton[] = [
    {
      text: 'Cancel',
      color: EButtonColor.SECONDARY,
      variant: 'outlined',
      func: () => setDeleteDialogOpen(false)
    },
    {
      text: 'Delete',
      color: EButtonColor.ERROR,
      variant: 'contained',
      func: () => {
        deleteImageHandler();
        setDeleteDialogOpen(false);
      }
    }
  ];

  return (
    <DialogModal
      isOpen={isModalOpen}
      headline="Delete Image"
      text="Are you sure you want to delete this image?"
      buttons={buttonsContent}
      closeDialog={() => onModalClosed()}
    />
  );
};

export default DeleteAssetModal;
