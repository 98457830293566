import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid, Stack } from '@mui/material';
import usePublisher from 'api/usePublisher';
import { ENotificationType } from 'constants/enums';
import { useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { required } from 'utils/errorsTextHelper';
import { permissionsUtil } from 'utils/permissionsUtil';
import * as yup from 'yup';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcPhoneInput from 'components/AcPhoneInput/AcPhoneInput';
import ActionButton from 'components/ActionButton/ActionButton';

import 'style/forms.scss';


const CompanySettings = () => {
  const { publisherId } = useParams();
  const { getPublisher, updatePublisher } = usePublisher(
    publisherId || undefined
  );
  const data: any = getPublisher?.data;
  const { enqueueSnackbar } = useNotifications();
  let companySettingsSchema = yup.object().shape({
    companyName: yup.string().required(required('Name')),
    phoneNumberPrefix: yup.string().max(3),
    supportMail: yup.string().email().required(required('Email')),
    address: yup.string(),
    domain: yup.string().required(required('Domain')),
    phoneNumber: yup
      .string()
      .min(5, 'Phone number must be at least 5 characters')
  });

  const initialValues = useMemo(() => ({
    phoneNumberPrefix: data?.phoneNumberPrefix || '',
    companyName: data?.companyName || '',
    phoneNumber: data?.phoneNumber || '',
    supportMail: data?.supportMail || '',
    address: data?.address || '',
    domain: data?.domain || '',
    publisherId: data?._id || ''
  }), [data]);

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleBlur,
    submitForm,
    isSubmitting,
    dirty,
    isValid
  } = useFormik({
    initialValues,
    validationSchema: companySettingsSchema,
    enableReinitialize: true,
    onSubmit: async (values, {resetForm}) => {
      savePublisherDetails(values, resetForm);
    }
  });

  const savePublisherDetails = (companyData: any, resetForm: () => void) => {
    updatePublisher.mutate(companyData, {
      onSuccess: async () => {
        enqueueSnackbar(
          'Company details updated successfully',
          ENotificationType.SUCCESS
        );
        await getPublisher.refetch();
        resetForm();
      },
      onError: (data: any) => {
        enqueueSnackbar(data.response.data.message, ENotificationType.ERROR);
      }
    });
  };

  if (getPublisher.isLoading || !getPublisher.data) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 340px)',
          width: '100%'
        }}
      >
        <CircularProgress title="Loading..." />
      </div>
    );
  }

  return (
    <Grid container className="formContent">
      <Grid item xs={5} className="formContent-minWIdth">
        <Stack>
          <AcCard stackContainer={false} title="General">
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={6}>
                <AcInput
                  header="Company Name"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={
                    touched.companyName ? errors.companyName?.toString() : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <AcPhoneInput
                  header="Phone Number"
                  names={['phoneNumberPrefix', 'phoneNumber']}
                  placeholders={['eg. 1', 'eg. 0508242111']}
                  values={[values.phoneNumberPrefix, values.phoneNumber]}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={[
                    touched.phoneNumberPrefix &&
                      Boolean(errors.phoneNumberPrefix),
                    touched.phoneNumber && Boolean(errors.phoneNumber)
                  ]}
                  helpers={[
                    touched.phoneNumberPrefix
                      ? errors.phoneNumberPrefix?.toString()
                      : '',
                    touched.phoneNumber ? errors.phoneNumber?.toString() : ''
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Company ID"
                  disabled
                  name="publisherId"
                  value={data?._id}
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Support Email"
                  name="supportMail"
                  value={values.supportMail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    data?.supportModel === 'Publisher' &&
                    touched.supportMail &&
                    Boolean(errors.supportMail)
                  }
                  helperText={
                    data?.supportModel !== 'Publisher'
                      ? 'Your support model directs support cases to Appcharge. To change your support model, please contact Appcharge.'
                      : touched.supportMail
                        ? errors.supportMail?.toString()
                        : ''
                  }
                  disabled={data?.supportModel !== 'Publisher'}
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Address"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address ? errors.address?.toString() : ''}
                />
              </Grid>
              <Grid item xs={6}>
                <AcInput
                  header="Domain"
                  name="domain"
                  value={values.domain}
                  disabled={!permissionsUtil.isSuperAdmin()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.domain && Boolean(errors.domain)}
                  helperText={touched.domain ? errors.domain?.toString() : ''}
                  enableNewTabOpen
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
        <Grid item xs={6} mt={3}>
          <ActionButton
            variant="outlined"
            text="Update"
            onClick={submitForm}
            disabled={!isValid || !dirty || isSubmitting}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompanySettings;
