import React, { useEffect, useMemo, useRef, useState } from 'react';

import { CSVLink } from 'react-csv';
import { useLocation, useNavigate } from 'react-router-dom';

import ContentCopy from '@mui/icons-material/ContentCopy';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import {
  getGridStringOperators,
  GridActionsCellItem,
  GridColumns,
  GridSortModel
} from '@mui/x-data-grid';
import useOrders from 'api/useOrders';
import usePermissions from 'api/usePermissions';
import useUsers from 'api/useUsers';
import { EPublisherSolutionModel, User } from 'common/contracts';
import { DATE_TIME_FORMAT } from 'constants/constants';
import {
  EButtonColor,
  ELocalStorageKeys,
  ENotificationType,
  EOrderStatus,
  GridFeatureMode,
  SortingDirection,
  SortingOrderValue
} from 'constants/enums';
import { geo } from 'constants/geo';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { useMultiplier } from 'hooks/useMultiplier';
import { useNotifications } from 'hooks/useNotifications';
import { usePaginationFromURL } from 'hooks/usePaginationFromURL';
import { formatReceiptNumber } from 'utils/formattingUtil';
import { isEmptyString, NullableString } from 'utils/isEmptyString';
import { localStorageUtil } from 'utils/localStorageUtil';
import { permissionsUtil } from 'utils/permissionsUtil';
import { getSelectProjectOptions } from 'utils/projectsUtil';

import DataTable from '../../DataTable/DataTable';
import DialogModal from '../../Dialog/Dialog';
import FirstActionModal from '../../FirstActionModal/FirstActionModal';
import ProjectsSelect from '../../ProjectsSelect/ProjectsSelect';
import FilterBar from '../FilterBar/FilterBar';
import { ordersUtils } from '../utils';

import { statusDictionary } from './OrdersTable.types';

import './style.scss';

const DEFAULT_START_DATE = '2023-01-01T00:00:00.000Z';

const onFilterModelChange = (
  p: any,
  updateQueryParam: (paramName: string, paramValue: string) => void
) => {
  p.items.map((item: { columnField: string; value: string }) => {
    item.columnField === 'orderId' &&
      item.value &&
      updateQueryParam('orderId', item.value);
    item.columnField === 'playerId' &&
      item.value &&
      updateQueryParam('playerId', item.value);

    item.columnField === 'publisherPurchaseId' &&
      item.value &&
      updateQueryParam('publisherPurchaseId', item.value);

    item.columnField === 'receiptId' &&
      item.value &&
      updateQueryParam('receiptId', item.value);
  });
};

const OrdersTable: React.FC = () => {
  const DEFAULT_END_DATE = dayjs()
    .utc()
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .toISOString();
  const projectsDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.projects;
  const { enqueueSnackbar } = useNotifications();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchFeatureFlags } = useUsers({ enableFeatureFlags: false });
  const [useReceiptFeature, setUseReceiptFeature] = useState<boolean>(false);
  const {
    page,
    rows,
    sortValue,
    direction,
    statuses,
    publisherPurchaseId,
    playerId,
    orderId,
    receiptId
  } = usePaginationFromURL('orders');
  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(rows);
  const [isSuccessSearch, setIsSuccessSearch] = useState<boolean>(false);
  const [formattedRangeDates, setFormattedRangeDates] = useState<
    string[] | null[]
  >([null, null]);
  const [sorting, setSorting] = useState<{
    sortValue: SortingOrderValue;
    direction: SortingDirection;
  }>({
    sortValue: sortValue,
    direction: direction
  });

  const { getPermissions } = usePermissions();
  const permissions = getPermissions();
  const allowedPublishers =
    permissionsUtil.getAccessPublisherOrders(permissions);

  const [selectedProjects, setSelectedProjects] = useState<string[]>(
    projectsDetails
      ? getSelectProjectOptions(projectsDetails, allowedPublishers).map(
          (project) => project.value
        )
      : []
  );

  const updateQueryParam = (paramName: string, paramValue: string) =>
    ordersUtils.updateQueryParam({
      paramName,
      paramValue,
      location,
      navigate
    });

  let filters: any = {
    orderId,
    playerId,
    receiptId: receiptId?.replace(/-/g, ''),
    publisherPurchaseId,
    statuses: statuses.flatMap((status) =>
      status.split(ordersUtils.STATUSES_SEPARATOR)
    ),
    startDate: formattedRangeDates[0] || DEFAULT_START_DATE,
    endDate: formattedRangeDates[1] || DEFAULT_END_DATE,
    selectedProjects: selectedProjects.join(',')
  };
  const { getOrders: orders, makeRefund } = useOrders(
    undefined,
    { currentPage, rowsPerPage },
    sorting,
    {
      ...filters
    }
  );

  const getSearchResult = (searchValue: NullableString) =>
    setIsSuccessSearch(!isEmptyString(searchValue));

  const timeout = useRef<any>(null);
  const debounce = (func: any, wait = 250) => {
    timeout && clearTimeout(timeout.current);
    timeout.current = setTimeout(func, wait);
  };

  const totalCount = orders.data?.totalCount || 0;
  const [csvData, setCsvData] = useState<any[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const downloadCSVRef = useRef(null);

  const [selectedOrder, setSelectedOrder] = useState<{
    id: string;
    amount: number;
    amountFormatted: string;
    currency: string;
    publisherId: string;
  } | null>(null);

  const { getOrderMultiplier } = useMultiplier(
    selectedOrder?.publisherId ?? ''
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', String(currentPage));
    queryParams.set('rows', String(rowsPerPage));
    queryParams.set('sortValue', sorting.sortValue);
    queryParams.set('direction', sorting.direction);

    const navigationOpt: {
      replace: boolean;
      state?: Record<string, boolean>;
    } = { replace: true };
    if (location?.state) {
      navigationOpt.state = location.state;
    }
    navigate(`${location.pathname}?${queryParams.toString()}`, navigationOpt);
  }, [currentPage, rowsPerPage, sorting]);

  useEffect(() => {
    if (!orders.data) return;
    const filteredData = orders.data?.orders.map((record: any) => {
      const modifiedRecord = {
        'Created at': record.createdAt,
        id: record._id,
        'Client Facebook ID': record.clientFbId,
        'Bundle name': record.bundleName,
        'Formatted amount': record.amountFormatted,
        Currency: record.currency,
        'Total amount': record.amountTotal.toNumber(),
        'UTM source': record.utmSource,
        'UTM medium': record.utmMedium,
        'UTM campaign': record.utmCampaign,
        State: record.state,
        Reason: record.reason,
        Provider: record.provider,
        'Publisher payment ID': record.publisherPurchaseId
      };
      return modifiedRecord;
    });
    setCsvData(filteredData);
  }, [orders.data]);


  if (orders.isError) {
    enqueueSnackbar(
      'Could not load orders list from server',
      ENotificationType.ERROR
    );
  }

  const orderColumns = useMemo<GridColumns>(
    () => [
      ...(projectsDetails
        ? [
            {
              field: 'project',
              minWidth: 200,
              headerName: 'Project',
              flex: 0.2,
              valueGetter: (params: any) => {
                const currentPublisherId = params.row.publisherId;
                return projectsDetails?.find(
                  (project) => project.publisherId === currentPublisherId
                )?.publisherName;
              }
            }
          ]
        : []),
      {
        field: 'bundleName',
        minWidth: 200,
        headerName: 'Name',
        flex: 0.2
      },
      {
        field: '_id',
        headerName: 'ID',
        minWidth: 100,
        flex: 0.2,
        ColumnUnsortedIcon: null,
        disableReorder: true,
        filterable: false
      },
      {
        field: 'amountFormatted',
        headerName: 'Price',
        minWidth: 100,
        flex: 0.2,
        renderCell: (params: any) => {
          const multiplier =
            geo.find((c) => c.currencyCode === params.row.currency)
              ?.multiplier || 100;
          const amount = `${params.row.currencySymbol}${new Decimal(
            params.row.amountTotal
          )
            .div(multiplier)
            .toNumber()}`;
          return amount || 'N/A';
        },
        filterable: false
      },
      {
        field: 'createdAt',
        minWidth: 180,
        flex: 0.2,
        headerName: 'Date',
        renderCell: (params: any) => {
          if (orders.isLoading) return '';
          return (
            <span>
              {dayjs(params.row.createdAt).utc().format(DATE_TIME_FORMAT)}
            </span>
          );
        },
        valueGetter: (params: any) => {
          return params.row.createdAt;
        },
        filterable: false
      },
      {
        field: 'orderId',
        headerName: 'Order ID',
        minWidth: 200,
        flex: 0.2,
        renderCell: (params: any) => {
          return params.row.orderId || 'N/A';
        },
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        ),
        filterable: false,
        sortable: false
      },
      {
        field: 'receiptId',
        headerName: 'Receipt number',
        minWidth: 120,
        filterable: false,
        sortable: false,
        flex: 0.2,
        renderCell: (params: any) => {
          return params.row.receiptId
            ? formatReceiptNumber(params.row.receiptId)
            : '';
        },
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        )
      },
      {
        field: 'playerId',
        headerName: 'Player ID',
        minWidth: 100,
        flex: 0.2,
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        ),
        filterable: false
      },
      {
        field: 'publisherPurchaseId',
        headerName: 'Transaction ID',
        minWidth: 150,
        flex: 0.2,
        ColumnUnsortedIcon: null,
        disableReorder: true,
        renderCell: (params: any) => {
          if (params.row.publisherPurchaseId) {
            return (
              <Stack direction="row" alignItems="center">
                <span
                  style={{
                    overflow: 'hidden',
                    maxWidth: 100,
                    textOverflow: 'ellipsis'
                  }}
                >
                  {params.row.publisherPurchaseId}
                </span>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      params.row.publisherPurchaseId
                    );
                    enqueueSnackbar(
                      `Transaction ID copied`,
                      ENotificationType.SUCCESS
                    );
                  }}
                >
                  <ContentCopy />
                </IconButton>
              </Stack>
            );
          }
          return 'N/A';
        },
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        ),
        filterable: false,
        sortable: false
      },
      {
        field: 'state',
        headerName: 'Status',
        flex: 0.2,
        minWidth: 200,
        renderCell: ({ value }: any) => {
          if (orders.isLoading) return value;
          return (
            <StatusLabel
              text={statusDictionary[value as EOrderStatus]?.text}
              status={statusDictionary[value as EOrderStatus]?.status}
              prefixIcon={statusDictionary[value as EOrderStatus]?.prefixIcon}
            />
          );
        },
        valueGetter: (params: any) => {
          return params.row.state;
        },
        filterable: false
      },
      {
        field: 'Actions',
        flex: 0,
        type: 'actions',
        width: 50,
        disableReorder: true,
        filterable: false,
        hideable: false,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<VisibilityOutlinedIcon />}
            label="View"
            onClick={() => {
              navigate(`./${params.id}`, {
                state: {
                  state: params.row.state,
                  isOver30Days:
                    dayjs().diff(dayjs(params.row.createdAt), 'days') >= 30,
                  publisherId: params.row.publisherId
                }
              });
            }}
            showInMenu
          />
        ]
      }
    ],
    [orders.isLoading, projectsDetails]
  );

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length > 0) {
      const field = model[0].field;
      const mappedSortValue = ordersUtils.fieldToSortingOrderValue[field];
      if (mappedSortValue) {
        setSorting({
          sortValue: mappedSortValue,
          direction:
            model[0].sort === SortingDirection.ASC
              ? SortingDirection.ASC
              : SortingDirection.DESC
        });
      }
    }
  };

  return (
    <>
      <Stack>
        <div style={{ display: 'none' }}>
          {csvData && (
            <CSVLink
              filename={'orders.csv'}
              ref={downloadCSVRef}
              data={csvData}
            />
          )}
        </div>
        {projectsDetails && (
          <Stack direction="row" gap={2} mt={'14px'}>
            <ProjectsSelect
              projectsDetails={projectsDetails}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              isApplyBtnEnabled={true}
              allowedPublishers={permissionsUtil.getAccessPublisherOrders(
                permissions
              )}
            />
          </Stack>
        )}
        <FilterBar
          projectsDetails={projectsDetails}
          setFormattedRangeDates={setFormattedRangeDates}
          formattedRangeDates={formattedRangeDates}
          getSearchResult={getSearchResult}
        />
        <DataTable
          pagination={true}
          columns={orderColumns}
          rows={orders.data?.orders || []}
          loading={orders.isLoading}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          onRowsPerPageChange={(newRowsPerPage) => {
            setRowsPerPage(newRowsPerPage);
            setCurrentPage(0);
          }}
          sortingMode={GridFeatureMode.SERVER}
          onSortModelChange={handleSortModelChange}
          defaultHiddenFields={[
            '_id',
            'provider',
            'paymentId',
            'bundleId',
            (localStorageUtil.get(
              ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL
            ) === EPublisherSolutionModel.STORE &&
              'playerEmail') ||
              ''
          ]}
          currentPage={currentPage}
          totalCount={totalCount}
          localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
          hideFooter={false}
          error={orders.isError}
          initialSorting={{
            sortModel: [
              {
                field: ordersUtils.sortValueToFieldMap[sorting.sortValue],
                sort:
                  sorting.direction === SortingDirection.ASC
                    ? SortingDirection.ASC
                    : SortingDirection.DESC
              }
            ]
          }}
          onNoData={
            isSuccessSearch && orders.data?.orders.length === 0 ? (
              <FirstActionModal
                headline="No orders found"
                text="Your order was not found. Please check your spelling or try different keywords."
              />
            ) : (
              <FirstActionModal
                headline="No exact matches"
                text="Try changing or removing some of your filters or adjusting your search area."
              />
            )
          }
          filterMode={GridFeatureMode.SERVER}
          onFilterModelChange={(p) => onFilterModelChange(p, updateQueryParam)}
        />
      </Stack>
      <DialogModal
        width="392px"
        isOpen={!!selectedOrder}
        headline="Refund payment"
        text="Refunds take 5-10 days to appear on a customer's statement."
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setSelectedOrder(null);
            }
          },
          {
            text: 'Refund',
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            disabled: isDeleting,
            func: () => {
              setIsDeleting(true);
              makeRefund.mutate(
                {
                  orderId: selectedOrder!.id,
                  publisherId: selectedOrder!.publisherId
                },
                {
                  onSuccess: () => {
                    orders.refetch();
                    enqueueSnackbar(
                      `Order ${selectedOrder!.id} refunded`,
                      ENotificationType.SUCCESS
                    );
                  },
                  onError: () => {
                    enqueueSnackbar(
                      "Couldn't refund order",
                      ENotificationType.ERROR
                    );
                  },
                  onSettled: () => {
                    setSelectedOrder(null);
                    setIsDeleting(false);
                  }
                }
              );
            }
          }
        ]}
        closeDialog={() => setSelectedOrder(null)}
        content={
          <Stack>
            <Box>
              <Typography fontSize="12px" marginTop="16px">
                Refund amount
              </Typography>
              <Typography fontSize="20px">
                <b>
                  {selectedOrder &&
                    new Decimal(selectedOrder?.amount)
                      .div(getOrderMultiplier(selectedOrder.currency))
                      .toNumber()}{' '}
                  {selectedOrder?.currency}
                </b>
              </Typography>
            </Box>
          </Stack>
        }
      />
    </>
  );
};

export default OrdersTable;
