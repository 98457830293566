import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { EQueryKeys } from 'constants/enums';
import useAxios from 'hooks/useAxios';
import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useBlockedPlayers() {
  const axios = useAxios();
  const { publisherId } = useParams();
  const { id } = useParams<{ id: string }>();
  const getBlockedPlayers = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.BLOCKED_PLAYERS],
    queryFn: async () => {
      return await axios.get(
        getApiRouteOrMock(EApiRoutes.BLOCKED_PLAYERS),
        {},
        publisherId ? { 'x-publishers': publisherId } : undefined
      );
    }
  });
  return {
    getBlockedPlayers
  }
}
