import { useMutation, useQuery } from '@tanstack/react-query';

import { OfferUI } from '../common/contracts';
import { EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useOfferUI(
  currentPublisherId?: string,
  offerId?: string
) {
  const axios = useAxios();

  const getOffersUI = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.OFFERS_UI],
    queryFn: async () => {
      const offersUIRaw = await axios.get(
        getApiRouteOrMock(EApiRoutes.OFFERS_UI),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
      return offersUIRaw;
    },
    enabled: !!currentPublisherId
  });

  const getOfferUI = useQuery({
    ...fetchConfig.single,
    queryKey: [`${EQueryKeys.OFFERS_UI}_${offerId}`],
    queryFn: async () => {
      return await axios.get(
        `${getApiRouteOrMock(EApiRoutes.OFFERS_UI)}/${offerId}`,
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    enabled: !!currentPublisherId && !!offerId
  });

  const addOfferUI = useMutation(async (newOfferUI: OfferUI) => {
    return await axios.post(
      getApiRouteOrMock(EApiRoutes.OFFERS_UI),
      newOfferUI
    );
  }, {});

  const deleteOfferUI = useMutation(async (offerUIId: string) => {
    return await axios.del(
      `${getApiRouteOrMock(EApiRoutes.OFFERS_UI)}/${offerUIId}`
    );
  }, {});

  const updateOfferUI = useMutation(
    async ({ form, offerId }: { form: Partial<OfferUI>; offerId: string }) => {
      return axios.put(
        `${getApiRouteOrMock(EApiRoutes.OFFERS_UI)}/${offerId}`,
        form
      );
    },
    {}
  );

  return {
    getOfferUI,
    getOffersUI,
    addOfferUI,
    deleteOfferUI,
    updateOfferUI
  };
}
