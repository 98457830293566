import React, { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Grid, Stack } from '@mui/material';
import useImages from 'api/useImages';
import { EAssetType } from 'constants/enums';
import { useFormikContext } from 'formik';
import { UploadsTooltips } from 'hooks/useUpload';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import ActionButton from 'components/ActionButton/ActionButton';

import {
  CurrentSectionItem,
  SectionsItem,
  StoreThemeFormValues
} from '../storeTheme.types';

import StoreThemeSectionsTable from './SectionsTable';

export const Sections: React.FC = () => {
  const { values, handleBlur, setFieldValue, errors } =
    useFormikContext<StoreThemeFormValues>();

  const { publisherId } = useParams();

  const pictures = useImages(publisherId).getImages;
  const [currentSection, setCurrentSection] =
    useState<CurrentSectionItem | null>(null);
  const [sections, setSections] = useState<SectionsItem[]>(values.sections);
  const [editedSectionId, setEditedSectionId] = useState('');
  const [currentIdError, setCurrentIdError] = useState<string>('');
  const [currentNameError, setCurrentNameError] = useState<string>('');

  const sectionsTableItems: SectionsItem[] = useMemo(() => {
    if (sections.length > 0) {
      return sections.map((item, index) => ({
        ...item,
        _id: item.publisherSectionId || index.toString(),
        type: 'Section'
      }));
    }
    return [];
  }, [sections]);

  useEffect(() => {
    if (values.sections.length > 0) {
      setSections(values.sections);
    }
  }, [values.sections]);

  const handleChangeSectionsList = () => {
    return !!editedSectionId ? editSection() : addSection();
  };

  const addSection = () => {
    if (!currentSection) return;

    const sectionToAdd = {
      _id: currentSection.publisherSectionId,
      publisherSectionId: currentSection.publisherSectionId,
      name: currentSection.name,
      type: 'Section',
      image: currentSection?.image
    };
    setSections((prevSections) => [...prevSections, sectionToAdd]);

    const sectionFieldValue: SectionsItem = {
      _id: sectionToAdd.publisherSectionId || sectionToAdd.name,
      publisherSectionId: sectionToAdd.publisherSectionId,
      name: sectionToAdd.name,
      toAdd: true
    };
    if (sectionToAdd.image) {
      sectionFieldValue.image = sectionToAdd.image;
    }
    setFieldValue('sections', [...values.sections, sectionFieldValue]);
    setFieldValue('sectionId', '');
    setFieldValue('sectionName', '');
    setCurrentSection(null);
  };

  const editSection = () => {
    if (!currentSection) return;
    const updatedSectionsList = sections.map((section) => {
      if (section.publisherSectionId === editedSectionId) {
        const updatedSection: SectionsItem = {
          _id: currentSection.publisherSectionId,
          publisherSectionId: currentSection.publisherSectionId,
          sectionId: currentSection.sectionId,
          name: currentSection.name
        };
        if (currentSection.image) {
          updatedSection.image = currentSection.image;
        }
        return updatedSection;
      }
      return section;
    });
    setEditedSectionId('');
    setSections(updatedSectionsList);
    setFieldValue('sections', updatedSectionsList);
    setFieldValue('sectionId', '');
    setFieldValue('sectionName', '');
    setCurrentSection(null);
  };

  const getSectionImgItems = () => {
    if (!pictures.isLoading && pictures.data) {
      const filteredPic = pictures.data.filter(
        (p: any) => p.type === EAssetType.GENERAL
      );
      return filteredPic.length > 0
        ? filteredPic.map((picture: any) => ({
            content: picture.name,
            key: picture.url,
            value: picture.url,
            renderFunction: () => (
              <Stack
                className="formContent-imageSelected"
                direction="row"
                spacing={2}
              >
                <img alt={picture.name} src={picture.url} />
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: 0
                  }}
                >
                  {picture.name}
                </div>
              </Stack>
            )
          }))
        : [];
    }

    return [];
  };

  useEffect(() => {
    if (
      currentSection?.name &&
      values.sections.some(
        (item: SectionsItem) =>
          item.publisherSectionId !== editedSectionId &&
          item.name === currentSection.name
      )
    ) {
      setCurrentNameError('Section name already exists');
    } else {
      setCurrentNameError('');
    }
  }, [currentSection?.name]);

  useEffect(() => {
    if (
      currentSection?.publisherSectionId &&
      currentSection.publisherSectionId !== editedSectionId &&
      values.sections.some(
        (item: SectionsItem) =>
          item.publisherSectionId === currentSection.publisherSectionId
      )
    ) {
      setCurrentIdError('Section ID already exists');
    } else if (
      currentSection?.publisherSectionId &&
      currentSection.publisherSectionId.includes(' ')
    ) {
      setCurrentIdError('Section ID should not contain spaces');
    } else {
      setCurrentIdError('');
    }
  }, [currentSection?.publisherSectionId]);

  return (
    <AcCard stackContainer={false} title="Sections">
      <Grid container rowSpacing={1.5} columnSpacing={1.5}>
        <Grid item xs={6}>
          <AcInput
            header="Section name"
            name="sectionName"
            value={currentSection?.name || ''}
            tooltip="This will be the name of your section"
            type="text"
            onChange={(e) => {
              setCurrentSection({
                ...currentSection,
                publisherSectionId: currentSection?.publisherSectionId || '',
                name: (e.target.value as string) || ''
              });
            }}
            onBlur={handleBlur}
            error={currentNameError !== ''}
            helperText={currentNameError}
          />
        </Grid>

        <Grid item xs={6}>
          <AcInput
            header="ID"
            name="sectionId"
            value={currentSection?.publisherSectionId || ''}
            type="text"
            onChange={(e) => {
              setCurrentSection({
                ...currentSection,
                publisherSectionId: (e.target.value as string) || '',
                name: currentSection?.name || ''
              });
            }}
            onBlur={handleBlur}
            error={currentIdError !== ''}
            helperText={currentIdError}
          />
        </Grid>

        <Grid item xs={6}>
          <AcSelect
            header="Image"
            name="sectionImage"
            value={currentSection?.image || ''}
            onChange={(e) => {
              setCurrentSection({
                ...currentSection,
                name: currentSection?.name || '',
                image: e.target.value as string,
                publisherSectionId: currentSection?.publisherSectionId || ''
              });
            }}
            onBlur={handleBlur}
            onClear={() => {
              if (currentSection) {
                const { image, ...rest } = currentSection;
                setCurrentSection(rest);
              }
            }}
            disabled={pictures.isLoading || pictures.isRefetching}
            uploadConfig={{
              onUploadSuccess: async (uploadData: any) => {
                await pictures.refetch();
                setCurrentSection({
                  name: currentSection?.name || '',
                  image: uploadData!.data.url,
                  publisherSectionId: currentSection?.publisherSectionId || ''
                });
              },
              uploadType: EAssetType.GENERAL,
              uploadMessage: UploadsTooltips[EAssetType.SECTION]
            }}
            items={getSectionImgItems()}
            tooltip={
              'For optimized display, make sure your image are at least 300px with 1:1 aspect ratio'
            }
          />
        </Grid>

        <Grid item xs={12}>
          <ActionButton
            text={!!editedSectionId ? 'Save' : 'Add'}
            variant="outlined"
            disabled={
              !currentSection?.publisherSectionId ||
              !currentSection?.name ||
              Boolean(errors?.sectionId) ||
              Boolean(errors?.sectionName) ||
              currentIdError !== '' ||
              currentNameError !== ''
            }
            onClick={handleChangeSectionsList}
          />
        </Grid>
        <Grid item xs={12} mt={-3}>
          <StoreThemeSectionsTable
            sections={sectionsTableItems}
            setSections={setSections}
            setFieldValue={setFieldValue}
            setCurrentSection={setCurrentSection}
            setEditedSectionId={setEditedSectionId}
          />
        </Grid>
      </Grid>
    </AcCard>
  );
};
