import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { Chip, Divider, Grid, InputAdornment, Stack } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import useOffers from 'api/useOffers';
import useOffersUI from 'api/useOffersUI';
import useSegments from 'api/useSegments';
import { Offer, OfferUI } from 'common/contracts';
import { OfferType } from 'constants/enums';
import { OfferDesignTypes } from 'views/OffersUI/OffersUIForm/types';
import { SettingsTabProps } from 'views/RollingOffers/types';

import AcAutoComplete from 'components/AcAutoComplete/AcAutoComplete';
import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

const SettingsTab: React.FC<SettingsTabProps> = ({
  formikProps,
  rollingOfferId,
  edit,
  currentOfferUI,
  setCurrentOfferUI,
  chosenSegment,
  setChosenSegment,
  data,
  dup
}) => {
  const { handleChange, handleBlur, touched, errors, setFieldValue, values } =
    formikProps;

  const [isNameExists, setIsNameExists] = useState<boolean>(false);
  const [segmentError, setSegmentError] = useState<string>('');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { publisherId } = useParams();

  const { getOffers } = useOffers(rollingOfferId, OfferType.ROLLING_OFFER);
  const { getOffersUI } = useOffersUI(publisherId, currentOfferUI);
  const { getSegments } = useSegments(publisherId);

  useEffect(() => {
    const offerExists = getOffers.data?.offers.some(
      (offer: Offer) => offer.name === values.name
    );
    setIsNameExists(offerExists && !edit);
  }, [values.name, edit, getOffers.data]);

  useEffect(() => {
    setCurrentOfferUI(values.offerUiId);
  }, [values.offerUiId, setCurrentOfferUI]);

  useEffect(() => {
    if (data?.segments) {
      setChosenSegment(data.segments);
    }
  }, [data?.segments, setChosenSegment]);

  const handleAddSegment = (val: string) => {
    if (chosenSegment.includes(val)) {
      setSegmentError("You can't add the same segment twice");
    } else {
      const newSegments = [...chosenSegment, val];
      setChosenSegment(newSegments);
      setFieldValue('segments', newSegments);
      setSegmentError('');
      inputRef.current?.blur();
    }
  };

  const handleRemoveSegment = (val: string) => {
    const newSegments = chosenSegment.filter(
      (segment: string) => segment !== val
    );
    setChosenSegment(newSegments);
    setFieldValue('segments', newSegments);
  };

  const offerUIOptions =
    getOffersUI.data?.result
      .filter((oi: OfferUI) => oi.offerUiType === OfferDesignTypes.RollingOffer)
      .map((offerUI: OfferUI) => ({
        content: offerUI.name,
        key: offerUI._id,
        value: offerUI._id
      })) || [];

  const availableSegments =
    getSegments?.data?.filter(
      (segment: string) => !chosenSegment.includes(segment)
    ) || [];

  return (
    <Stack gap={2}>
      <AcCard stackContainer={false} title="General">
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <AcInput
              header="Name"
              name="name"
              required
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                (Boolean(errors.name) && (touched.name || dup)) || isNameExists
              }
              helperText={
                touched.name || dup || isNameExists
                  ? errors.name?.toString()
                  : ''
              }
              dataTestValidationId="rollingOfferNameValidationTxt"
            />
          </Grid>
          <Grid item xs={6}>
            <AcInput
              header="ID"
              name="publisherOfferId"
              required
              value={values.publisherOfferId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                Boolean(errors.publisherOfferId) &&
                (touched.publisherOfferId || dup)
              }
              helperText={
                touched.publisherOfferId || dup
                  ? errors.publisherOfferId?.toString()
                  : ''
              }
              dataTestValidationId="rollingOfferIdValidationTxt"
            />
          </Grid>
          <Grid item xs={6}>
            <AcSelect
              header="Offer design"
              name="offerUiId"
              required
              value={values.offerUiId}
              onChange={(e) => {
                handleChange(e);
                setCurrentOfferUI(e.target.value as string);
              }}
              onBlur={handleBlur}
              items={offerUIOptions}
              error={touched?.offerUiId && !!errors?.offerUiId}
              helperText={
                touched?.offerUiId ? errors?.offerUiId?.toString() : ''
              }
              dataTestValidationId="rollingOfferDesignValidationTxt"
              dataTestListId="dashRollingOfferDesignList"
              dataTestBoxId="rollingOfferDesignBox"
            />
          </Grid>
          <Grid item xs={6}>
            <AcInput
              header="Priority"
              name="priority"
              required
              tooltip="Priority decides which rolling offer show, based on their importance. The lower the number, the higher the priority."
              value={values.priority}
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              error={touched?.priority && Boolean(errors?.priority)}
              helperText={touched?.priority ? errors?.priority?.toString() : ''}
            />
          </Grid>
        </Grid>
      </AcCard>
      <Divider sx={{ width: '100%' }} />
      <AcCard stackContainer={false} title="Segments">
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid xs={12} item>
            <AcAutoComplete
              ref={inputRef}
              value=""
              onEnter={handleAddSegment}
              onChangeEvent={(val) => {
                if (chosenSegment.includes(val)) {
                  setSegmentError("You can't add the same segment twice");
                } else {
                  setSegmentError('');
                }
              }}
              options={availableSegments}
              additionalOptions={chosenSegment}
              startAdornment={
                <InputAdornment position="start">
                  <GridSearchIcon />
                </InputAdornment>
              }
              helperText="Press ‘Enter’ to add a new segment"
              error={segmentError}
            />
            <Stack
              marginTop={1.5}
              gap={1}
              direction="row"
              flexWrap="wrap"
              data-testid="rollingOfferSegmentsList"
            >
              {chosenSegment.map((val: string) => (
                <Chip
                  key={val}
                  label={
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <span>{val}</span>
                      <CloseIcon sx={{ width: 14, height: 14 }} />
                    </Stack>
                  }
                  onClick={() => handleRemoveSegment(val)}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </AcCard>
    </Stack>
  );
};

export default SettingsTab;
