import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import useOfferUI from '../../../api/useOffersUI';
import AcContentWrapper from '../../../components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from '../../../components/AcViewWrapper/AcViewWrapper';
import DataTable from '../../../components/DataTable/DataTable';
import DialogModal from '../../../components/Dialog/Dialog';
import FirstActionModal from '../../../components/FirstActionModal/FirstActionModal';
import PageTopBar from '../../../components/Topbar/PageTopBar';
import { DATE_TIME_FORMAT } from '../../../constants/constants';
import {
  EButtonColor,
  ELocalStorageKeys,
  ENotificationType
} from '../../../constants/enums';
import { useNotifications } from '../../../hooks/useNotifications';
import { permissionsUtil } from '../../../utils/permissionsUtil';

import './style.scss';

const dayjsUTC = dayjs.extend(utc);

const OffersUITable = () => {
  const { publisherId } = useParams();
  const navigate = useNavigate();
  const { getOffersUI, deleteOfferUI } = useOfferUI(publisherId);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedOfferUI, setSelectedOfferUI] = useState<string | null>(null);
  const { enqueueSnackbar } = useNotifications();

  const handleDeleteOfferUI = async () => {
    if (!selectedOfferUI) return;
    await deleteOfferUI.mutate(selectedOfferUI, {
      onSuccess: () => {
        enqueueSnackbar(
          'Offer Design deleted successfully',
          ENotificationType.SUCCESS
        );
        getOffersUI.refetch();
      },
      onError: (e: any) => {
        enqueueSnackbar(
          'Failed to delete Offer Design',
          ENotificationType.ERROR,
          e.response.data.message ?? ''
        );
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
        setSelectedOfferUI(null);
      }
    });
  };

  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      width: 80,
      renderCell: (params: any) => {
        return params?.row?.backgroundImage ? (
          <img
            style={{
              objectFit: 'cover',
              width: '32px',
              height: '32px'
            }}
            src={params.row.backgroundImage}
            alt="emblem"
          />
        ) : (
          <Typography>N/A</Typography>
        );
      },
      headerClassName: 'offersUITableHeaderCell',
      cellClassName: 'offersUITableCell'
    },
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      width: 100,
      headerClassName: 'offersUITableHeaderCell',
      cellClassName: 'offersUITableCell'
    },
    {
      field: 'externalId',
      flex: 1,
      headerName: 'External ID',
      width: 100,
      headerClassName: 'offersUITableHeaderCell',
      cellClassName: 'offersUITableCell'
    },
    {
      field: '_id',
      flex: 1,
      headerName: 'ID',
      width: 100,
      headerClassName: 'offersUITableHeaderCell',
      cellClassName: 'offersUITableCell'
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Date',
      width: 200,
      type: 'date',
      renderCell: (params: any) => {
        if (getOffersUI.isLoading) return '';
        return (
          <span>
            {dayjsUTC.utc(params.row.createdAt).format(DATE_TIME_FORMAT)}
          </span>
        );
      },
      headerClassName: 'offersUITableHeaderCell',
      cellClassName: 'offersUITableCell'
    },
    {
      field: 'offerUiType',
      flex: 1,
      headerName: 'Type',
      width: 200,
      headerClassName: 'offersUITableHeaderCell',
      cellClassName: 'offersUITableCell'
    },
    {
      field: 'actions',
      flex: 0,
      type: 'actions',
      width: 80,
      disableReorder: true,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditTwoToneIcon />}
          label="Edit"
          onClick={() => {
            navigate(`./form/${params.id}`);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.OFFER_DESIGN,
                EPermissionAction.MANAGE,
                publisherId,
            )
          }
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ContentCopyOutlinedIcon />}
          label="Duplicate"
          onClick={() => {
            navigate(`./dup/${params.id}`);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.OFFER_DESIGN,
                EPermissionAction.MANAGE,
                publisherId,
            )
          }
          showInMenu
        />,
        <GridActionsCellItem
          className="danger"
          icon={<DeleteOutlineTwoToneIcon className="danger" />}
          label="Delete"
          onClick={() => {
            setIsDeleteDialogOpen(true);
            setSelectedOfferUI(params.id);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.OFFER_DESIGN,
                EPermissionAction.MANAGE,
                publisherId,
            )
          }
          showInMenu
        />
      ],
      headerClassName: 'offersUITableHeaderCell'
    }
  ];

  return (
    <>
      <AcViewWrapper
        header={
          <PageTopBar
            headline="Offer Design"
            buttons={[
              {
                text: 'Add New Design',
                action: () => navigate('./form'),
                disabled: !permissionsUtil.canUserEdit(),
                hidden:
                  !permissionsUtil.isActionEnabled(
                    null,
                    EPermissionGroup.OFFER_DESIGN,
                    EPermissionAction.MANAGE,
                    publisherId,
                  )
              }
            ]}
          />
        }
      >
        <AcContentWrapper className="offersUITableContent">
          <DataTable
            columns={columns}
            rows={
              !getOffersUI.isLoading && getOffersUI.data
                ? getOffersUI.data.result
                : []
            }
            loading={getOffersUI.isLoading}
            defaultHiddenFields={['id']}
            localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
            hideFooter={false}
            error={false}
            initialSorting={{
              sortModel: [{ field: 'createdAt', sort: 'desc' }]
            }}
            onNoData={
              <FirstActionModal
                headline="Make your offers pop"
                text="Create your first offer design by hitting the “Add new design” button on the top right corner of the screen"
              />
            }
          />
        </AcContentWrapper>
      </AcViewWrapper>
      <DialogModal
        isOpen={isDeleteDialogOpen}
        headline="Delete Offer Design"
        text="Are you sure you want to delete this Offer Design?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setIsDeleteDialogOpen(false);
            }
          },
          {
            text: 'Delete',
            color: EButtonColor.ERROR,
            variant: 'contained',
            func: handleDeleteOfferUI
          }
        ]}
        closeDialog={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};

export default OffersUITable;
