import { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Divider, Stack, Typography } from '@mui/material';
import usePublisher from 'api/usePublisher';
import { EButtonColor, ENotificationType, ESetting } from 'constants/enums';
import { useNotifications } from 'hooks/useNotifications';

import DialogModal from 'components/Dialog/Dialog';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';

import './style.scss';

export const PricingSettings: FC = () => {
  const { publisherId } = useParams();
  const { id } = useParams<{ id: string }>();
  const { getPublisherSettings, updatePublisherSettings } = usePublisher(
    publisherId ? publisherId : undefined
  );
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<ESetting | null>(null);
  const { enqueueSnackbar } = useNotifications();
  const [settings, setSettings] = useState({
    roundingRulesEnabled: false,
    taxEnabled: false
  });

  useEffect(() => {
    getPublisherSettings
      .refetch()
      .then((response) => {
        const settings = response.data;
        setSettings({
          roundingRulesEnabled: settings?.roundingOn,
          taxEnabled: settings?.taxOn
        });
      })
      .catch((error) => {
        enqueueSnackbar('Failed to load settings', ENotificationType.ERROR);
        console.error('Error fetching publisher settings:', error);
      });
  }, []);

  const handleSettingChange = (settingType: ESetting) => {
    setDialogType(settingType);
    setDialogOpen(true);
  };

  const submitSettingChange = () => {
    if (!dialogType) return;
    const settingName =
      dialogType === ESetting.ROUNDING_ON ? 'Rounding rules' : 'Tax';
    const isActive =
      dialogType === ESetting.ROUNDING_ON
        ? !settings.roundingRulesEnabled
        : !settings.taxEnabled;
    updatePublisherSettings.mutate(
      { setting: dialogType, isActive },
      {
        onSuccess: () => {
          enqueueSnackbar(
            `${settingName} setting updated successfully`,
            ENotificationType.SUCCESS
          );
          setSettings((prevSettings) => ({
            ...prevSettings,
            [dialogType === ESetting.ROUNDING_ON
              ? 'roundingRulesEnabled'
              : 'taxEnabled']: isActive
          }));
          setDialogOpen(false);
        },
        onError: () => {
          enqueueSnackbar(
            `Error updating ${settingName} setting`,
            ENotificationType.ERROR
          );
          setDialogOpen(false);
        }
      }
    );
  };

  return (
    <>
      <Stack>
        <Stack
          justifyContent="space-between"
          alignContent="center"
          direction="row"
        >
          <h3
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            Add Tax
          </h3>
          <CustomizedSwitch
            tooltip={''}
            status={settings.taxEnabled}
            texts={[]}
            functions={[
              () => handleSettingChange(ESetting.TAX_ON),
              () => handleSettingChange(ESetting.TAX_ON)
            ]}
            disabled={
              getPublisherSettings.isLoading || getPublisherSettings.isFetching
            }
          />
        </Stack>
        <Divider />
        <Typography
          mt={1.5}
          fontFamily="'Montserrat', sans-serif"
          fontSize={'12px'}
          color={'#717188'}
        >
          Tax will be automatically added to local prices of countries where the
          tax is included
        </Typography>
        <Stack
          justifyContent="space-between"
          alignContent="center"
          direction="row"
        >
          <h3
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            Rounding Rules
          </h3>
          <CustomizedSwitch
            tooltip={''}
            status={settings.roundingRulesEnabled}
            texts={[]}
            functions={[
              () => handleSettingChange(ESetting.ROUNDING_ON),
              () => handleSettingChange(ESetting.ROUNDING_ON)
            ]}
            disabled={
              getPublisherSettings.isLoading || getPublisherSettings.isFetching
            }
          />
        </Stack>
        <Divider />
        <Typography
          mt={1.5}
          fontFamily="'Montserrat', sans-serif"
          fontSize={'12px'}
          color={'#717188'}
        >
          Rounding rules will be automatically applied on all local prices
        </Typography>
      </Stack>
      <DialogModal
        isOpen={isDialogOpen}
        headline={'Changes Approval'}
        text={
          'Enabling this toggle will affect all existing price points. Do you approve?'
        }
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setDialogOpen(false);
            }
          },
          {
            text: 'Approve',
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            func: submitSettingChange
          }
        ]}
        closeDialog={() => setDialogOpen(false)}
      />
    </>
  );
};

export default PricingSettings;
