import { ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

// TODO update text and color, when new will be added
const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      text: ['hd-h1', 'hd-h2', 'hd-h3', 'hd-h4', 'p-l', 'p-m'],
      color: [
        'base-accent',
        'base-accent-foreground',
        'base-background',
        'base-border',
        'base-card',
        'base-card-foreground',
        'base-destructive',
        'base-destructive-foreground',
        'base-foreground',
        'base-input',
        'base-muted',
        'base-muted-foreground',
        'base-popover',
        'base-popover-foreground',
        'base-primary',
        'base-primary-foreground',
        'base-ring',
        'base-ring-offset',
        'base-secondary',
        'base-secondary-foreground',
        'base-chart-1',
        'base-chart-2',
        'base-chart-3',
        'base-chart-4',
        'base-chart-5',
        'base-sidebar-background',
        'base-sidebar-foreground',
        'base-sidebar-primary',
        'base-sidebar-primary-foreground',
        'base-sidebar-accent',
        'base-sidebar-accent-foreground',
        'base-sidebar-border',
        'base-sidebar-ring',
        'base-black',
        'base-white',
        'alpha-10',
        'alpha-20',
        'alpha-30',
        'alpha-40',
        'alpha-50',
        'alpha-60',
        'alpha-70',
        'alpha-80',
        'alpha-90'
      ]
    }
  }
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
