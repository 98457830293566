import { useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  Background,
  EBadgePosition,
  EBundlesViewModel,
  EFontWeights
} from '@appcharge/shared-ui';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Grid, Tab } from '@mui/material';
import useBadges from 'api/useBadges';
import useImages from 'api/useImages';
import useOffers from 'api/useOffers';
import useOffersUI from 'api/useOffersUI';
import useTheme from 'api/useTheme';
import useUsers from 'api/useUsers';
import {
  Badge,
  BundleBadge,
  EBadgeType,
  Offer,
  OfferBadge
} from 'common/contracts';
import {
  DEFAULT_BACKGROUND_URL,
  MIN_USD_PRICE,
  PRICE_VALUE_LIMIT,
  TRIMMED_INPUT_REGEX
} from 'constants/constants';
import {
  EAssetType,
  EFeatureFlag,
  ENotificationType,
  EProductPriority,
  EProductType,
  EPromotionsFormState,
  ESaleDiscountDisplayType,
  ESpecialOfferInternalViewModel,
  OfferType
} from 'constants/enums';
import Decimal from 'decimal.js';
import { useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { useUnsavedChanges } from 'hooks/useUnsavedChanges';
import { permissionsUtil } from 'utils/permissionsUtil';
import { renderingUtil } from 'utils/renderingUtil';
import { timeUtils } from 'utils/scheduleUtils';
import { ProductProps } from 'views/Popups/types';
import * as yup from 'yup';

import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import AcTabs from 'components/AcTabs/AcTabs';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';
import { Product } from 'components/ProductsForm/ProductsForm.types';
import PageTopBar from 'components/Topbar/PageTopBar';

import ScheduleTab from './Tabs/ScheduleTab';
import SettingsTab from './Tabs/SettingsTab';

import '../../style/forms.scss';

const mapOfferModelToHasOneMain: Partial<
  Record<ESpecialOfferInternalViewModel, boolean>
> = {
  [ESpecialOfferInternalViewModel.HUGE]: true,
  [ESpecialOfferInternalViewModel.BABA]: true,
  [ESpecialOfferInternalViewModel.PIPA]: true,
  [ESpecialOfferInternalViewModel.BIG_FISH_CASINO]: true,
  [ESpecialOfferInternalViewModel.JACKPOT_SLOTS]: true
};

const mapViewModelToBadgeType: Record<EBundlesViewModel, EBadgeType> = {
  [EBundlesViewModel.LINEAR]: EBadgeType.EMBLEM,
  [EBundlesViewModel.THREE_IN_ONE]: EBadgeType.RIBBON,
  [EBundlesViewModel.SIX_IN_ONE]: EBadgeType.EMBLEM,
  [EBundlesViewModel.TWO_IN_ONE]: EBadgeType.RIBBON
};

interface IPromotionsForm {
  edit?: boolean;
  dup?: boolean;
}

const PromotionsForm: React.FC<IPromotionsForm> = ({
  edit = false,
  dup = false
}) => {
  const { publisherId } = useParams();
  const navigate = useNavigate();
  const { specialOfferId } = useParams();
  const { enqueueSnackbar } = useNotifications();

  const [data, setData] = useState<Partial<Offer>>({});
  const [currentOfferUI, setCurrentOfferUI] = useState('');
  const [chosenSegment, setChosenSegment] = useState<string[]>([]);
  const [tab, setTab] = useState(EPromotionsFormState.GENERAL);
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [active, setActive] = useState<boolean>(true);
  const [bundleModel, setBundleModel] =
    useState<ESpecialOfferInternalViewModel>();
  const [badgeList, setBadgeList] = useState<BundleBadge[]>([]);
  const [dirtyProducts, setDirtyProducts] = useState(false);
  const [startHour, setStartHour] = useState<string>('');

  const { updateOffer, addOffer, getOffer, getOffers } = useOffers(
    specialOfferId,
    OfferType.SPECIAL_OFFER
  );
  const [badges, setBadges] = useState(getOffer?.data?.result?.badges);
  const [emblemBadges, setEmblemBadges] = useState<object[]>([]);
  const [ribbonBadges, setRibbonBadges] = useState<object[]>([]);
  const { getOfferUI, getOffersUI } = useOffersUI(publisherId, currentOfferUI);
  const { getStoreTheme } = useTheme(publisherId);
  const viewModel = getStoreTheme?.data?.general
    ?.bundlesViewModel as EBundlesViewModel;
  const { getBadges } = useBadges(publisherId);
  const { getImages } = useImages(publisherId);
  const { fetchFeatureFlags } = useUsers({ publisherId });

  const hasFeatureFlagTags =
    fetchFeatureFlags.data?.featureFlags?.[EFeatureFlag.DASHBOARD_TAGS];

  const hasScheduleOfferFeatureFlag =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_SCHEDULED_OFFERS
    ];

  const checkUniqueness = (value: string | undefined, field: keyof Offer) => {
    const isTaken = getOffers.data?.offers.find((o: Offer) => {
      if (!edit) return o[field] === value;
      return o[field] === value && data[field] !== value;
    });
    return !isTaken;
  };

  const handleTabChange = (
    event: any,
    newValue:
      | EPromotionsFormState
      | ((prevState: EPromotionsFormState) => EPromotionsFormState)
  ) => {
    setTab(newValue);
  };

  let offerSchema = yup.object().shape({
    setAsFree: yup.boolean(),
    name: yup
      .string()
      .matches(TRIMMED_INPUT_REGEX, 'Name should not be spaces')
      .min(3, `Name length should be longer`)
      .required('Name is required')
      .test('uniqueness', 'Name already exists', (value) => {
        return checkUniqueness(value, 'name');
      }),
    description: yup
      .string()
      .nullable()
      .test(
        'is-empty-or-min-length',
        'Description length should be longer',
        (value) => !value || value.length >= 3
      ),
    offerUiId: yup.string().required('Offer Design is required'),
    publisherOfferId: yup
      .string()
      .matches(TRIMMED_INPUT_REGEX, 'SKU should not be spaces')
      .required('SKU is required')
      .test('uniqueness', 'SKU already exists', (value) => {
        return checkUniqueness(value, 'publisherOfferId');
      }),
    price: yup.number().when('setAsFree', {
      is: true,
      then: yup
        .number()
        .oneOf([0], 'Price must be 0 if the offer is set as free'),
      otherwise: yup
        .number()
        .min(0.8, 'Price must be at least 0.8')
        .max(5000, 'Price must be at most 5000')
        .required('Price is required')
        .test('maxDigits', 'Price must have at most 10 digits', (value) =>
          value ? value.toString().length <= PRICE_VALUE_LIMIT : true
        )
    }),
    discount: yup
      .number()
      .min(0, 'Price discount cannot be negative')
      .max(99, 'Price discount cannot be more than 99%')
      .test(
        'is-one-decimal',
        'Price discount can have one decimal place at most',
        (value) =>
          value === undefined || /^\d+(\.\d{1})?$/.test(value.toString())
      ),
    playerAvailability: yup
      .number()
      .min(1, 'Availability must be at least 1')
      .max(99, 'Availability cannot exceed 99')
      .when(['setAsFree', 'schedule.permanent.interval'], {
        is: (setAsFree: boolean, interval: string) =>
          setAsFree || interval || startHour,
        then: yup
          .number()
          .required(
            'Player availability is required when the offer is set as free, or if the offer is permanent with intervals'
          ),
        otherwise: yup.number()
      })
      .max(99, 'Availability cannot exceed 99'),
    salePercentage: yup
      .number()
      .min(0, 'Sale amount cannot be negative')
      .test(
        'is-one-decimal',
        'Sale amount can have one decimal place at most',
        (value) =>
          value === undefined || /^\d+(\.\d{1})?$/.test(value.toString())
      ),
    tags: yup.array().of(yup.string())
  });

  useEffect(() => {
    currentOfferUI && getOfferUI.refetch();
  }, [currentOfferUI]);

  useEffect(() => {
    if (specialOfferId && publisherId) {
      getOffer.refetch();
    }
  }, [specialOfferId, publisherId]);

  useEffect(() => {
    if (!getStoreTheme.data?.general?.specialOffersInternalViewModel) return;
    setBundleModel(getStoreTheme.data.general.specialOffersInternalViewModel);
  }, [getStoreTheme.data]);

  useEffect(() => {
    data?.segments && setChosenSegment(data?.segments || []);
  }, [data?.segments]);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [edit, data]);

  useEffect(() => {
    if (!!specialOfferId && !getOffer.isLoading && getOffer.data) {
      setData(getOffer.data.result);
      setCurrentOfferUI(getOffer.data.result.offerUi?.offerUiId || '');
      setProducts(
        getOffer.data.result.productsSequence[0].products.map(
          (
            p: {
              product: Partial<Product>;
              quantity: number;
              quantityDisplay: string;
            },
            index: number
          ) => {
            return {
              _id: p.product?.productId || index,
              productId: p.product?.productId,
              image: p.product?.images?.[0].url,
              imagePrefix: p.product?.images?.find(
                (i) => i.type === EAssetType.PRODUCT_PREFIX
              )?.url,
              name: p.product?.name,
              amount: p?.quantity,
              type: p.product?.type,
              textFontColorHex: p.product?.textFontColorHex,
              prefix: p.product?.prefix,
              suffix: p.product?.suffix,
              publisherProductId: p.product?.publisherProductId,
              quantityDisplay: p.quantityDisplay
            };
          }
        )
      );
    }
  }, [getOffer.data, getOffer.isLoading, specialOfferId]);

  useEffect(() => {
    const initialStartHour = getOffer.data?.result?.schedule?.interval
      ? timeUtils.parseCronToHour(getOffer.data.result.schedule.interval)
      : '';
    if (startHour !== initialStartHour) {
      setStartHour(initialStartHour);
    }
  }, [getOffer.data?.result]);

  const offersViewModel: ESpecialOfferInternalViewModel = useMemo(
    () => getStoreTheme.data?.general?.specialOffersInternalViewModel,
    [getStoreTheme]
  );

  const relevantBadgeType: EBadgeType = useMemo(() => {
    if (
      bundleModel === ESpecialOfferInternalViewModel.GHOST ||
      bundleModel === ESpecialOfferInternalViewModel.MATCH_MASTERS
    )
      return EBadgeType.EMBLEM;
    if (bundleModel === ESpecialOfferInternalViewModel.PLAY_SIMPLE)
      return EBadgeType.RIBBON;

    if (viewModel) {
      return mapViewModelToBadgeType[viewModel as EBundlesViewModel];
    }

    return EBadgeType.RIBBON;
  }, [viewModel, bundleModel]);

  const filterRelevantBadges = (badgeType: EBadgeType) => {
    if (
      (viewModel === EBundlesViewModel.LINEAR ||
        viewModel === EBundlesViewModel.TWO_IN_ONE ||
        viewModel === EBundlesViewModel.THREE_IN_ONE ||
        viewModel === EBundlesViewModel.SIX_IN_ONE) &&
      badgeType === relevantBadgeType
    ) {
      return true;
    }
    return false;
  };

  const {
    values,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
    dirty,
    errors,
    touched,
    setFieldValue,
    setTouched,
    validateField,
    validateForm
  } = useFormik({
    validateOnMount: true,
    validationSchema: offerSchema,
    enableReinitialize: true,
    initialValues: {
      setAsFree:
        (edit || dup) &&
        data.productsSequence?.[0]?.priceInUsdCents !== undefined &&
        new Decimal(data.productsSequence[0].priceInUsdCents)
          .div(100)
          .toNumber() === 0,
      publisherOfferId: data.publisherOfferId || '',
      offerId: data.offerId,
      price:
        data.productsSequence?.[0]?.priceInUsdCents !== undefined
          ? new Decimal(data.productsSequence[0].priceInUsdCents)
              .div(100)
              .toNumber() === 0
            ? 0
            : new Decimal(data.productsSequence[0].priceInUsdCents)
                .div(100)
                .toNumber()
          : MIN_USD_PRICE,
      playerAvailability:
        data.productsSequence?.[0]?.playerAvailability ?? undefined,
      name: data.name ? `${data.name}${dup ? '_copy' : ''}` : '',
      displayName: data.displayName
        ? `${data.displayName}${dup ? '_copy' : ''}`
        : data.name
          ? `${data.name}${dup ? '_copy' : ''}`
          : '',
      ...(data.description && { description: data.description }),
      type: data.type || '',
      offerUiId: data.offerUi?._id || data.offerUi?.offerUiId || '',
      coolDownInHours: data.coolDownInHours || 0,
      segments: data.segments || [],
      schedule: data.schedule || {
        permanent: true,
        timeFrames: []
      },
      active: data.active ?? true,
      tags: data.tags || [],
      quantity: '',
      backgroundImage: data?.offerUi?.backgroundImage || '',
      productsSequence: data.productsSequence || {},
      leftBadge:
        (
          data?.badges?.find(
            (b) => b?.badge?.position === 'left' && b.badge?.type === 'emblem'
          )?.badge as Badge
        )?.badgeId || '',
      rightBadge:
        (
          data?.badges?.find(
            (b) => b?.badge?.position === 'right' && b.badge?.type === 'emblem'
          )?.badge as Badge
        )?.badgeId || '',
      badge:
        data?.badges?.find((b) => {
          return filterRelevantBadges(b.badge!.type);
        })?.badge?.badgeId || null,
      publisherBadgeId:
        data?.badges?.find((b) => {
          return filterRelevantBadges(b.badge!.type);
        })?.badge?.publisherBadgeId || '',
      salePercentage: data?.productSale?.sale || 0,
      salePercentageDisplayType:
        data.productSale?.type || ESaleDiscountDisplayType.PERCENTAGE,
      discount: data?.priceDiscount?.discount || 0,
      priceDiscountDisplayType:
        data.priceDiscount?.type || ESaleDiscountDisplayType.PERCENTAGE
    },
    onSubmit: async (values) => {
      const offerBadges: OfferBadge[] = [];
      if (values?.badge) {
        const publisherBadgeId =
          values?.publisherBadgeId || badges[0]?.badge?.publisherBadgeId;
        const badge: OfferBadge = {
          badgeId: values?.badge,
          publisherBadgeId: publisherBadgeId
        };
        offerBadges.push(badge);
      }
      const newOffer: Partial<Offer> & { sectionId?: string | null } = {
        publisherOfferId: values.publisherOfferId,
        name: values.name,
        displayName: values.displayName || values.name,
        ...(values.description && { description: values.description }),
        type: OfferType.SPECIAL_OFFER,
        active: active,
        offerUiId: values.offerUiId,
        coolDownInHours: 1,
        segments: chosenSegment,
        schedule: values.schedule,
        tags: values.tags,
        badges: offerBadges,
        productSale: {
          sale: values.salePercentage || 0,
          type:
            values.salePercentageDisplayType ||
            ESaleDiscountDisplayType.PERCENTAGE
        },
        priceDiscount: {
          discount: values.discount,
          type:
            values.priceDiscountDisplayType ||
            ESaleDiscountDisplayType.PERCENTAGE
        },
        productsSequence: [
          {
            index: 1,
            products: products.map((p) => ({
              productId: p.productId,
              quantity: p.amount,
              publisherProductId: p.publisherProductId
            })),
            priceInUsdCents: values.setAsFree
              ? 0
              : new Decimal(values.price).mul(100).toNumber(),
            ...(values.playerAvailability
              ? { playerAvailability: values.playerAvailability }
              : undefined)
          }
        ]
      };
      if (edit && specialOfferId && !dup) {
        updateOffer.mutate(
          { offerId: specialOfferId, form: newOffer },
          {
            onSuccess: () => {
              enqueueSnackbar(
                'Promotion edited successfully',
                ENotificationType.SUCCESS
              );
              navigate('../');
            },
            onError: () => {
              enqueueSnackbar(
                'Error editing promotion',
                ENotificationType.ERROR
              );
            }
          }
        );
      } else {
        await addOffer.mutate(newOffer, {
          onSuccess: () => {
            enqueueSnackbar(
              'New Promotion added successfully',
              ENotificationType.SUCCESS
            );
            navigate('../');
          },
          onError: () => {
            enqueueSnackbar('Error adding Promotion', ENotificationType.ERROR);
          }
        });
      }
    }
  });

  useEffect(() => {
    if (values.badge) {
      const currentBadge = getBadges?.data?.result?.find(
        (b: Badge) => b.badgeId === values.badge
      );
      setBadges([{ badge: currentBadge }]);
      if (currentBadge?.emblem) {
        setEmblemBadges([currentBadge.emblem]);
      }
      if (currentBadge?.ribbon) {
        setRibbonBadges([currentBadge.emblem]);
      }
    } else {
      const badges = getOffer?.data?.result?.badges;
      if (badges && badges?.length > 0) {
        setBadges(badges);
      }
    }
  }, [values?.badge]);

  useEffect(() => {
    setActive(values.active);
  }, [values?.active]);

  useEffect(() => {
    if (!getBadges.data?.result) return;
    const badgesDropdownItems = getBadges.data?.result
      .map((badge: Badge) => {
        if (filterRelevantBadges(badge.type))
          return {
            content: badge.name,
            name: badge.name,
            key: badge.badgeId,
            value: badge.badgeId,
            url: badge.emblem?.imageUrl,
            position: badge.position as EBadgePosition
          };
      })
      .filter((b: any) => b !== undefined);
    setBadgeList(badgesDropdownItems);
  }, [getBadges.data, viewModel, bundleModel]);

  useUnsavedChanges({ dirty });
  const transformPriceValue = () => {
    if (!values.price) return MIN_USD_PRICE;
    return String(values.price).length > PRICE_VALUE_LIMIT
      ? parseFloat(String(values.price).slice(0, PRICE_VALUE_LIMIT))
      : values.price;
  };

  const formikProps = {
    values,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
    dirty,
    errors,
    touched,
    setFieldValue,
    setTouched,
    validateField,
    validateForm
  };

  return (
    <AcViewWrapper
      header={
        <>
          <PageTopBar
            withTabsDesign={true}
            disable={false}
            headline={`${edit ? 'Edit' : 'New'} Promotion ${
              edit
                ? `(${
                    !getOffer.isLoading && getOffer.data?.result
                      ? getOffer.data?.result?.name
                      : '...'
                  })`
                : ''
            }`}
            buttons={[
              {
                text: 'Save',
                action: () => submitForm(),
                disabled:
                  isLoading ||
                  !isValid ||
                  !permissionsUtil.canUserEdit() ||
                  (edit && !dirty && !dirtyProducts) ||
                  (hasScheduleOfferFeatureFlag &&
                    !values?.schedule?.permanent &&
                    !values?.schedule?.timeFrames?.length)
              }
            ]}
            backFunction={() => navigate('../')}
          />
          <Box pl={'3rem'} pr={'3rem'}>
            <AcTabs value={tab} onChange={handleTabChange}>
              <Tab
                label="Settings"
                value={EPromotionsFormState.GENERAL}
                disabled={getOffer.isLoading && edit}
              />
              {hasScheduleOfferFeatureFlag && (
                <Tab
                  label="Schedule"
                  value={EPromotionsFormState.SCHEDULE}
                  disabled={getOffer.isLoading && edit}
                />
              )}
            </AcTabs>
          </Box>
        </>
      }
    >
      {!getImages.isLoading && getImages.data && (
        <AcContentWrapper className="formContent">
          <TabContext value={tab}>
            <TabPanel value={EPromotionsFormState.GENERAL} sx={{ padding: 0 }}>
              <Grid container sx={{ display: 'flex' }}>
                <Grid item xs={5}>
                  <SettingsTab
                    formikProps={formikProps}
                    values={values}
                    setCurrentOfferUI={setCurrentOfferUI}
                    chosenSegment={chosenSegment}
                    setChosenSegment={setChosenSegment}
                    hasFeatureFlagTags={hasFeatureFlagTags}
                    getOffersUI={getOffersUI}
                    specialOfferId={specialOfferId}
                    products={products}
                    setProducts={setProducts}
                    edit={edit}
                    relevantBadgeType={relevantBadgeType}
                    badgeList={badgeList}
                    setDirtyProducts={setDirtyProducts}
                    setStartHour={setStartHour}
                    dup={dup}
                  />
                </Grid>
                <Grid item xs={7} className="iphone-mock-wrapper">
                  <div className="iphone-mock">
                    <Background
                      backgroundImageMobile={
                        !getStoreTheme.isLoading &&
                        getStoreTheme.data?.general?.backgroundImageMobile
                      }
                      backgroundImageDesktop={
                        !getStoreTheme.isLoading &&
                        getStoreTheme.data?.general?.backgroundImageDesktop
                      }
                      width="100%"
                      height="100%"
                      position="absolute"
                    />
                    <div className="bundles-wrapper">
                      {!getStoreTheme.isLoading &&
                        renderingUtil.renderSpecialOfferByInternalModel(
                          offersViewModel,
                          {
                            title: getOfferUI.data?.specialOffer?.title || '',
                            fontSize:
                              getOfferUI.data?.specialOffer?.fontSize || 0,
                            fontWeight:
                              getOfferUI.data?.specialOffer?.fontWeight ||
                              ('normal' as EFontWeights),
                            textColor: getOfferUI.data?.specialOffer
                              ?.fontColor || {
                              colorOne: '#000'
                            },
                            subTitle: getOfferUI.data?.specialOffer?.subTitle
                              ?.text
                              ? getOfferUI.data?.specialOffer?.subTitle?.text
                              : '',
                            subTitleSize:
                              getOfferUI.data?.specialOffer?.subTitle
                                ?.fontSize || 0,
                            subTitleWeight:
                              getOfferUI.data?.specialOffer?.subTitle
                                ?.fontWeight || ('normal' as EFontWeights),
                            subTitleColor: getOfferUI.data?.specialOffer
                              ?.subTitle?.fontColor || {
                              colorOne: '#000'
                            },
                            design: 'match',
                            badges: badges,
                            ribbonBadges: ribbonBadges,
                            emblemBadges: emblemBadges,
                            buttonTextColor: '#fff',
                            borderColor:
                              getOfferUI.data?.specialOffer?.borderColor ||
                              getStoreTheme.data.storeScreen.bundleBorderColor,
                            selectOffer: () => {},
                            backgroundColor: {
                              colorOne:
                                getOfferUI.data?.specialOffer?.backgroundColor
                                  ?.colorOne || '#ffffff00',
                              colorTwo:
                                getOfferUI.data?.specialOffer?.backgroundColor
                                  ?.colorTwo || '',
                              direction:
                                getOfferUI.data?.specialOffer?.borderColor
                                  ?.direction || 'to right'
                            },
                            buttonColor:
                              getStoreTheme.data?.general?.buttonColor,
                            backgroundImage:
                              getOfferUI.data?.backgroundImage ||
                              values?.backgroundImage ||
                              DEFAULT_BACKGROUND_URL,
                            prices: {
                              priceDisplay: `$${transformPriceValue()}`,
                              priceInUsdCents: values?.price
                            },
                            productsList:
                              products.length > 0
                                ? products.map((p, index) => {
                                    return {
                                      quantity: p?.amount,
                                      quantityDisplay: p?.quantityDisplay,
                                      images: {
                                        product: p?.image,
                                        productPrefix: p?.imagePrefix
                                      },
                                      textFontColorHex: p?.textFontColorHex,
                                      prefix: p?.prefix,
                                      suffix: p?.suffix,
                                      priority: mapOfferModelToHasOneMain[
                                        offersViewModel
                                      ]
                                        ? index === 0
                                          ? EProductPriority.MAIN
                                          : EProductPriority.SUB
                                        : EProductPriority.MAIN,
                                      type: 'Quantity' as EProductType
                                    };
                                  })
                                : [],
                            isFree: values.setAsFree,
                            salePercentage: values.salePercentage || 0,
                            salePercentageDisplayType:
                              values.salePercentageDisplayType,
                            inMobileMock: true
                          }
                        )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            {hasScheduleOfferFeatureFlag && (
              <TabPanel
                value={EPromotionsFormState.SCHEDULE}
                sx={{ padding: 0 }}
              >
                <ScheduleTab
                  formikProps={formikProps}
                  values={values}
                  updateOffer={updateOffer}
                  edit={edit}
                  startHour={startHour}
                  setStartHour={setStartHour}
                />
              </TabPanel>
            )}
          </TabContext>
        </AcContentWrapper>
      )}
    </AcViewWrapper>
  );
};

export default PromotionsForm;
