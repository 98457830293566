import { createSlice } from '@reduxjs/toolkit';

import { AuthSliceState } from './store.types';

const initialAuthSliceState: AuthSliceState = {
  authJwt: null,
  userId: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthSliceState,
  reducers: {
    login(state: AuthSliceState, action) {
      if (action.payload?.authJwt) state.authJwt = action.payload.authJwt;
      state.userId = action.payload.userId;
    },
    clearState() {
      return initialAuthSliceState;
    }
  }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
