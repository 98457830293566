import { useDispatch, useSelector } from 'react-redux';

import axios, { AxiosRequestHeaders } from 'axios';

import { ELocalStorageKeys } from '../constants/enums';
import { AuthSliceState } from '../store/store.types';
import { uiActions } from '../store/uiSlice';
import { localStorageUtil } from '../utils/localStorageUtil';

import { useLogin } from './useLogin';
import { useParams } from 'react-router-dom';

axios.defaults.baseURL =
  localStorageUtil.getAny(ELocalStorageKeys.ENVIRONMENT) ||
  process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

export default function useAxios() {
  const { logout } = useLogin();
  const dispatch = useDispatch();

  const authToken =
    useSelector(({ auth }: { auth: AuthSliceState }) => auth.authJwt) ||
    localStorageUtil.get(ELocalStorageKeys.JWT_TOKEN) ||
    localStorageUtil.get(ELocalStorageKeys.JWT_TOKEN, true);

  const { publisherId } = useParams();

  const headersData: AxiosRequestHeaders | undefined = authToken
    ? { 'x-access-token': authToken }
    : publisherId
      ? { 'x-publishers': publisherId }
      : undefined;

  const post = async (
    path: string,
    data: any,
    customHeaders?: AxiosRequestHeaders
  ) => {
    dispatch(uiActions.setGlobalLoading(true));
    return await axios({
      url: path,
      method: 'POST',
      headers: customHeaders ?? headersData,
      data
    })
      .then((res) => {
        dispatch(uiActions.setUnsavedChanges(false));
        return res;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        }
        return Promise.reject(err);
      })
      .finally(() => {
        dispatch(uiActions.setGlobalLoading(false));
      });
  };

  const get = async (
    path: string,
    params?: any,
    customHeaders?: AxiosRequestHeaders
  ) => {
    const response = await axios({
      url: path,
      method: 'GET',
      headers: customHeaders ?? headersData,
      params: params || undefined
    }).catch((err) => {
      if (err.response.status === 401) {
        logout();
      }
      return Promise.reject(err);
    });
    return response!.data;
  };

  const put = async (
    path: string,
    data: any,
    customHeaders?: AxiosRequestHeaders
  ) => {
    dispatch(uiActions.setGlobalLoading(true));
    const response = await axios({
      url: path,
      method: 'PUT',
      headers: customHeaders ?? headersData,
      data
    })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        }
        return Promise.reject(err);
      })
      .finally(() => {
        dispatch(uiActions.setGlobalLoading(false));
      });
    return response!.data;
  };

  const del = async (
    path: string,
    data?: any,
    customHeaders?: AxiosRequestHeaders
  ) => {
    dispatch(uiActions.setGlobalLoading(true));
    const config: {
      url: string;
      method: string;
      headers: AxiosRequestHeaders | undefined;
      data?: any;
    } = {
      url: path,
      method: 'DELETE',
      headers: customHeaders ?? headersData,
      data
    };
    if (data) {
      config.data = data;
    }
    const response = await axios(config)
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        }
        return Promise.reject(err);
      })
      .finally(() => {
        dispatch(uiActions.setGlobalLoading(false));
      });
    return response!.data;
  };

  const patch = async (path: string, data: any) => {
    dispatch(uiActions.setGlobalLoading(true));
    const response = await axios({
      url: path,
      method: 'PATCH',
      headers: headersData,
      data
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          logout();
        }
        return Promise.reject(err);
      })
      .finally(() => {
        dispatch(uiActions.setGlobalLoading(false));
      });
    return response!.data;
  };

  return { post, get, put, del, patch };
}
