import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useIntegration from 'api/useIntegration';
import usePublisher from 'api/usePublisher';
import { IS_NO_IP_ONLY_HTTPS } from 'constants/constants';
import { ENotificationType } from 'constants/enums';
import { required } from 'utils/errorsTextHelper';
import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import ActionButton from 'components/ActionButton/ActionButton';
import { useNotifications } from 'hooks/useNotifications';
import { IntegrationSettingsData } from '../Settings.types';

import 'style/forms.scss';
import useUsers from 'api/useUsers';
import { useProfilesManager } from 'hooks/useProfilesManager';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';

export enum EEncryptionType {
  SYMMETRIC_ENCRYPTION = 'SymmetricEncryption',
  SIGNATURE_HASHING = 'SignatureHashing'
}

const IntegrationSettings = () => {
  const { id } = useParams<{ id: string }>();
  const { toggleIntegrationProfiles, isProfilesEnabled, isToggleInProgress } =
    useProfilesManager();
  const { getIntegration, updateIntegration }: any = useIntegration(
    id as string
  );
  const { fetchFeatureFlags } = useUsers({ enableFeatureFlags: false });
  const { getPublisher } = usePublisher(id || undefined);
  const data = getIntegration?.data;
  const integrationRefetch = getIntegration?.refetch;
  const { enqueueSnackbar } = useNotifications();
  const isProfilesFeatureFlagOn = useMemo(
    () => fetchFeatureFlags?.data?.featureFlags?.dashboard_integration_profiles,
    [fetchFeatureFlags?.data?.featureFlags?.dashboard_integration_profiles]
  );

  useEffect(() => {
    return () => {
      integrationRefetch();
    };
  }, [integrationRefetch]);

  const saveWebhooksDetails = (
    webhookValues: Partial<IntegrationSettingsData>
  ) => {
    updateIntegration.mutate(webhookValues, {
      onSuccess: () => {
        getPublisher.refetch();
        enqueueSnackbar(
          'Webhook details updated successfully',
          ENotificationType.SUCCESS
        );
      },
      onError: (data: any) => {
        enqueueSnackbar(
          'Failed to update Webhook details',
          ENotificationType.ERROR
        );
      }
    });
  };

  const webhooksSchema = yup.object().shape({
    ordersReportingApiUrl: yup
      .string()
      .required(required('Orders Reporting API URL'))
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    publisherToken: yup.string(),
    playerInfoSyncUrl: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    eventsWebhookUrl: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    playersAuthWebhook: yup
      .string()
      .test(
        'Must be a valid URL, starting with https://, and not an IP address',
        'Must be a valid URL, starting with https://, and not an IP address',
        (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
      ),
    apiUrlPrimaryKey: yup.string().required(required('Main Key')),
    apiUrlSecondaryKey: yup.string().required(required('Secondary Key'))
  });

  const initialValues = useMemo(
    () => ({
      publisherToken: data?.publisherToken || '',
      encryptionModel: data?.encryptionModel || '',
      playerInfoSyncUrl: data?.playerInfoSyncUrl || '',
      playersAuthWebhook: data?.playersAuthWebhook || '',
      ordersReportingApiUrl: data?.ordersReportingApiUrl || '',
      apiUrlPrimaryKey: data?.apiUrlPrimaryKey || '',
      apiUrlSecondaryKey: data?.apiUrlSecondaryKey || '',
      eventsWebhookUrl: data?.eventsWebhookUrl || ''
    }),
    [data]
  );

  const webhooksForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: webhooksSchema,
    onSubmit: async (values) => {
      if (values) {
        saveWebhooksDetails(values as Partial<IntegrationSettingsData>);
      }
    }
  });

  const toggleIntegrationProfilesHandler = () => {
    toggleIntegrationProfiles(!isProfilesEnabled, {
      ...webhooksForm.values,
      fbAppId: data?.playersAuthentication.fbAppId
    });
  };

  return (
    <Grid container className="formContent">
      <Grid item xs={5} className="formContent-minWIdth">
        <Stack>
          <AcCard stackContainer={false} title="Integration links">
            {isProfilesFeatureFlagOn && (
              <Box style={{ display: 'flex' }}>
                <p style={{ marginRight: '16px' }}>
                  Enable Integration Profiles
                </p>
                <CustomizedSwitch
                  status={isProfilesEnabled}
                  texts={[]}
                  disabled={isToggleInProgress}
                  functions={[
                    toggleIntegrationProfilesHandler,
                    toggleIntegrationProfilesHandler
                  ]}
                />
              </Box>
            )}
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcInput
                  header="Orders Reporting API"
                  name="ordersReportingApiUrl"
                  value={webhooksForm.values.ordersReportingApiUrl}
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  disabled={isProfilesEnabled}
                  error={
                    webhooksForm.touched.ordersReportingApiUrl &&
                    Boolean(webhooksForm.errors.ordersReportingApiUrl)
                  }
                  helperText={
                    webhooksForm.touched.ordersReportingApiUrl
                      ? webhooksForm.errors.ordersReportingApiUrl?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  header="Player Info Sync URL"
                  name="playerInfoSyncUrl"
                  value={webhooksForm.values.playerInfoSyncUrl}
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  disabled={isProfilesEnabled}
                  error={
                    webhooksForm.touched.playerInfoSyncUrl &&
                    Boolean(webhooksForm.errors.playerInfoSyncUrl)
                  }
                  helperText={
                    webhooksForm.touched.playerInfoSyncUrl
                      ? webhooksForm.errors.playerInfoSyncUrl?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  header="Authentication Webhook"
                  name="playersAuthWebhook"
                  value={webhooksForm.values.playersAuthWebhook}
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  disabled={isProfilesEnabled}
                  error={
                    webhooksForm.touched?.playersAuthWebhook &&
                    Boolean(webhooksForm.errors?.playersAuthWebhook)
                  }
                  helperText={
                    webhooksForm.touched?.playersAuthWebhook
                      ? webhooksForm.errors?.playersAuthWebhook?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <AcInput
                  header="Events Webhook"
                  name="eventsWebhookUrl"
                  value={webhooksForm.values.eventsWebhookUrl}
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  disabled={isProfilesEnabled}
                  error={
                    webhooksForm.touched?.eventsWebhookUrl &&
                    Boolean(webhooksForm.errors?.eventsWebhookUrl)
                  }
                  helperText={
                    webhooksForm.touched?.eventsWebhookUrl
                      ? webhooksForm.errors?.eventsWebhookUrl?.toString()
                      : ''
                  }
                  customClass="url-input"
                />
              </Grid>
              <Grid item xs={12}>
                <AcSelect
                  items={[
                    {
                      value: EEncryptionType.SYMMETRIC_ENCRYPTION,
                      content: 'Symmetric Encryption'
                    },
                    {
                      value: EEncryptionType.SIGNATURE_HASHING,
                      content: 'Signature Hashing'
                    }
                  ]}
                  name="encryptionModel"
                  value={webhooksForm.values.encryptionModel}
                  header="Encryption Type"
                  onChange={webhooksForm.handleChange}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  header="Main Key"
                  name="apiUrlPrimaryKey"
                  value={webhooksForm.values.apiUrlPrimaryKey}
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  error={
                    webhooksForm.touched.apiUrlPrimaryKey &&
                    Boolean(webhooksForm.errors.apiUrlPrimaryKey)
                  }
                  helperText={
                    webhooksForm.touched.apiUrlPrimaryKey
                      ? webhooksForm.errors.apiUrlPrimaryKey?.toString()
                      : ''
                  }
                  type="password"
                  enableCopy
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  header="Checkout Public Key"
                  name="checkoutPublicKey"
                  value={data?.checkoutPublicKey}
                  disabled={true}
                  enableCopy
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  opacity:
                    webhooksForm.values.encryptionModel ===
                    EEncryptionType.SIGNATURE_HASHING
                      ? 0.5
                      : 1
                }}
              >
                <AcInput
                  header="Secondary Key"
                  name="apiUrlSecondaryKey"
                  value={webhooksForm.values.apiUrlSecondaryKey}
                  onChange={webhooksForm.handleChange}
                  onBlur={webhooksForm.handleBlur}
                  error={
                    webhooksForm.touched.apiUrlSecondaryKey &&
                    Boolean(webhooksForm.errors.apiUrlSecondaryKey)
                  }
                  helperText={
                    webhooksForm.touched.apiUrlSecondaryKey
                      ? webhooksForm.errors.apiUrlSecondaryKey?.toString()
                      : ''
                  }
                  disabled={
                    webhooksForm.values.encryptionModel ===
                    EEncryptionType.SIGNATURE_HASHING
                  }
                  type="password"
                  enableCopy
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  header="Publisher Token"
                  name="publisherToken"
                  value={webhooksForm.values.publisherToken}
                  onChange={webhooksForm.handleChange}
                  type="password"
                  onBlur={webhooksForm.handleBlur}
                  disabled
                  error={
                    webhooksForm.touched.publisherToken &&
                    Boolean(webhooksForm.errors.publisherToken)
                  }
                  helperText={
                    webhooksForm.touched.publisherToken
                      ? webhooksForm.errors.publisherToken?.toString()
                      : ''
                  }
                  enableCopy
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
        <Grid item mt={3} xs={6}>
          <ActionButton
            variant="outlined"
            text="Update"
            onClick={webhooksForm.submitForm}
            disabled={
              !webhooksForm.isValid ||
              !webhooksForm.dirty ||
              webhooksForm.isSubmitting
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntegrationSettings;
