import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Background } from '@appcharge/shared-ui';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import useImages from 'api/useImages';
import useTheme from 'api/useTheme';
import { EAssetType, EBundlesInternalViewModel } from 'constants/enums';
import { useFormikContext } from 'formik';
import { UploadsTooltips, useUploads } from 'hooks/useUpload';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

import { ThemeLoader } from '../ThemeLoader';
import { getFonts } from '../utils';

import { FooterSection } from './Components/FooterSection';
import { GeneralThemeFormValues } from './generalTheme.types';

import 'style/forms.scss';

export const GeneralSettings: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { values, handleBlur, handleChange, setFieldValue, errors, touched } =
    useFormikContext<GeneralThemeFormValues>();

  const { publisherId } = useParams();

  const { getStoreTheme: storeTheme } = useTheme(publisherId);

  const pictures = useImages(publisherId).getImages;
  const addPictureInputRef = useRef<HTMLInputElement>(null);
  const [currentUploadingField, setCurrentUploadingField] = useState('');

  const { uploadImage } = useUploads();

  const [isProductMadness, setIsProductMadness] = useState<boolean>(false);
  useEffect(() => {
    const bundleViewModel = storeTheme?.data?.general?.bundlesInternalViewModel;
    setIsProductMadness(
      [
        EBundlesInternalViewModel.JACKPOT_SLOTS,
        EBundlesInternalViewModel.BIG_FISH_CASINO
      ].includes(bundleViewModel)
    );
  }, [storeTheme.data?.general?.bundlesInternalViewModel]);

  const hasValues = useMemo(() => Object.keys(values).length > 0, [values]);

  useEffect(
    () => setIsLoading(storeTheme.isLoading || !storeTheme.data || !hasValues),
    [storeTheme?.isLoading, storeTheme?.data, hasValues]
  );

  if (isLoading) {
    return <ThemeLoader />;
  }

  return (
    <Grid container className="formContent">
      <Grid item xs={5}>
        {!pictures.isLoading && (
          <Stack>
            <AcCard stackContainer={false} title="Store Essentials">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcInput
                    header="Store Tab Title"
                    name="storeTabName"
                    value={values.storeTabName}
                    tooltip="This will be the title of your store tab."
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.storeTabName && Boolean(errors.storeTabName)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    onChange={(e) =>
                      uploadImage(
                        e,
                        addPictureInputRef.current as HTMLInputElement,
                        pictures,
                        setFieldValue,
                        setCurrentUploadingField,
                        currentUploadingField
                      )
                    }
                    ref={addPictureInputRef}
                    accept="image/*"
                  />
                  <AcSelect
                    header="Logo"
                    name="logo"
                    value={values.logo}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue('logo', uploadData!.data.url);
                      },
                      uploadType: EAssetType.LOGO,
                      uploadMessage: UploadsTooltips[EAssetType.LOGO]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge logo',
                        key: 'https://media.appcharge.com/defaults/logo.svg',
                        value: 'https://media.appcharge.com/defaults/logo.svg',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/logo.svg"
                                alt="Default Appcharge logo"
                                style={{
                                  maxWidth: 26
                                }}
                              />
                              <Typography>Default Appcharge logo</Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.LOGO)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 26,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.logo && Boolean(errors.logo)}
                    tooltip="This will be the logo of your store."
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Favicon"
                    name="favicon"
                    value={values.favicon}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue('favicon', uploadData!.data.url);
                      },
                      uploadType: EAssetType.FAVICON,
                      uploadMessage: UploadsTooltips[EAssetType.FAVICON]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge favicon',
                        key: 'https://media.appcharge.com/defaults/logo.svg',
                        value: 'https://media.appcharge.com/defaults/logo.svg',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/logo.svg"
                                alt="Default Appcharge favicon"
                                style={{
                                  maxWidth: 30
                                }}
                              />
                              <Typography>Default Appcharge logo</Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.FAVICON)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.favicon && Boolean(errors.favicon)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Background Image (Mobile)"
                    name="backgroundImageMobile"
                    value={values.backgroundImageMobile}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue(
                          'backgroundImageMobile',
                          uploadData!.data.url
                        );
                      },
                      uploadType: EAssetType.BG_MOBILE,
                      uploadMessage: UploadsTooltips[EAssetType.BG_MOBILE]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge background',
                        key: 'https://media.appcharge.com/defaults/background.png',
                        value:
                          'https://media.appcharge.com/defaults/background.png',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/background.png"
                                alt="Default Appcharge background"
                                style={{
                                  width: 30,
                                  maxHeight: 30
                                }}
                              />
                              <Typography>
                                Default Appcharge background
                              </Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.BG_MOBILE)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.backgroundImageMobile &&
                      Boolean(errors.backgroundImageMobile)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Background Image (Desktop)"
                    name="backgroundImageDesktop"
                    value={values.backgroundImageDesktop}
                    uploadConfig={{
                      onUploadSuccess: async (uploadData: any) => {
                        await pictures.refetch();
                        setFieldValue(
                          'backgroundImageDesktop',
                          uploadData!.data.url
                        );
                      },
                      uploadType: EAssetType.BG_DESKTOP,
                      uploadMessage: UploadsTooltips[EAssetType.BG_DESKTOP]
                    }}
                    items={[
                      {
                        content: 'Default Appcharge background',
                        key: 'https://media.appcharge.com/defaults/background.png',
                        value:
                          'https://media.appcharge.com/defaults/background.png',
                        renderFunction: () => {
                          return (
                            <Stack direction="row" alignItems="center" gap={1}>
                              <img
                                src="https://media.appcharge.com/defaults/background.png"
                                alt="Default Appcharge background"
                                style={{
                                  width: 30,
                                  maxHeight: 30
                                }}
                              />
                              <Typography>
                                Default Appcharge background
                              </Typography>
                            </Stack>
                          );
                        }
                      },
                      ...pictures.data
                        .filter((p: any) => p.type === EAssetType.BG_DESKTOP)
                        .map((picture: any) => {
                          return {
                            content: picture.name,
                            key: picture.url,
                            value: picture.url,
                            renderFunction: () => {
                              return (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <img
                                    src={picture.url}
                                    alt={picture.name}
                                    style={{
                                      width: 30,
                                      maxHeight: 30
                                    }}
                                  />
                                  <Typography>{picture.name}</Typography>
                                </Stack>
                              );
                            }
                          };
                        })
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.backgroundImageDesktop &&
                      Boolean(errors.backgroundImageDesktop)
                    }
                  />
                </Grid>
                {isProductMadness && (
                  <Grid item xs={12}>
                    <AcSelect
                      header="Store Sale"
                      name="storeSaleImage"
                      value={values?.storeSaleImage}
                      uploadConfig={{
                        onUploadSuccess: async (uploadData: any) => {
                          await pictures.refetch();
                          setFieldValue('storeSaleImage', uploadData!.data.url);
                        },
                        uploadType: EAssetType.GENERAL,
                        uploadMessage: UploadsTooltips[EAssetType.GENERAL]
                      }}
                      items={[
                        ...pictures.data
                          .filter((p: any) => p.type === EAssetType.GENERAL)
                          .map((picture: any) => {
                            return {
                              content: picture.name,
                              key: picture.url,
                              value: picture.url,
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src={picture.url}
                                      alt={picture.name}
                                      style={{
                                        width: 30,
                                        maxHeight: 30
                                      }}
                                    />
                                    <Typography>{picture.name}</Typography>
                                  </Stack>
                                );
                              }
                            };
                          })
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.storeSaleImage && Boolean(errors.storeSaleImage)
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </AcCard>
            <Divider />
            <AcCard stackContainer={false} title="Font">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcSelect
                    name="font"
                    value={values.font}
                    items={getFonts()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.font && Boolean(errors.font)}
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <FooterSection />
          </Stack>
        )}
      </Grid>
      <Grid item xs={7} className="iphone-mock-wrapper">
        <div
          className="store-preview-wrapper iphone-mock"
          style={{ overflow: 'scroll' }}
        >
          <Background
            backgroundImageMobile={values.backgroundImageMobile}
            backgroundImageDesktop={values.backgroundImageMobile}
            width="100%"
            height="100%"
            position="absolute"
          />
          <img
            style={{ position: 'relative' }}
            alt="logo"
            className={`storeLogo`}
            src={values.logo}
          />
        </div>
      </Grid>
    </Grid>
  );
};
