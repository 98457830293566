import {
  BabaSpecialOffer,
  BagelCodeSpecialOffer,
  BingoPopSpecialOffer,
  GhostSpecialOffer,
  HugeSpecialOffer,
  LocoBingoSpecialOffer,
  MatchMastersSpecialOffer,
  Me2onSpecialOffer,
  PipaSpecialOffer,
  PlaySimpleSpecialOffer,
  PokerFaceSpecialOffer,
  ProductMadnessSpecialOffer
} from '@appcharge/shared-ui';

import { ESpecialOfferInternalViewModel } from '../constants/enums';

export const mapSpecialOffers: Record<ESpecialOfferInternalViewModel, any> = {
  [ESpecialOfferInternalViewModel.HUGE]: HugeSpecialOffer,
  [ESpecialOfferInternalViewModel.MATCH_MASTERS]: MatchMastersSpecialOffer,
  [ESpecialOfferInternalViewModel.POKER_FACE]: PokerFaceSpecialOffer,
  [ESpecialOfferInternalViewModel.BAGELCODE]: BagelCodeSpecialOffer,
  [ESpecialOfferInternalViewModel.LOCO_BINGO]: LocoBingoSpecialOffer,
  [ESpecialOfferInternalViewModel.ME2ON]: Me2onSpecialOffer,
  [ESpecialOfferInternalViewModel.PIPA]: PipaSpecialOffer,
  [ESpecialOfferInternalViewModel.BABA]: BabaSpecialOffer,
  [ESpecialOfferInternalViewModel.JACKPOT_SLOTS]: ProductMadnessSpecialOffer,
  [ESpecialOfferInternalViewModel.BIG_FISH_CASINO]: ProductMadnessSpecialOffer,
  [ESpecialOfferInternalViewModel.PLAY_SIMPLE]: PlaySimpleSpecialOffer,
  [ESpecialOfferInternalViewModel.GHOST]: GhostSpecialOffer,
  [ESpecialOfferInternalViewModel.BINGO_POP]: BingoPopSpecialOffer
};

export const mapViewModelDisplayName: Record<
  ESpecialOfferInternalViewModel,
  string
> = {
  [ESpecialOfferInternalViewModel.HUGE]: 'Huge',
  [ESpecialOfferInternalViewModel.MATCH_MASTERS]: 'Match Masters',
  [ESpecialOfferInternalViewModel.POKER_FACE]: 'Poker Face',
  [ESpecialOfferInternalViewModel.BAGELCODE]: 'Bagelcode',
  [ESpecialOfferInternalViewModel.LOCO_BINGO]: 'Loco Bingo',
  [ESpecialOfferInternalViewModel.ME2ON]: 'Me2on',
  [ESpecialOfferInternalViewModel.PIPA]: 'Pipa',
  [ESpecialOfferInternalViewModel.BABA]: 'Baba',
  [ESpecialOfferInternalViewModel.JACKPOT_SLOTS]: 'Jackpot Slots',
  [ESpecialOfferInternalViewModel.BIG_FISH_CASINO]: 'Big Fish Casino',
  [ESpecialOfferInternalViewModel.PLAY_SIMPLE]: 'Play Simple',
  [ESpecialOfferInternalViewModel.GHOST]: 'Ghost Studio',
  [ESpecialOfferInternalViewModel.BINGO_POP]: 'Bingo Pop'
};
