import React from 'react';

import { useParams } from 'react-router-dom';

import { Grid, Stack, Typography } from '@mui/material';
import useImages from 'api/useImages';
import { EAssetType } from 'constants/enums';
import { useFormikContext } from 'formik';
import { UploadsTooltips } from 'hooks/useUpload';
import { permissionsUtil } from 'utils/permissionsUtil';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

import { GeneralThemeFormValues } from '../generalTheme.types';

import 'style/forms.scss';

const externalLinksItems = [
  { content: 'New tab', value: 'New tab' },
  { content: 'Modal', value: 'Modal' }
];

export const FooterSection: React.FC = () => {
  const { values, handleBlur, handleChange, setFieldValue, errors, touched } =
    useFormikContext<GeneralThemeFormValues>();
  const { publisherId } = useParams();
  const pictures = useImages(publisherId).getImages;

  return (
    <AcCard stackContainer={false} title="Footer">
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12}>
          <AcSelect
            header="Mobile Image"
            name="footer.imageMobile"
            value={values.footer.imageMobile}
            uploadConfig={{
              onUploadSuccess: async (uploadData: any) => {
                await pictures.refetch();
                setFieldValue('footer.imageMobile', uploadData!.data.url);
              },
              uploadType: EAssetType.LOGO,
              uploadMessage: UploadsTooltips[EAssetType.LOGO]
            }}
            items={[
              {
                content: 'No image',
                key: 'no-image',
                value: '',
                renderFunction: () => (
                  <Stack direction="row" alignItems="center" gap={1}>
                    <span
                      style={{
                        width: 30,
                        height: 30,
                        display: 'inline-block',
                        background: '#eee',
                        textAlign: 'center',
                        lineHeight: '30px'
                      }}
                    ></span>
                    <Typography>No Image</Typography>
                  </Stack>
                )
              },
              {
                content: 'Default Appcharge logo',
                key: 'https://media.appcharge.com/defaults/logo.svg',
                value: 'https://media.appcharge.com/defaults/logo.svg',
                renderFunction: () => {
                  return (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <img
                        src="https://media.appcharge.com/defaults/logo.svg"
                        alt="Default Appcharge logo"
                        style={{
                          maxWidth: 26
                        }}
                      />
                      <Typography>Default Appcharge logo</Typography>
                    </Stack>
                  );
                }
              },
              ...pictures.data
                .filter((p: any) => p.type === EAssetType.LOGO)
                .map((picture: any) => {
                  return {
                    content: picture.name,
                    key: picture.url,
                    value: picture.url,
                    renderFunction: () => {
                      return (
                        <Stack direction="row" alignItems="center" gap={1}>
                          <img
                            src={picture.url}
                            alt={picture.name}
                            style={{
                              width: 30,
                              maxHeight: 30
                            }}
                          />
                          <Typography>{picture.name}</Typography>
                        </Stack>
                      );
                    }
                  };
                })
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.footer?.imageMobile && Boolean(errors.footer?.imageMobile)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AcSelect
            header="Desktop Image"
            name="footer.imageDesktop"
            value={values.footer.imageDesktop}
            uploadConfig={{
              onUploadSuccess: async (uploadData: any) => {
                await pictures.refetch();
                setFieldValue('footer.imageDesktop', uploadData!.data.url);
              },
              uploadType: EAssetType.LOGO,
              uploadMessage: UploadsTooltips[EAssetType.LOGO]
            }}
            items={[
              {
                content: 'No image',
                key: 'no-image',
                value: '',
                renderFunction: () => (
                  <Stack direction="row" alignItems="center" gap={1}>
                    <span
                      style={{
                        width: 30,
                        height: 30,
                        display: 'inline-block',
                        background: '#eee',
                        textAlign: 'center',
                        lineHeight: '30px'
                      }}
                    ></span>
                    <Typography>No Image</Typography>
                  </Stack>
                )
              },
              {
                content: 'Default Appcharge logo',
                key: 'https://media.appcharge.com/defaults/logo.svg',
                value: 'https://media.appcharge.com/defaults/logo.svg',
                renderFunction: () => {
                  return (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <img
                        src="https://media.appcharge.com/defaults/logo.svg"
                        alt="Default Appcharge logo"
                        style={{
                          maxWidth: 26
                        }}
                      />
                      <Typography>Default Appcharge logo</Typography>
                    </Stack>
                  );
                }
              },
              ...pictures.data
                .filter((p: any) => p.type === EAssetType.LOGO)
                .map((picture: any) => {
                  return {
                    content: picture.name,
                    key: picture.url,
                    value: picture.url,
                    renderFunction: () => {
                      return (
                        <Stack direction="row" alignItems="center" gap={1}>
                          <img
                            src={picture.url}
                            alt={picture.name}
                            style={{
                              width: 30,
                              maxHeight: 30
                            }}
                          />
                          <Typography>{picture.name}</Typography>
                        </Stack>
                      );
                    }
                  };
                })
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.footer?.imageDesktop &&
              Boolean(errors.footer?.imageDesktop)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AcInput
            headerSize="14"
            header="Appstore Link"
            name="footer.appstoreLink"
            value={values.footer.appstoreLink}
            placeholder="https://www.apple.com/app-store/"
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.footer?.appstoreLink &&
              Boolean(errors.footer?.appstoreLink)
            }
            helperText={
              touched.footer?.appstoreLink
                ? errors.footer?.appstoreLink?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AcInput
            headerSize="14"
            header="GooglePlay Link"
            name="footer.googlePlayLink"
            value={values.footer.googlePlayLink}
            placeholder="https://play.google.com/"
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.footer?.googlePlayLink &&
              Boolean(errors.footer?.googlePlayLink)
            }
            helperText={
              touched.footer?.googlePlayLink
                ? errors.footer?.googlePlayLink?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AcInput
            headerSize="14"
            header="Privacy Policy"
            name="footer.privacyPolicyUrl"
            value={values.footer.privacyPolicyUrl}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.footer?.privacyPolicyUrl &&
              Boolean(errors.footer?.privacyPolicyUrl)
            }
            helperText={
              touched.footer?.privacyPolicyUrl
                ? errors.footer?.privacyPolicyUrl?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AcInput
            headerSize="14"
            header="Terms and Conditions"
            name="footer.termsAndConditionsUrl"
            value={values.footer.termsAndConditionsUrl}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.footer?.termsAndConditionsUrl &&
              Boolean(errors.footer?.termsAndConditionsUrl)
            }
            helperText={
              touched.footer?.termsAndConditionsUrl
                ? errors.footer?.termsAndConditionsUrl?.toString()
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AcInput
            headerSize="14"
            header="Support Link"
            name="footer.supportLink"
            value={values.footer.supportLink}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.footer?.supportLink && Boolean(errors.footer?.supportLink)
            }
            helperText={
              touched.footer?.supportLink
                ? errors.footer?.supportLink?.toString()
                : ''
            }
          />
        </Grid>
        {permissionsUtil.isSuperAdmin() && (
          <Grid item xs={12}>
            <AcSelect
              header="External links"
              name="footer.openLinksInNewTab"
              value={values.footer.openLinksInNewTab ? 'New tab' : 'Modal'}
              items={externalLinksItems}
              onChange={(e) => {
                setFieldValue(
                  'footer.openLinksInNewTab',
                  Boolean(e.target.value === 'New tab')
                );
              }}
              onBlur={handleBlur}
              error={
                touched.footer?.openLinksInNewTab &&
                Boolean(errors.footer?.openLinksInNewTab)
              }
            />
          </Grid>
        )}
      </Grid>
    </AcCard>
  );
};
