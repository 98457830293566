import { useQuery } from '@tanstack/react-query';

import { EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useSegments(currentPublisherId?: string) {
  const axios = useAxios();

  const getSegments = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.SEGMENTS],
    queryFn: async () => {
      return await axios.get(getApiRouteOrMock(EApiRoutes.SEGMENTS));
    },
    enabled: !!currentPublisherId
  });

  return {
    getSegments
  };
}
