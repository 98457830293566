import { useLocation } from 'react-router-dom';
import AcLink from 'components/AcLink/AcLink';
import { ESideBarGroup } from 'constants/enums';
import { SidebarMenuOptionProps } from './SidebarMenuOption.types';

import './style.scss';

const SidebarMenuOption = ({ data, testId }: SidebarMenuOptionProps) => {
  const location = useLocation();
  const rootSegments = data.route.split('/');
  const rootPath = rootSegments.length > 2 ? rootSegments.slice(0, 2).join('/') : data.route
  const locationSegments = location.pathname.split('/').slice(2);
  const isActive = locationSegments.join('/').startsWith(
      data.group === ESideBarGroup.OVERVIEW ? `${rootSegments[0]}` : rootPath
  );
  return (
    <>
      {data.disabled ? (
        <span>
          <li className={'sidebarItem disabled'}>
            {data.icon}
            <h3>{data.title}</h3>
          </li>
        </span>
      ) : data.route ? (
        <AcLink
          to={`./${data.route}${
            data.defaultQuery ? `?${data.defaultQuery}` : ''
          }`}
        >
          <li className={`sidebarItem ${isActive && 'active'}`} data-testid={testId}>
            <div>{data.icon}</div>
            <div>{data.title}</div>
          </li>
        </AcLink>
      ) : (
        <li
          className="sidebarItem"
          onClick={() => data.onClick && data.onClick()}
        >
          {data.icon}
          <h3>{data.title}</h3>
        </li>
      )}
    </>
  );
};

export default SidebarMenuOption;
