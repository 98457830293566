import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  Background,
  BundlesLayout,
  EBadgePosition,
  EBundlesViewModel
} from '@appcharge/shared-ui';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Stack
} from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import useBadges from 'api/useBadges';
import useImages from 'api/useImages';
import useOffers from 'api/useOffers';
import useOffersUI from 'api/useOffersUI';
import { useProduct } from 'api/useProduct';
import useSections from 'api/useSections';
import useSegments from 'api/useSegments';
import useTags from 'api/useTags';
import useTheme from 'api/useTheme';
import useUsers from 'api/useUsers';
import {
  Badge,
  BundleBadge,
  EBadgeType,
  Offer,
  OfferBadge,
  OfferUI,
  ProductsSequenceProduct,
  Section
} from 'common/contracts';
import {
  DEFAULT_BACKGROUND_URL,
  MIN_USD_PRICE,
  PRICE_VALUE_LIMIT,
  TRIMMED_INPUT_REGEX
} from 'constants/constants';
import {
  EAssetType,
  EBundlesInternalViewModel,
  EFeatureFlag,
  ENotificationType,
  EProductType,
  ESaleDiscountDisplayType,
  ESectionViewModel,
  OfferType
} from 'constants/enums';
import Decimal from 'decimal.js';
import { AutocompleteInput } from 'design-system/AutocompleteInput/AutocompleteInput';
import { useFormik } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { transformQuantityValue } from 'utils/formattingUtil';
import { permissionsUtil } from 'utils/permissionsUtil';
import { renderingUtil } from 'utils/renderingUtil';
import * as yup from 'yup';

import AcAutoComplete from 'components/AcAutoComplete/AcAutoComplete';
import AcCard from 'components/AcCard/AcCard';
import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from 'components/AcSelect/AcSelect.types';
import ActionButton from 'components/ActionButton/ActionButton';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';
import DataTable from 'components/DataTable/DataTable';
import DisplayTypeSelect from 'components/DisplayTypeSelect/DisplayTypeSelect';
import { Product } from 'components/ProductsForm/ProductsForm.types';
import { SectionsItem } from 'components/Theme/StoreTheme/storeTheme.types';
import PageTopBar from 'components/Topbar/PageTopBar';

import { SectionLayout } from './components/SectionLayout';

import 'style/forms.scss';

interface IOffersFormProps {
  edit?: boolean;
  dup?: boolean;
  isPlayerInfoSync?: boolean;
}

const mapViewModelToBadgeType: Record<EBundlesViewModel, EBadgeType> = {
  [EBundlesViewModel.LINEAR]: EBadgeType.EMBLEM,
  [EBundlesViewModel.THREE_IN_ONE]: EBadgeType.RIBBON,
  [EBundlesViewModel.SIX_IN_ONE]: EBadgeType.EMBLEM,
  [EBundlesViewModel.TWO_IN_ONE]: EBadgeType.RIBBON
};

export const OffersForm: React.FC<IOffersFormProps> = ({
  edit = false,
  dup = false,
  isPlayerInfoSync = false
}) => {
  const { publisherId } = useParams();
  const { fetchFeatureFlags } = useUsers({ publisherId });
  const { offerId } = useParams();
  const navigate = useNavigate();
  const { addOffer, updateOffer, getOffer, getOffers, formatProductQuantity } =
    useOffers(offerId);
  const [currentOfferUI, setCurrentOfferUI] = useState('');
  const { getOfferUI, getOffersUI } = useOffersUI(publisherId, currentOfferUI);
  const hasFeatureFlagTags =
    fetchFeatureFlags.data?.featureFlags?.[EFeatureFlag.DASHBOARD_TAGS];
  const { getStoreTheme } = useTheme(publisherId);
  const [data, setData] = useState<Partial<Offer>>({});
  const { enqueueSnackbar } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const { getImages } = useImages(publisherId);
  const { getSegments } = useSegments(publisherId);
  const { getTags } = useTags(publisherId, hasFeatureFlagTags);
  const { getBadges } = useBadges(publisherId);
  const [segmentError, setSegmentError] = useState('');
  const [badgeList, setBadgeList] = useState<BundleBadge[]>([]);
  const [isNameExists, setIsNameExists] = useState<boolean>(false);
  const [bundleModel, setBundleModel] = useState<EBundlesInternalViewModel>();
  const { getSections } = useSections(publisherId);
  const [section, setSection] = useState<Partial<SectionsItem>>(
    data?.section || {}
  );
  const [badges, setBadges] = useState(getOffer?.data?.result?.badges);
  const [emblemBadges, setEmblemBadges] = useState<object[]>([]);
  const [ribbonBadges, setRibbonBadges] = useState<object[]>([]);
  const [tagsError, setTagsError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [dirtyProducts, setDirtyProducts] = useState(false);

  const viewModel = getStoreTheme?.data?.general
    ?.bundlesViewModel as EBundlesViewModel;

  const bundlesAmount = useMemo(() => {
    if (viewModel === EBundlesViewModel.TWO_IN_ONE) return 2;
    if (viewModel === EBundlesViewModel.THREE_IN_ONE) return 3;
    if (viewModel === EBundlesViewModel.SIX_IN_ONE) return 6;
    return 1;
  }, [viewModel]);
  const sectionViewModel = getStoreTheme?.data?.general
    ?.sectionViewModel as ESectionViewModel;
  const checkUniqueness = (value: any, field: keyof Offer) => {
    const isTaken = getOffers.data?.offers.find((o: any) => {
      if (!edit) return o[field] === value;
      return o[field] === value && data[field] !== value;
    });
    return !isTaken;
  };
  const emptySection = { content: 'None', key: '0', value: '0' };

  let offerSchema = yup.object().shape({
    name: yup
      .string()
      .matches(TRIMMED_INPUT_REGEX, 'Name should not be spaces')
      .min(3, `Name length should be longer`)
      .required('Name is required')
      .test('uniqueness', 'Name already exists', (value) => {
        return checkUniqueness(value, 'name');
      }),
    description: yup
      .string()
      .nullable()
      .test(
        'is-empty-or-min-length',
        'Description length should be longer',
        (value) => !value || value.length >= 3
      ),
    offerUiId: yup.string().required('Offer Design is required'),
    publisherOfferId: yup
      .string()
      .matches(TRIMMED_INPUT_REGEX, 'SKU should not be spaces')
      .required('SKU is required')
      .test('uniqueness', 'SKU already exists', (value) => {
        return checkUniqueness(value, 'publisherOfferId');
      }),
    price: yup
      .number()
      .min(0.8, 'Price must be at least 0.8')
      .max(5000, 'Price must be at most 5000')
      .required('Price is required')
      .test('maxDigits', 'Price must have at most 10 digits', (value) =>
        value ? value.toString().length <= PRICE_VALUE_LIMIT : true
      ),
    discount: yup
      .number()
      .min(0, 'Price discount cannot be negative')
      .max(99, 'Price discount cannot be more than 99%')
      .test(
        'is-one-decimal',
        'Price discount can have one decimal place at most',
        (value) =>
          value === undefined || /^\d+(\.\d{1})?$/.test(value.toString())
      ),
    salePercentage: yup
      .number()
      .min(0, 'Sale amount cannot be negative')
      .test(
        'is-one-decimal',
        'Sale amount can have one decimal place at most',
        (value) =>
          value === undefined || /^\d+(\.\d{1})?$/.test(value.toString())
      ),
    segments: yup.array().of(yup.string()),
    tags: yup.array().of(yup.string())
  });

  useEffect(() => {
    if (currentOfferUI && publisherId) {
      getOfferUI.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOfferUI, publisherId]);

  useEffect(() => {
    if (data.section) {
      setSection(data.section);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [edit, data]);

  const relevantBadgeType: EBadgeType | 'both' = useMemo(() => {
    if (bundleModel === EBundlesInternalViewModel.HEART_OF_VEGAS) {
      return 'both';
    }
    if (
      bundleModel === EBundlesInternalViewModel.LIGHTNING_LINK_CASINO ||
      bundleModel === EBundlesInternalViewModel.GHOST
    )
      return EBadgeType.EMBLEM;
    if (bundleModel === EBundlesInternalViewModel.PLAY_SIMPLE)
      return EBadgeType.RIBBON;

    if (viewModel) {
      return mapViewModelToBadgeType[viewModel as EBundlesViewModel];
    }

    return EBadgeType.RIBBON;
  }, [viewModel, bundleModel]);

  const filterRelevantBadges = (badgeType: EBadgeType) => {
    if (
      relevantBadgeType === 'both' ||
      ((viewModel === EBundlesViewModel.LINEAR ||
        viewModel === EBundlesViewModel.TWO_IN_ONE ||
        viewModel === EBundlesViewModel.THREE_IN_ONE ||
        viewModel === EBundlesViewModel.SIX_IN_ONE) &&
        badgeType === relevantBadgeType)
    ) {
      return true;
    }
    return false;
  };

  const [products, setProducts] = useState<
    {
      _id: string;
      productId: string;
      image: string;
      imagePrefix: string;
      name: string;
      amount: number | string;
      type: string;
      textFontColorHex: string;
      prefix: string;
      suffix: string;
      publisherProductId?: string;
      quantityDisplay: string;
      priority?: string;
    }[]
  >([]);
  const [currentProduct, setCurrentProduct] =
    useState<ProductsSequenceProduct | null>(null);
  const { data: productsData, isLoading: isLoadingProducts } =
    useProduct(publisherId).getProducts;

  const productsList: Product[] = productsData?.result;

  useEffect(() => {
    if (!!offerId && !getOffer.isLoading && getOffer.data) {
      setData(getOffer.data.result);
      setCurrentOfferUI(
        getOffer.data.result.offerUi?._id ||
          getOffer.data.result.offerUi?.offerUiId ||
          ''
      );
      setProducts(
        getOffer.data.result.productsSequence[0].products.map(
          (
            p: {
              product: Partial<Product>;
              quantity: number;
              quantityDisplay: string;
            },
            index: number
          ) => {
            return {
              _id: p.product?.productId || index,
              productId: p.product?.productId,
              image: p.product?.images?.[0].url,
              imagePrefix: p.product?.images?.find(
                (i) => i.type === EAssetType.PRODUCT_PREFIX
              )?.url,
              name: p.product?.name,
              amount: p?.quantity,
              type: p.product?.type,
              textFontColorHex: p.product?.textFontColorHex,
              prefix: p.product?.prefix,
              suffix: p.product?.suffix,
              publisherProductId: p.product?.publisherProductId,
              quantityDisplay: p.quantityDisplay || '',
              priority: p.product?.priority
            };
          }
        )
      );
    }
  }, [getOffer.data, getOffer.isLoading, offerId]);

  useEffect(() => {
    if (offerId && publisherId) {
      getOffer.refetch();
    }
  }, [offerId, publisherId]);

  useEffect(() => {
    const newSection = getSections.data?.result?.find(
      (section: Partial<SectionsItem>) =>
        section.sectionId === getOffer.data?.result?.sectionId?.sectionId ||
        getOffer.data?.result?.section?.sectionId
    );
    setSection(newSection ?? {});
  }, [getOffer.data, getSections.data]);

  const {
    values,
    handleChange,
    handleBlur,
    submitForm,
    dirty,
    isValid,
    errors,
    touched,
    setFieldValue,
    setTouched,
    validateField
  } = useFormik({
    validateOnMount: true,
    validationSchema: offerSchema,
    enableReinitialize: true,
    initialValues: {
      publisherOfferId: data.publisherOfferId || '',
      price: data.productsSequence?.[0]?.priceInUsdCents
        ? new Decimal(data.productsSequence?.[0]?.priceInUsdCents)
            .div(100)
            .toNumber()
        : MIN_USD_PRICE,
      name: data.name ? `${data.name}${dup ? '_copy' : ''}` : '',
      displayName: data.displayName
        ? `${data.displayName}${dup ? '_copy' : ''}`
        : data.name
          ? `${data.name}${dup ? '_copy' : ''}`
          : '',
      ...(data.description && { description: data.description }),
      type: data.type || '',
      offerUiId: data.offerUi?._id || data.offerUi?.offerUiId || '',
      segments: data.segments || [],
      tags: data.tags || [],
      quantity: '',
      productsSequence: data.productsSequence || [{ products: [] }],
      salePercentage: data?.productSale?.sale || 0,
      salePercentageDisplayType:
        data.productSale?.type || ESaleDiscountDisplayType.PERCENTAGE,
      discount: data?.priceDiscount?.discount || 0,
      priceDiscountDisplayType:
        data.priceDiscount?.type || ESaleDiscountDisplayType.PERCENTAGE,
      active: edit ? data?.active : true,
      badge:
        data?.badges?.find((b) => {
          return filterRelevantBadges(b.badge!.type);
        })?.badge?.badgeId || null,
      publisherBadgeId: data?.badges?.find((b) => {
        return filterRelevantBadges(b.badge!.type);
      })?.badge?.publisherBadgeId,
      section: data?.section?.sectionId || data?.sectionId?.sectionId || '0',
      backgroundImage: data?.offerUi?.backgroundImage || ''
    },
    onSubmit: async (values) => {
      const offerBadges: OfferBadge[] = [];
      if (values.badge) {
        const publisherBadgeId =
          values?.publisherBadgeId || badges[0].badge.publisherBadgeId;
        const badge: OfferBadge = {
          badgeId: values.badge,
          publisherBadgeId: publisherBadgeId
        };
        offerBadges.push(badge);
      }

      const newOffer: Partial<Offer> & { sectionId?: string | null } = {
        publisherOfferId: values.publisherOfferId,
        name: values.name,
        displayName: values.displayName || values.name,
        ...(values.description && { description: values.description }),
        type: OfferType.BUNDLE,
        active: values?.active,
        offerUiId: values.offerUiId,
        segments: values.segments,
        tags: values.tags,
        badges: offerBadges,
        productSale: {
          sale: values.salePercentage,
          type:
            values.salePercentageDisplayType ||
            ESaleDiscountDisplayType.PERCENTAGE
        },
        priceDiscount: {
          discount: values.discount,
          type:
            values.priceDiscountDisplayType ||
            ESaleDiscountDisplayType.PERCENTAGE
        },
        productsSequence: [
          {
            index: 1,
            products: products.map((p) => ({
              productId: p.productId,
              quantity: p.amount,
              publisherProductId: p.publisherProductId
            })),
            priceInUsdCents: new Decimal(values.price).mul(100).toNumber()
            // playerAvailability: 1
          }
        ],
        sectionId:
          values.section && values.section !== '0' ? values.section : null
      };
      if (edit && offerId && !dup) {
        updateOffer.mutate(
          { offerId, form: newOffer },
          {
            onSuccess: () => {
              enqueueSnackbar(
                'Offer edited successfully',
                ENotificationType.SUCCESS
              );
              navigate('../');
            },
            onError: () => {
              enqueueSnackbar('Error editing offer', ENotificationType.ERROR);
            }
          }
        );
      } else {
        addOffer.mutate(newOffer, {
          onSuccess: () => {
            enqueueSnackbar(
              'New Offer added successfully',
              ENotificationType.SUCCESS
            );
            navigate('../');
          },
          onError: () => {
            enqueueSnackbar('Error adding Offer', ENotificationType.ERROR);
          }
        });
      }
    }
  });

  useEffect(() => {
    if (values.badge !== undefined) {
      const currentBadge = getBadges?.data?.result?.find(
        (b: any) => b.badgeId === values.badge
      );
      setBadges([{ badge: currentBadge }]);
      if (currentBadge?.emblem) {
        setEmblemBadges([currentBadge.emblem]);
      }
      if (currentBadge?.ribbon) {
        setRibbonBadges([currentBadge.emblem]);
      }
    } else {
      const badges = getOffer?.data?.result?.badges;
      if (badges && badges?.length > 0) {
        setBadges(badges);
      }
    }
  }, [values?.badge]);

  const removeProduct = (productId: string) => {
    const newProducts = products.filter((p) => p._id !== productId);
    setDirtyProducts(true);
    setProducts(newProducts);
  };

  const editProduct = (productId: string) => {
    setDirtyProducts(true);
    return products.forEach((p) => {
      if (p._id === productId) {
        removeProduct(productId);
        setCurrentProduct({
          product: p.image,
          productId: p._id,
          quantity: p.amount,
          publisherProductId: p.publisherProductId
        });
      }
    });
  };

  const addProduct = async () => {
    const foundProduct = productsData!.result.find(
      (product: { productId: string | undefined }) =>
        product.productId === currentProduct?.productId
    );

    const formattedProductQuantity = await formatProductQuantity.mutateAsync({
      quantity: currentProduct!.quantity.toString(),
      productType: foundProduct.type
    });

    const newProduct = {
      _id: currentProduct!.productId!,
      productId: currentProduct!.productId!,
      image: foundProduct!.images[0].url,
      imagePrefix: foundProduct.images?.find((i: { type: EAssetType }) => {
        return i.type === EAssetType.PRODUCT_PREFIX;
      })?.url,
      name: foundProduct!.name,
      amount: currentProduct!.quantity,
      type: foundProduct!.type,
      textFontColorHex: foundProduct!.textFontColorHex,
      prefix: foundProduct!.prefix,
      suffix: foundProduct!.suffix,
      quantityDisplay: formattedProductQuantity.data,
      priority: foundProduct?.priority
    };

    if (currentProduct?.publisherProductId) {
      (newProduct as any).publisherProductId =
        currentProduct.publisherProductId;
    }

    setProducts([...products, newProduct]);

    if ((newProduct as any)?.publisherProductId) {
      setFieldValue('productsSequence[0].products', [
        ...values.productsSequence[0].products,
        {
          productId: newProduct.productId,
          quantity: newProduct.amount,
          publisherProductId: (newProduct as any).publisherProductId
        }
      ]);
      handleChange({
        target: {
          name: 'quantity',
          value: String(newProduct.amount)
        }
      });
    } else {
      setFieldValue('productsSequence[0].products', [
        ...values.productsSequence[0].products,
        {
          productId: newProduct.productId,
          quantity: newProduct.amount
        }
      ]);
    }

    setCurrentProduct({
      productId: '',
      quantity: 0,
      publisherProductId: ''
    });
  };

  useEffect(() => {
    if (!getBadges.data?.result) return;
    const badgesDropdownItems = getBadges.data?.result
      .map((badge: Badge) => {
        if (filterRelevantBadges(badge.type))
          return {
            content: badge.name,
            name: badge.name,
            key: badge.badgeId,
            value: badge.badgeId,
            url: badge.emblem?.imageUrl,
            position: badge.position as EBadgePosition
          };
      })
      .filter((b: any) => b !== undefined);
    setBadgeList(badgesDropdownItems);
  }, [getBadges.data, viewModel, bundleModel]);

  // removes segments
  const removeSegment = (segment: string) => {
    const filteredSegments = values.segments.filter((s) => s !== segment);
    setFieldValue('segments', filteredSegments);
  };

  const removeTag = (tag: string) => {
    const filteredTags = values.tags.filter((s) => s !== tag);
    setFieldValue('tags', filteredTags);
  };

  useEffect(() => {
    setIsNameExists(
      getOffers.data?.offers.find((o: any) => {
        return o.name === values.name;
      }) && !edit
    );
  }, [values.name]);

  useEffect(() => {
    if (!getStoreTheme.data?.general?.bundlesInternalViewModel) return;
    setBundleModel(getStoreTheme.data.general.bundlesInternalViewModel);
  }, [getStoreTheme.data]);

  const handleQuantityChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newOffersQuantity =
      e.target.value.length !== 0
        ? transformQuantityValue(e.target.value)
        : e.target.value;
    setTouched({ ...touched, quantity: true });
    setCurrentProduct({
      productId: currentProduct?.productId || '',
      quantity: newOffersQuantity,
      publisherProductId: currentProduct?.publisherProductId
    });
    validateField('quantity');
  };

  const transformPriceValue = (): number => {
    if (!values.price) return MIN_USD_PRICE;
    return String(values.price).length > PRICE_VALUE_LIMIT
      ? parseFloat(String(values.price).slice(0, PRICE_VALUE_LIMIT))
      : values.price;
  };

  const getSectionItems = () => {
    let sectionsData = [emptySection];
    if (getSections?.data?.result.length > 0) {
      const parsedSectionsData = getSections.data.result.map(
        (section: Section) => ({
          content: section.name,
          key: section.sectionId,
          value: section.sectionId
        })
      );
      return sectionsData.concat(parsedSectionsData);
    }
    return sectionsData;
  };

  const handleSectionChange = (e: SelectChangeEvent<unknown>) => {
    const newSection = getSections.data.result.find(
      (section: Partial<SectionsItem>) => section.sectionId === e.target.value
    );
    setSection(newSection ?? {});
    handleChange(e);
  };

  const mockBundleBackgroundColor = useMemo(
    () => getOfferUI.data?.specialOffer?.backgroundColor,
    [getOfferUI.data]
  );

  const getProductsOptions = useMemo(() => {
    if (isLoadingProducts || !productsList) return [];
    const productIds = new Set(products.map((item) => item.productId));
    const filteredProducts = productsList.filter(
      (product: Product) => !productIds.has(product.productId as string)
    );
    if (filteredProducts.length === 0) return [];
    return filteredProducts.map((product) => ({
      url: product.images?.[0]?.url,
      name: product.name,
      key: product.name,
      value: product.productId
    }));
  }, [productsList, products, isLoadingProducts]);

  const selectedProduct =
    getProductsOptions.find(
      (product) => product.value === currentProduct?.productId
    ) || null;

  return (
    <AcViewWrapper
      header={
        <PageTopBar
          disable={false}
          headline={`${edit ? 'Edit' : 'New'} Bundle ${
            edit
              ? `(${
                  !getOffer.isLoading && getOffer.data?.result
                    ? getOffer.data?.result?.name
                    : '...'
                })`
              : ''
          }`}
          buttons={[
            {
              text: 'Save',
              action: () => submitForm(),
              disabled:
                isLoading ||
                !isValid ||
                !permissionsUtil.canUserEdit() ||
                (edit && !dirty && !dirtyProducts)
            }
          ]}
          backFunction={() => navigate(-1)}
        />
      }
    >
      {!getImages.isLoading && getImages.data && (
        <AcContentWrapper className="formContent">
          <Grid container pt={'24px'}>
            <Grid item xs={5}>
              <Stack>
                <AcCard stackContainer={false} title="General">
                  <Grid container rowSpacing={2} columnSpacing={1.5}>
                    <Grid item xs={6}>
                      <AcInput
                        header="Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={
                          (touched.name && Boolean(errors.name)) || isNameExists
                        }
                        helperText={
                          (touched.name ? errors.name?.toString() : '') ||
                          (isNameExists ? 'Name already exists' : '')
                        }
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <AcInput
                        header="Display Name"
                        name="displayName"
                        variant="outlined"
                        value={values.displayName}
                        onChange={(data) => {
                          handleChange(data);
                        }}
                        onBlur={handleBlur}
                        error={
                          touched.displayName && Boolean(errors.displayName)
                        }
                        tooltip={'Add your display name for this offer'}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <AcInput
                        header="Description"
                        name="description"
                        variant="outlined"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.description && Boolean(errors.description)
                        }
                        tooltip={'Add your description for this offer'}
                        helperText={
                          touched.description
                            ? errors.description?.toString()
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcInput
                        header="SKU"
                        name="publisherOfferId"
                        value={values.publisherOfferId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        error={
                          Boolean(errors.publisherOfferId) &&
                          (touched.publisherOfferId || dup)
                        }
                        helperText={
                          touched.publisherOfferId || dup
                            ? errors.publisherOfferId?.toString()
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcSelect
                        header="Offer Design"
                        name="offerUiId"
                        value={values.offerUiId}
                        required={true}
                        onChange={(e) => {
                          setCurrentOfferUI(e.target.value as string);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        items={getOffersUI?.data?.result
                          .filter((oi: OfferUI) => oi.offerUiType === 'Bundle')
                          .map((offerUI: OfferUI) => {
                            return {
                              content: offerUI.name,
                              key: offerUI._id,
                              value: offerUI._id
                            };
                          })}
                        error={touched.offerUiId && Boolean(errors.offerUiId)}
                        helperText={
                          touched.offerUiId ? errors.offerUiId?.toString() : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcSelect
                        header="Section"
                        name="section"
                        value={values.section}
                        required={false}
                        onChange={(e) => handleSectionChange(e)}
                        onBlur={handleBlur}
                        items={getSectionItems()}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcInput
                        header="Price"
                        name="price"
                        value={values.price}
                        onChange={(e) => {
                          setTouched({
                            ...touched,
                            price: true
                          });
                          handleChange(e);
                          validateField('price');
                        }}
                        onBlur={handleBlur}
                        type="number"
                        required={true}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            )
                          },
                          htmlInput: {
                            min: MIN_USD_PRICE
                          }
                        }}
                        error={touched.price && Boolean(errors.price)}
                        helperText={
                          touched.price ? errors.price?.toString() : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </AcCard>
                <Divider />
                <AcCard stackContainer={false} title="Segments">
                  <Grid container columnSpacing={1.5}>
                    <Grid xs={12} item>
                      <AcAutoComplete
                        ref={inputRef}
                        value={inputValue}
                        startAdornment={
                          <InputAdornment position="start">
                            <GridSearchIcon />
                          </InputAdornment>
                        }
                        helperText="Press ‘Enter’ to add a new segment"
                        onEnter={(val) => {
                          setFieldValue('segments', [...values.segments, val]);
                          setInputValue('');
                          inputRef?.current?.blur();
                        }}
                        onChangeEvent={(val) =>
                          setSegmentError(
                            values.segments.includes(val)
                              ? "You can't add the same segment twice"
                              : ''
                          )
                        }
                        options={
                          (!getSegments?.isLoading &&
                            getSegments?.data.filter(
                              (s: string) => !values.segments.includes(s)
                            )) ||
                          []
                        }
                        additionalOptions={values.segments}
                        onChange={(e) => {
                          const selected = (e.target as HTMLElement)
                            .textContent;
                          selected &&
                            setFieldValue('segments', [
                              ...values.segments,
                              selected
                            ]);
                        }}
                        onBlur={handleBlur}
                        error={segmentError}
                      />
                      <Stack></Stack>
                      {!!values.segments.length && (
                        <Stack
                          marginTop={1.5}
                          gap={1}
                          direction="row"
                          flexWrap="wrap"
                        >
                          {values.segments.map((val) => (
                            <Chip
                              className="segment-chip"
                              key={val}
                              onClick={() => removeSegment(val)}
                              label={
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  gap={0.5}
                                >
                                  <span>{val}</span>
                                  <CloseIcon
                                    sx={{
                                      width: 14,
                                      height: 14
                                    }}
                                  />
                                </Stack>
                              }
                            />
                          ))}
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                </AcCard>
                <Divider />
                {relevantBadgeType === EBadgeType.RIBBON && (
                  <>
                    <AcCard stackContainer={false} title="Badges">
                      <Grid container columnSpacing={1.5}>
                        <Grid item xs={12}>
                          <AcSelect
                            header="Ribbon"
                            name="badge"
                            value={values.badge}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            renderType={EAcSelectItemRenderType.TEXT}
                            items={badgeList}
                            onClear={() => {
                              setFieldValue('badge', null);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </AcCard>
                    <Divider />
                  </>
                )}
                {relevantBadgeType === EBadgeType.EMBLEM && (
                  <>
                    <AcCard stackContainer={false} title="Badges">
                      <Grid container columnSpacing={1.5}>
                        <Grid item xs={12}>
                          <AcSelect
                            header="Emblem"
                            name="badge"
                            value={values.badge}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            renderType={EAcSelectItemRenderType.TEXT}
                            items={badgeList}
                            onClear={() => {
                              setFieldValue('badge', null);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </AcCard>
                    <Divider />
                  </>
                )}
                {relevantBadgeType === 'both' && (
                  <>
                    <AcCard stackContainer={false} title="Badges">
                      <Grid container columnSpacing={1.5}>
                        <Grid item xs={12}>
                          <AcSelect
                            name="badge"
                            value={values.badge}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            renderType={EAcSelectItemRenderType.TEXT}
                            items={badgeList}
                            onClear={() => {
                              setFieldValue('badge', null);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </AcCard>
                    <Divider />
                  </>
                )}
                <AcCard stackContainer={false} title="Sale & Discounts">
                  <Grid container columnSpacing={1.5} mb={2}>
                    <Grid item xs={6}>
                      <AcInput
                        header="Product Sale"
                        name="salePercentage"
                        value={values.salePercentage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        slotProps={{
                          input: {
                            startAdornment:
                              values?.salePercentageDisplayType ===
                              ESaleDiscountDisplayType.PERCENTAGE ? (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ) : undefined
                          },
                          htmlInput: {
                            min: 0,
                            step: 0.1
                          }
                        }}
                        error={
                          touched.salePercentage &&
                          Boolean(errors.salePercentage)
                        }
                        helperText={
                          touched.salePercentage
                            ? errors.salePercentage?.toString()
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DisplayTypeSelect
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.salePercentageDisplayType}
                      />
                    </Grid>
                  </Grid>
                  {values.price > 0 && (
                    <Grid container columnSpacing={1.5}>
                      <Grid item xs={6}>
                        <AcInput
                          header="Price Discount"
                          name="discount"
                          value={values.discount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="number"
                          slotProps={{
                            htmlInput: {
                              min: 0,
                              step: 0.1
                            },
                            input: {
                              startAdornment:
                                values?.priceDiscountDisplayType ===
                                ESaleDiscountDisplayType.PERCENTAGE ? (
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                ) : undefined
                            }
                          }}
                          error={touched.discount && Boolean(errors.discount)}
                          helperText={
                            touched.discount ? errors.discount?.toString() : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DisplayTypeSelect
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.priceDiscountDisplayType}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AcCard>
                <Divider />
                <AcCard stackContainer={false} title="Products">
                  <Grid container rowSpacing={1.5} columnSpacing={1.5}>
                    <Grid item xs={6}>
                      <AutocompleteInput
                        label="Name"
                        options={getProductsOptions}
                        onChange={(newValue: any) => {
                          setCurrentProduct({
                            ...currentProduct,
                            productId: newValue?.value || '',
                            quantity: currentProduct?.quantity || 0
                          });
                        }}
                        currentOption={selectedProduct}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AcInput
                        header="Quantity"
                        name="quantity"
                        value={String(currentProduct?.quantity)}
                        type="number"
                        slotProps={{
                          htmlInput: {
                            min: 0
                          }
                        }}
                        onChange={(e) => handleQuantityChange(e)}
                        error={touched.quantity && Boolean(errors.quantity)}
                        helperText={
                          touched.quantity ? errors.quantity?.toString() : ''
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ActionButton
                        text={'Add Product'}
                        variant="outlined"
                        disabled={
                          !currentProduct?.productId ||
                          !currentProduct?.quantity
                        }
                        onClick={addProduct}
                      />
                    </Grid>

                    <Grid item xs={12} mt={-3}>
                      <DataTable
                        columns={[
                          {
                            field: 'image',
                            headerName: '',
                            flex: 1,
                            renderCell: (params: any) => {
                              return (
                                <img
                                  style={{
                                    maxHeight: '50%',
                                    objectFit: 'contain'
                                  }}
                                  src={params.row.image}
                                  alt="productImage"
                                />
                              );
                            }
                          },
                          {
                            field: 'name',
                            headerName: 'Name',
                            flex: 1
                          },
                          {
                            field: 'amount',
                            headerName: 'Amount',
                            flex: 1
                          },
                          {
                            field: 'type',
                            headerName: 'Type',
                            flex: 1
                          },
                          {
                            field: 'remove',
                            headerName: 'Remove',
                            renderCell: (params) => (
                              <IconButton
                                onClick={() => removeProduct(params.row._id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ),
                            flex: 1
                          },
                          {
                            field: 'edit',
                            headerName: 'Edit',
                            renderCell: (params) => (
                              <IconButton
                                onClick={() => editProduct(params.row._id)}
                              >
                                <EditIcon />
                              </IconButton>
                            ),
                            flex: 1
                          }
                        ]}
                        rows={products}
                        loading={false}
                      />
                    </Grid>
                  </Grid>
                </AcCard>
                <Divider />
                {hasFeatureFlagTags && (
                  <AcCard stackContainer={false} title="Tags">
                    <Grid container columnSpacing={1.5}>
                      <Grid xs={12} item>
                        <AcAutoComplete
                          ref={inputRef}
                          value={inputValue}
                          startAdornment={
                            <InputAdornment position="start">
                              <GridSearchIcon />
                            </InputAdornment>
                          }
                          helperText="Press ‘Enter’ to add a new tag"
                          onEnter={(val) => {
                            setFieldValue('tags', [...values.tags, val]);
                            setInputValue('');
                            inputRef?.current?.blur();
                          }}
                          onChangeEvent={(val) =>
                            setTagsError(
                              values.tags.includes(val)
                                ? "You can't add the same tag twice"
                                : ''
                            )
                          }
                          onBlur={handleBlur}
                          options={
                            (!getTags?.isLoading &&
                              getTags?.data.filter(
                                (s: string) => !values.tags.includes(s)
                              )) ||
                            []
                          }
                          additionalOptions={values.tags}
                          onChange={(e) => {
                            const selected = (e.target as HTMLElement)
                              .textContent;
                            selected &&
                              setFieldValue('tags', [...values.tags, selected]);
                          }}
                          error={tagsError}
                        />
                        {!!values.tags.length && (
                          <Stack
                            marginTop={1.5}
                            gap={1}
                            direction="row"
                            flexWrap="wrap"
                          >
                            {values.tags.map((val) => (
                              <Chip
                                className="tags-chip"
                                key={val}
                                onClick={() => removeTag(val)}
                                label={
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    gap={0.5}
                                  >
                                    <span>{val}</span>
                                    <CloseIcon
                                      sx={{
                                        width: 14,
                                        height: 14
                                      }}
                                    />
                                  </Stack>
                                }
                              />
                            ))}
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </AcCard>
                )}
              </Stack>
            </Grid>

            <Grid item xs={7} className="iphone-mock-wrapper">
              <div className="iphone-mock">
                <Background
                  backgroundImageMobile={
                    !getStoreTheme.isLoading &&
                    getStoreTheme.data.general?.backgroundImageMobile
                  }
                  backgroundImageDesktop={
                    !getStoreTheme.isLoading &&
                    getStoreTheme.data.general?.backgroundImageDesktop
                  }
                  width="100%"
                  height="100%"
                  position="absolute"
                />
                <SectionLayout
                  sectionViewModel={sectionViewModel}
                  section={section}
                />
                <BundlesLayout bundlesViewModel={viewModel} isPreview={true}>
                  <div
                    className="bundles-wrapper"
                    style={{
                      minHeight: '200px',
                      justifyContent: 'space-between'
                    }}
                  >
                    {!getStoreTheme.isLoading &&
                      Array.from(
                        {
                          length: bundlesAmount
                        },
                        () => null
                      ).map((_, index) =>
                        renderingUtil.renderBundleByInternalModel(
                          getStoreTheme.data.general.bundlesInternalViewModel,
                          {
                            index: index,
                            badges: badges,
                            ribbonBadges: ribbonBadges,
                            emblemBadges: emblemBadges,
                            backgroundSrc: getOfferUI.data?.backgroundImage
                              ? getOfferUI.data?.backgroundImage
                              : mockBundleBackgroundColor
                                ? ''
                                : values?.backgroundImage ||
                                  DEFAULT_BACKGROUND_URL,
                            backgroundColor: mockBundleBackgroundColor || {
                              colorOne: '#332566'
                            },
                            bundleBorderColor:
                              getStoreTheme.data.storeScreen.bundleBorderColor,
                            productList:
                              products.length > 0
                                ? products.map((p) => {
                                    return {
                                      quantityDisplay:
                                        p?.quantityDisplay || p?.amount,
                                      images: {
                                        product: p?.image,
                                        productPrefix: p?.imagePrefix
                                      },
                                      textFontColorHex: p?.textFontColorHex,
                                      type: 't' as EProductType,
                                      priority: p?.priority,
                                      prefix: p?.prefix,
                                      suffix: p?.suffix
                                    };
                                  })
                                : [],
                            bundleButtonColor:
                              getStoreTheme.data.general.buttonColor,
                            bundleButtonTextColor:
                              getStoreTheme.data.general.buttonTextColor,
                            fontFamily: getStoreTheme.data.general.font,
                            prices: {
                              priceDisplay: `$${transformPriceValue()}`,
                              priceInUsdCents: values?.price
                            },
                            salePercentage: values?.salePercentage,
                            salePercentageDisplayType:
                              values?.salePercentageDisplayType,
                            gameName:
                              getStoreTheme.data.general
                                .bundlesInternalViewModel,
                            inMobileMock: true
                          }
                        )
                      )}
                  </div>
                </BundlesLayout>
              </div>
            </Grid>
          </Grid>
        </AcContentWrapper>
      )}
    </AcViewWrapper>
  );
};
