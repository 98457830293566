import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useTags(
  currentPublisherId?: string,
  hasFeatureFlagTags?: boolean
) {
  const axios = useAxios();
  const [isOfferTagsEnabled, setOfferTagsEnabled] = useState(false);

  useEffect(() => {
    if (hasFeatureFlagTags) {
      setOfferTagsEnabled(true);
    }
  }, [hasFeatureFlagTags]);

  const getTags = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.PUBLISHER_TAGS],
    queryFn: async () => {
      return await axios.get(getApiRouteOrMock(EApiRoutes.PUBLISHER_TAGS));
    },
    enabled:
      isOfferTagsEnabled &&
      !!currentPublisherId
  });

  return {
    getTags
  };
}
