import useProfiles from 'api/useProfiles';
import { useParams } from 'react-router-dom';
import { useNotifications } from './useNotifications';
import { localStorageUtil } from 'utils/localStorageUtil';
import { ELambdaProfilesEndpoints, ELocalStorageKeys, ENotificationType } from 'constants/enums';
import { useEffect, useState } from 'react';
import useIntegration from 'api/useIntegration';
import { ProfileData } from 'components/Settings/Settings.types';
import useUsers from 'api/useUsers';


export const useProfilesManager = () => {
    const { publisherId } = useParams();
    const { createProfile, deleteProfile, getProfiles } = useProfiles(publisherId as string);
    const { enqueueSnackbar } = useNotifications();
    const { updateIntegration }: any = useIntegration(publisherId);
    const [isProfilesEnabled, setIsProfilesEnabled] = useState<boolean>(false);
    const [defaultProfile, setDefaultProfile] = useState<ProfileData>();
    const [isProfilesFeatureFlagOn, setIsProfilesFeatureFlagOn] = useState<boolean>(false);
    const { fetchFeatureFlags } = useUsers({ enableFeatureFlags: false });
    const [isToggleInProgress, setIsToggleInProgress] = useState<boolean>(false);

    const onProfilesSuccessToggle = (isProfilesToggleOn: boolean) => {
        setIsProfilesEnabled(isProfilesToggleOn);
        localStorageUtil.set(ELocalStorageKeys.IS_PROIFLES_ENABLED, isProfilesToggleOn);
        if (isProfilesToggleOn) {
            updateIntegration.mutate({
                playerInfoSyncUrl: `${process.env.REACT_APP_PROFILES_LAMBDA_URL}${ELambdaProfilesEndpoints.PIS_URL}/publisherId/${publisherId}`,
                playersAuthWebhook: `${process.env.REACT_APP_PROFILES_LAMBDA_URL}${ELambdaProfilesEndpoints.AUTH}/publisherId/${publisherId}`,
                ordersReportingApiUrl: `${process.env.REACT_APP_PROFILES_LAMBDA_URL}${ELambdaProfilesEndpoints.ORDER_REPORTING}/publisherId/${publisherId}`,
                eventsWebhookUrl: `${process.env.REACT_APP_PROFILES_LAMBDA_URL}${ELambdaProfilesEndpoints.PUBLISHER_EVENT_WEBHOOK}/publisherId/${publisherId}`
            }, {
                onSuccess: () => {
                    enqueueSnackbar(
                        'Integration profiles are not active',
                        ENotificationType.SUCCESS
                    );
                },
                onError: () => {
                    enqueueSnackbar(
                        'Failed to create default profile',
                        ENotificationType.ERROR
                    );
                },
                onSettled: () => setIsToggleInProgress(false)
            });
        }
        else {
            updateIntegration.mutate({
                playerInfoSyncUrl: defaultProfile?.playerInfoSyncUrl,
                playersAuthWebhook: defaultProfile?.playersAuthWebhook,
                ordersReportingApiUrl: defaultProfile?.ordersReportingApiUrl,
                eventsWebhookUrl: defaultProfile?.eventsWebhookUrl
            }, {
                onSuccess: () => {
                    enqueueSnackbar(
                        'Integration profile disabled successfully',
                        ENotificationType.SUCCESS
                    );
                },
                onError: () => {
                    enqueueSnackbar(
                        'Failed to set default integration',
                        ENotificationType.ERROR
                    );
                },
                onSettled: () => setIsToggleInProgress(false)
            });
        }
    };

    const toggleIntegrationProfiles = (isProfilesToggleOn: boolean, form: Partial<ProfileData>) => {
        setIsToggleInProgress(true);
        if (isProfilesToggleOn) {
            createProfile.mutate(
                {
                    name: 'Default',
                    ordersReportingApiUrl: form.ordersReportingApiUrl,
                    playersAuthWebhook: form.playersAuthWebhook,
                    playerInfoSyncUrl: form.playerInfoSyncUrl,
                    eventsWebhookUrl: form.eventsWebhookUrl,
                    fbAppId: form.fbAppId
                },
                {
                    onSuccess: (response: any) => {
                        setDefaultProfile(response.data);
                        onProfilesSuccessToggle(isProfilesToggleOn);
                    },
                    onError: () => {
                        setIsToggleInProgress(false);
                        enqueueSnackbar(
                            'Failed to create default profile',
                            ENotificationType.ERROR
                        );
                    }
                }
            );
        } else {
            if (!defaultProfile) return;
            deleteProfile.mutate(defaultProfile._id, {
                onSuccess: () => {
                    onProfilesSuccessToggle(isProfilesToggleOn);
                },
                onError: () => {
                    setIsToggleInProgress(false);
                    enqueueSnackbar(
                        'Failed to deactivate Integration profiles',
                        ENotificationType.ERROR
                    );
                }
            });
        }
    };

    useEffect(() => {
        if (!isProfilesFeatureFlagOn) setIsProfilesEnabled(false);
        setIsProfilesEnabled(localStorageUtil.get(ELocalStorageKeys.IS_PROIFLES_ENABLED));
    }, [isProfilesFeatureFlagOn]);

    useEffect(() => {
        if (!getProfiles.data) return;
        const defaultProfile = getProfiles.data.find((profile: ProfileData) => profile.name === 'Default');
        setDefaultProfile(defaultProfile);
    }, [getProfiles.data])

    useEffect(() => {
        if (!fetchFeatureFlags.data) return;
        setIsProfilesFeatureFlagOn(
            fetchFeatureFlags.data.featureFlags.dashboard_integration_profiles
        );
    }, [fetchFeatureFlags.data]);

    return { toggleIntegrationProfiles, isProfilesEnabled, isToggleInProgress, isProfilesFeatureFlagOn };
};
