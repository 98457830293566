import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useLogin } from 'hooks/useLogin';
import { uiActions } from 'store/uiSlice';

import LoginModal from 'components/LoginModal/LoginModal';

import './style.scss';
// dashboard-dev.appcharge.com/?t=eyJhZG1pblVzZXJFbWFpbCI6InN0YXJkdXN0LXN1cGVyYWRtaW5AYXBwY2hhcmdlLmNvbSIsInBhc3N3b3JkIjoiMjNGJGR4T2dyKnA7In0=
const LoginPage = () => {
  const login = useLogin();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectTarget = urlParams.get('t');
    if (login.isLoggedIn() && redirectTarget) {
      login.logout();
      dispatch(uiActions.setTokenLoading(false));

      navigate(`./?t=${redirectTarget}`);
    }

    if (login.isLoggedIn()) {
      navigate(
        `./project/orders?page=0&rows=100&sortValue=date&direction=desc`
      );
    }
  }, [login, navigate, dispatch]);

  return login.isLoggedIn() ? null : (
    <div id="loginPage">
      <div
        style={{
          width: '50%'
        }}
      ></div>
      <img
        className="logo"
        alt="logo"
        src={`./assets/images/full-logo-white.svg`}
        style={{ marginBottom: '30px' }}
      />
      <LoginModal />
    </div>
  );
};

export default LoginPage;
