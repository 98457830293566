import { useMutation, useQuery } from '@tanstack/react-query';

import { Section } from '../common/contracts';
import { EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useSections(currentPublisherId?: string) {
  const axios = useAxios();

  const getSections = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.SECTIONS],
    queryFn: async () => {
      return await axios.get(getApiRouteOrMock(EApiRoutes.SECTIONS));
    },
    enabled: !!currentPublisherId
  });

  const addSections = useMutation(async (data: Partial<Section>) => {
    return await axios.post(getApiRouteOrMock(EApiRoutes.SECTIONS), data);
  }, {});

  const updateSections = useMutation(async (data: Partial<Section>) => {
    return await axios.put(getApiRouteOrMock(EApiRoutes.SECTIONS), data);
  }, {});

  const deleteSections = useMutation(async (data: Partial<Section>) => {
    return await axios.del(getApiRouteOrMock(EApiRoutes.SECTIONS), data);
  }, {});

  const upsertSections = useMutation(async (data: any) => {
    return await axios.post(
      getApiRouteOrMock(EApiRoutes.UPSERT_SECTIONS),
      data
    );
  }, {});

  return {
    getSections,
    addSections,
    updateSections,
    upsertSections,
    deleteSections
  };
}
