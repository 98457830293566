import { Route, Routes, useParams } from 'react-router-dom';
import useIntegration from 'api/useIntegration';
import { OffersForm } from './Form/OffersForm';
import OffersTable from './OffersTable';

import '../../views/view.scss';

const OffersView = () => {
  const { publisherId } = useParams();
  const { data } = useIntegration(publisherId).getIntegration;
  return (
    <Routes>
      <Route path="/" element={<OffersTable />} />
      <Route
        path="/form"
        element={<OffersForm isPlayerInfoSync={!!data?.playerInfoSyncUrl} />}
      />
      <Route
        path="/form/:offerId"
        element={
          <OffersForm
            edit={true}
            isPlayerInfoSync={!!data?.playerInfoSyncUrl}
          />
        }
      />
      <Route
        path="/dup/:offerId"
        element={
          <OffersForm dup={true} isPlayerInfoSync={!!data?.playerInfoSyncUrl} />
        }
      />
    </Routes>
  );
};

export default OffersView;
