import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  ActionButton,
  Background,
  CheckoutBox,
  MessagePage
} from '@appcharge/shared-ui';
import { Divider, Grid, Stack } from '@mui/material';
import useTheme from 'api/useTheme';
import { EStyleProperty } from 'constants/enums';
import { useFormikContext } from 'formik';
import { getStyledSelectItem } from 'utils/getStyledSelectItem';

import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

import { ThemeLoader } from '../ThemeLoader';

import { CompletedThemeFormValues } from './types';

import 'style/forms.scss';

export const CompletedTheme: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { values, handleBlur, handleChange, setFieldValue, errors, touched } =
    useFormikContext<CompletedThemeFormValues>();

  const { publisherId } = useParams();

  const { getStoreTheme: storeTheme } = useTheme(publisherId || undefined);

  const hasValues = useMemo(() => Object.keys(values).length > 0, [values]);

  useEffect(
    () => setIsLoading(storeTheme.isLoading || !storeTheme.data || !hasValues),
    [storeTheme?.isLoading, storeTheme?.data, hasValues]
  );

  if (isLoading) {
    return <ThemeLoader />;
  }

  return (
    <Grid container className="formContent">
      <Grid item xs={5}>
        <Stack gap={3}>
          <AcCard
            stackContainer={false}
            title="Confirm the purchase"
            description="Congratulate your players on a successful purchase and let them know what’s next"
          >
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcInput
                  header="Header"
                  name="headerText"
                  value={values.headerText}
                  tooltip="This will be the header of the success screen."
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.headerText && Boolean(errors.headerText)}
                  helperText={
                    touched.headerText ? errors.headerText?.toString() : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AcGradientInput
                  header="Color"
                  name="headerColor"
                  defaultValue={values.headerColor}
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  error={touched.headerColor && Boolean(errors.headerColor)}
                  helperText={
                    touched.headerColor ? errors.headerColor?.toString() : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AcInput
                  header="Size"
                  name="headerSize"
                  type="number"
                  slotProps={{
                    htmlInput: {
                      max: 40,
                      min: 26
                    }
                  }}
                  value={values.headerSize}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.headerSize && Boolean(errors.headerSize)}
                  helperText={
                    touched.headerSize ? errors.headerSize?.toString() : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AcSelect
                  header="Weight"
                  name="headerWeight"
                  defaultValue="regular"
                  value={values.headerWeight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.headerWeight && Boolean(errors.headerWeight)}
                  items={[
                    {
                      content: 'Regular',
                      key: 'regular',
                      value: '300',
                      renderFunction: () =>
                        getStyledSelectItem(
                          'regular',
                          EStyleProperty.fontWeight
                        )
                    },
                    {
                      content: 'Medium',
                      key: 'medium',
                      value: '500',
                      renderFunction: () =>
                        getStyledSelectItem('medium', EStyleProperty.fontWeight)
                    },
                    {
                      content: 'Bold',
                      key: 'bold',
                      value: '700',
                      renderFunction: () =>
                        getStyledSelectItem('bold', EStyleProperty.fontWeight)
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </AcCard>
          <AcCard stackContainer={false} title="Additional text">
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcInput
                  header="Text"
                  name="text"
                  value={values.text}
                  tooltip=""
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.text && Boolean(errors.text)}
                  helperText={touched.text ? errors.text?.toString() : ''}
                />
              </Grid>
              <Grid item xs={4}>
                <AcGradientInput
                  header="Color"
                  name="textColor"
                  defaultValue={{
                    colorOne: values.textColor
                  }}
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  error={touched.textColor && Boolean(errors.textColor)}
                  helperText={
                    touched.textColor ? errors.textColor?.toString() : ''
                  }
                  outputAsSingleColor
                  onlySolid
                />
              </Grid>
              <Grid item xs={4}>
                <AcInput
                  header="Size"
                  name="textSize"
                  type="number"
                  slotProps={{
                    htmlInput: {
                      max: 24,
                      min: 12
                    }
                  }}
                  value={values.textSize}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.textSize && Boolean(errors.textSize)}
                  helperText={
                    touched.textSize ? errors.textSize?.toString() : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AcSelect
                  header="Weight"
                  name="textWeight"
                  defaultValue="regular"
                  value={values.textWeight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.textWeight && Boolean(errors.textWeight)}
                  items={[
                    {
                      content: 'Regular',
                      key: 'regular',
                      value: '300',
                      renderFunction: () =>
                        getStyledSelectItem(
                          'regular',
                          EStyleProperty.fontWeight
                        )
                    },
                    {
                      content: 'Medium',
                      key: 'medium',
                      value: '500',
                      renderFunction: () =>
                        getStyledSelectItem('medium', EStyleProperty.fontWeight)
                    },
                    {
                      content: 'Bold',
                      key: 'bold',
                      value: '700',
                      renderFunction: () =>
                        getStyledSelectItem('bold', EStyleProperty.fontWeight)
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
        <Divider />
        <Stack gap={3}>
          <AcCard stackContainer={false} title="Back to Game Button">
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcInput
                  headerSize="14"
                  header="Back to Game Button Text"
                  name="backToGameButtonText"
                  value={values.backToGameButtonText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.backToGameButtonText &&
                    Boolean(errors.backToGameButtonText)
                  }
                  helperText={
                    touched.backToGameButtonText
                      ? errors.backToGameButtonText?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  headerSize="14"
                  header="Android Back to Game Button Deep Link"
                  name="deepLinks.android"
                  value={values.deepLinks.android}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.deepLinks?.android &&
                    Boolean(errors.deepLinks?.android)
                  }
                  helperText={
                    touched.deepLinks?.android
                      ? errors.deepLinks?.android?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  headerSize="14"
                  header="Apple Back to Game Button Deep Link"
                  name="deepLinks.ios"
                  value={values.deepLinks.ios}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.deepLinks?.ios && Boolean(errors.deepLinks?.ios)
                  }
                  helperText={
                    touched.deepLinks?.ios
                      ? errors.deepLinks?.ios?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  headerSize="14"
                  header="Web Back to Game Button Deep Link"
                  name="deepLinks.web"
                  value={values.deepLinks.web}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.deepLinks?.web && Boolean(errors.deepLinks?.web)
                  }
                  helperText={
                    touched.deepLinks?.web
                      ? errors.deepLinks?.web?.toString()
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
      </Grid>
      <Grid item xs={7} className="iphone-mock-wrapper">
        <div className="store-preview-wrapper iphone-mock">
          <Background
            backgroundImageDesktop={
              storeTheme.data?.general?.backgroundImageMobile
            }
            backgroundImageMobile={
              storeTheme.data?.general?.backgroundImageMobile
            }
            width="100%"
            height="100%"
            position="absolute"
          >
            <MessagePage
              headerText={values.headerText}
              headerColor={values.headerColor}
              headerSize={values.headerSize}
              headerWeight={values.headerWeight}
              text={[values.text]}
              textColor={values.textColor}
              textSize={values.textSize}
              textWeight={values.textWeight}
              fontFamily={storeTheme.data?.general?.font}
              preview={true}
            >
              <CheckoutBox height="135px" width="90%">
                <div></div>
              </CheckoutBox>
              <ActionButton
                action={() => null}
                text={values.backToGameButtonText}
                colors={storeTheme.data?.general?.buttonColor}
                textColor={storeTheme.data?.general?.buttonTextColor}
              />
            </MessagePage>
          </Background>
        </div>
      </Grid>
    </Grid>
  );
};
