import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import {
  BundlesLayout,
  EBundlesViewModel,
  ELayout
} from '@appcharge/shared-ui';
import useTheme from 'api/useTheme';

import { EBundlesInternalViewModel } from '../../../../constants/enums';
import { mapBundleComponents } from '../../../../utils/mapBundleComponent';
import { StoreThemeFormValues } from '../storeTheme.types';

export const StoreThemeBundles: React.FC<{
  bundleBorderColor: StoreThemeFormValues['bundleBorderColor'];
}> = ({ bundleBorderColor }) => {
  const { publisherId } = useParams();

  const { getStoreTheme: storeTheme } = useTheme(publisherId);

  const bundlesViewModel = storeTheme.data?.general.bundlesViewModel as ELayout;
  const BundlesInternalLayout =
    mapBundleComponents[
      storeTheme.data?.general
        .bundlesInternalViewModel as EBundlesInternalViewModel
    ];

  const bundlesAmount = useMemo(() => {
    const viewModel = storeTheme.data?.general.bundlesViewModel;
    if (viewModel === EBundlesViewModel.TWO_IN_ONE) return 2;
    if (viewModel === EBundlesViewModel.THREE_IN_ONE) return 3;
    if (viewModel === EBundlesViewModel.SIX_IN_ONE) return 6;
    return 1;
  }, [storeTheme.data?.general.bundlesViewModel]);

  return (
    <BundlesLayout bundlesViewModel={bundlesViewModel} isPreview={true}>
      {Array.from(
        {
          length: bundlesAmount
        },
        () => null
      ).map((i, index) => (
        <div className="bundle" key={index}>
          <BundlesInternalLayout
            bundleBorderColor={bundleBorderColor}
            productList={[]}
            bundleButtonColor={storeTheme.data?.general.buttonColor}
            backgroundSrc={
              storeTheme.data?.general.bundlesInternalViewModel !==
              EBundlesInternalViewModel.PLAY_SIMPLE
                ? 'https://media.appcharge.com/defaults/background.png'
                : ''
            }
            bundleButtonTextColor={storeTheme.data?.general.buttonTextColor}
            fontFamily={storeTheme.data?.general.font}
            prices={{ priceDisplay: '$0.00', priceInUsdCents: 1 }}
            inMobileMock={true}
          />
        </div>
      ))}
    </BundlesLayout>
  );
};
