import { useMutation, useQuery } from '@tanstack/react-query';

import { EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useImages(currentPublisherId?: string) {
  const axios = useAxios();

  const getImages = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.IMAGES],
    queryFn: async () => {
      return await axios.get(getApiRouteOrMock(EApiRoutes.IMAGES));
    },
    enabled: !!currentPublisherId
  });

  const deleteImage = useMutation(async (imageId: string) => {
    return await axios.del(
      `${getApiRouteOrMock(EApiRoutes.DELETE_IMAGES)}/${imageId}`
    );
  });

  const addImage = useMutation(async (newImage: FormData) => {
    return await axios.post(getApiRouteOrMock(EApiRoutes.POST_IMAGE), newImage);
  });

  return {
    addImage,
    getImages,
    deleteImage
  };
}
