import React, { FC, ReactNode } from 'react';

import { cn } from '../lib/utils';

export type TypographyProps = {
  children?: ReactNode;
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
  required?: boolean;
} & React.HTMLAttributes<HTMLOrSVGElement>;

const tagStyles: Record<string, string> = {
  h1: 'text-hd-h1',
  h2: 'text-hd-h2',
  h3: 'text-hd-h3',
  h4: 'text-hd-h4'
};

const Typography: FC<TypographyProps> = ({
  children,
  tag: Tag = 'p',
  className,
  required = false,
  ...rest
}) => {
  if (!children) return null;

  return (
    <Tag
      {...rest}
      className={cn(
        'text-p-m text-base-foreground',
        tagStyles[Tag],
        required &&
          'before:content-["*"] before:text-base-destructive before:text-xs before:mr-2 before:align-text-top',
        className
      )}
    >
      {children}
    </Tag>
  );
};

export default Typography;
