import { useMutation, useQuery } from '@tanstack/react-query';

import { IPermissions } from 'common/permissions.types';
import { ProfileData } from 'components/Settings/Settings.types';
import { permissionsUtil } from 'utils/permissionsUtil'

import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';
import { localStorageUtil } from '../utils/localStorageUtil';

export default function useProfiles(currentPublisherId?: string) {
  const axios = useAxios();

  const canAccessProfiles = currentPublisherId ? !!permissionsUtil.canAccessProfiles(
      localStorageUtil.get<IPermissions>(
          ELocalStorageKeys.PERMISSIONS
      ) || {},
      currentPublisherId
  ) : false

  const getProfiles = useQuery<ProfileData[], Error>({
    ...fetchConfig.general,
    queryKey: [EQueryKeys.PROFILES],
    queryFn: async () => {
      return await axios.get(
        getApiRouteOrMock(EApiRoutes.PROFILES),
        {},
        currentPublisherId ? { 'x-publishers': currentPublisherId } : undefined
      );
    },
    enabled: canAccessProfiles
  });

  const createProfile = useMutation(
    async (data: Partial<ProfileData>) => {
      return await axios.post(getApiRouteOrMock(EApiRoutes.PROFILES), data);
    },
    {}
  );

  const updateProfile = useMutation(
    async (data: Partial<ProfileData>) => {
      return await axios.put(`${getApiRouteOrMock(EApiRoutes.PROFILES)}/${data._id}`, data);
    },
    {}
  );

  const deleteProfile = useMutation(
    async (profileId: string) => {
      return await axios.del(`${getApiRouteOrMock(EApiRoutes.PROFILES)}/${profileId}`);
    },
    {}
  );

  return {
    getProfiles,
    createProfile,
    updateProfile,
    deleteProfile
  };
}
