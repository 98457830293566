import { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { GridValueGetterParams } from '@mui/x-data-grid';
import useOffers from 'api/useOffers';
import { Offer } from 'common/contracts';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import {
  EAppearanceStatus,
  EButtonColor,
  ELocalStorageKeys,
  ENotificationType,
  EScheduleType,
  OfferType
} from 'constants/enums';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { EStatusLabel } from 'design-system/StatusLabel/types';
import { useNotifications } from 'hooks/useNotifications';
import { usePaginationFromURL } from 'hooks/usePaginationFromURL';
import { permissionsUtil } from 'utils/permissionsUtil';
import { appearanceUtils } from 'utils/scheduleUtils';

import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';
import DataTable from 'components/DataTable/DataTable';
import DialogModal from 'components/Dialog/Dialog';
import FirstActionModal from 'components/FirstActionModal/FirstActionModal';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';
import PageTopBar from 'components/Topbar/PageTopBar';

import { TableSearchFilters } from '../../components/TableSearchFilters/TableSearchFilters';
import {
  PublisherIdType,
  SearchKeyType,
  useTableSearch
} from '../../hooks/useTableSearch';

const RollingOffersTable = () => {
  const { publisherId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useNotifications();
  const { page, rows } = usePaginationFromURL('offers');

  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(rows);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<string | null>(null);

  const { getOffers, deleteOffer, updateOffer } = useOffers(
    undefined,
    OfferType.ROLLING_OFFER,
    {
      currentPage,
      rowsPerPage
    }
  );

  const totalCount = getOffers.data?.totalCount || 0;

  useEffect(() => {
    navigate(`${location.pathname}?page=${currentPage}&rows=${rowsPerPage}`, {
      replace: true
    });
    getOffers.refetch();
  }, [currentPage, rowsPerPage]);

  const handleTableStateChange = useCallback(() => {
    const dataGridDiv = document.querySelector('.MuiDataGrid-main');
    if (dataGridDiv) {
      dataGridDiv.setAttribute('data-testid', 'dashRollingOfferList');
    }
  }, [])

  const handleEditOfferUI = (offerId: string): void => {
    navigate(`./form/${offerId}`);
  };

  const handleDuplicateOffer = (offerId: string): void => {
    navigate(`./dup/${offerId}`);
  };

  const handleDeleteOffer = async () => {
    if (!selectedOffer) return;
    deleteOffer.mutate(selectedOffer, {
      onSuccess: () => {
        enqueueSnackbar(
          'Rolling Offer deleted successfully',
          ENotificationType.SUCCESS
        );
        getOffers.refetch();
      },
      onError: (e: any) => {
        enqueueSnackbar(
          'Failed to delete rolling Offer',
          ENotificationType.ERROR,
          e.response.data.message ?? ''
        );
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
        setSelectedOffer(null);
      }
    });
  };

  const RowContentWrapper = ({ children }: { children: React.ReactNode }) => {
    return <div className="row-content">{children}</div>;
  };

  const columns = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <RowContentWrapper>{params.row.name}</RowContentWrapper>
      )
    },
    {
      field: 'offerId',
      flex: 1,
      headerName: 'ID',
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <RowContentWrapper>{params.row.offerId}</RowContentWrapper>
      )
    },
    {
      field: 'publisherOfferId',
      flex: 1,
      headerName: 'SKU',
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <RowContentWrapper>{params.row.publisherOfferId}</RowContentWrapper>
      )
    },
    {
      field: 'subOffers',
      flex: 1,
      headerName: 'Sub Offers',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.productsSequence?.length || 0,
      renderCell: (params: GridRenderCellParams) => (
        <RowContentWrapper>{params.value}</RowContentWrapper>
      )
    },
    {
      field: 'segments',
      flex: 1,
      headerName: 'Segments',
      width: 200
    },
    {
      field: 'isActive',
      flex: 0.5,
      headerName: 'Is Active',
      width: 150,
      valueGetter: (params: GridValueGetterParams) => params.row.active,
      renderCell: (params: GridRenderCellParams) => {
        const { offerId, active } = params.row;
        return (
          <CustomizedSwitch
            status={active}
            texts={[]}
            functions={[
              () => toggleRollingStatus(offerId, true),
              () => toggleRollingStatus(offerId, false)
            ]}
          />
        );
      }
    },
    {
      field: 'schedule',
      flex: 1,
      headerName: 'Schedule Status',
      width: 250,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        const { status } = appearanceUtils.getStrongestStatusWithTime(
          (params.row as Offer)?.schedule?.timeFrames
        );
        if (
          (params.row as Offer)?.schedule?.permanent ||
          !(params.row as Offer)?.schedule
        ) {
          return (
            <StatusLabel
              text={EScheduleType.PERMANENT}
              status={EStatusLabel.ACTIVE}
              prefixIcon={<CheckCircleOutlineIcon />}
            />
          );
        } else {
          return (
            <StatusLabel
              text={status}
              status={
                status === EAppearanceStatus.RUNNING
                  ? EStatusLabel.ACTIVE
                  : status === EAppearanceStatus.UPCOMING
                    ? EStatusLabel.PENDING
                    : EStatusLabel.FAILED
              }
              prefixIcon={
                status === EAppearanceStatus.RUNNING ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <ScheduleIcon />
                )
              }
            />
          );
        }
      },
      sortComparator: (
        a: number,
        b: number,
        params1: { id: string | number },
        params2: { id: string | number }
      ) => appearanceUtils.sortByStatusOrder(params1, params2, offers)
    },
    {
      field: 'nextAppearance',
      flex: 1,
      headerName: 'Next Appearance',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        const schedule = (params.row as Offer)?.schedule;
        const { status, appearanceTime } =
          appearanceUtils.getStrongestStatusWithTime(schedule?.timeFrames);

        return schedule?.permanent || status !== EAppearanceStatus.UPCOMING
          ? null
          : appearanceTime;
      },
      renderCell: (params: GridRenderCellParams) => {
        return <RowContentWrapper>{params.value || '-'}</RowContentWrapper>;
      }
    },
    {
      field: 'actions',
      flex: 0,
      type: 'actions',
      width: 80,
      disableReorder: true,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<EditTwoToneIcon />}
          label="Edit"
          onClick={() => {
            handleEditOfferUI(params.id);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
              null,
              EPermissionGroup.POPUPS,
              EPermissionAction.MANAGE,
              publisherId
            )
          }
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ContentCopyOutlinedIcon />}
          label="Duplicate"
          onClick={() => {
            handleDuplicateOffer(params.id);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
              null,
              EPermissionGroup.POPUPS,
              EPermissionAction.MANAGE,
              publisherId
            )
          }
          showInMenu
        />,
        <GridActionsCellItem
          className="danger"
          icon={<DeleteOutlineTwoToneIcon className="danger" />}
          label="Delete"
          onClick={() => {
            setIsDeleteDialogOpen(true);
            setSelectedOffer(params.id);
          }}
          disabled={
            !permissionsUtil.canUserEdit() ||
            !permissionsUtil.isActionEnabled(
              null,
              EPermissionGroup.POPUPS,
              EPermissionAction.MANAGE,
              publisherId
            )
          }
          showInMenu
        />
      ]
    }
  ];

  const offers: Offer[] =
    (!getOffers.isLoading &&
      getOffers?.data?.offers &&
      getOffers?.data?.offers.map((offer: Offer) => ({
        _id: offer.publisherOfferId,
        id: offer.offerId,
        ...offer
      }))) ||
    [];

  const toggleRollingStatus = async (offerId: string, active: boolean) => {
    updateOffer.mutate(
      { offerId, form: { active: !active } },
      {
        onSuccess: () => {
          enqueueSnackbar(
            active
              ? 'Rolling Offer deactivated successfully'
              : 'Rolling Offer activated successfully',
            ENotificationType.SUCCESS
          );
          getOffers.refetch();
        },
        onError: () => {
          enqueueSnackbar(
            'Error editing rolling offer',
            ENotificationType.ERROR
          );
        }
      }
    );
  };
  const {
    searchValue,
    filterValue,
    setSearchValue,
    setFilterValue,
    filteredData,
    isTableDataEmpty
  } = useTableSearch({
    data: offers,
    publisherId: PublisherIdType.OFFER_ID,
    searchKey: SearchKeyType.NAME
  });

  return (
    <>
      <AcViewWrapper
        header={
          <PageTopBar
            headline="Rolling Offers"
            buttons={[
              {
                text: 'Add New Rolling Offer',
                action: () => navigate('./form'),
                disabled: !permissionsUtil.canUserEdit(),
                hidden: !permissionsUtil.isActionEnabled(
                  null,
                  EPermissionGroup.POPUPS,
                  EPermissionAction.MANAGE,
                  publisherId
                )
              }
            ]}
          />
        }
      >
        <AcContentWrapper>
          <TableSearchFilters
            searchValue={searchValue}
            filterValue={filterValue}
            setSearchValue={setSearchValue}
            setFilterValue={setFilterValue}
            tableVariant={'rolling offer'}
          />
          <DataTable
            pagination={true}
            className="rollingOffersTable"
            rowIdIdentifier="offerId"
            columns={columns}
            rows={filteredData()}
            loading={getOffers.isLoading || getOffers.isFetching}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(newRowsPerPage) => {
              setRowsPerPage(newRowsPerPage);
              setCurrentPage(0);
            }}
            currentPage={currentPage}
            totalCount={totalCount}
            defaultHiddenFields={[]}
            localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
            hideFooter={false}
            error={false}
            initialSorting={{
              sortModel: [{ field: 'createdAt', sort: 'desc' }]
            }}
            onNoData={
              isTableDataEmpty ? (
                <FirstActionModal
                  headline="Make your rolling offers pop!"
                  text="Create your first rolling offer by hitting the “Add New Rolling Offer” button on the top right corner of the screen"
                />
              ) : (
                <FirstActionModal
                  headline="No exact matches"
                  text="Try changing or removing your filter or adjusting your search area"
                />
              )
            }
            getRowClassName={appearanceUtils.getRowClassName}
            onStateChange={handleTableStateChange}
          />
        </AcContentWrapper>
      </AcViewWrapper>

      <DialogModal
        isOpen={isDeleteDialogOpen}
        headline="Delete Rolling Offer"
        text="Are you sure you want to delete this Rolling Offer?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setIsDeleteDialogOpen(false);
            }
          },
          {
            text: 'Delete',
            color: EButtonColor.ERROR,
            variant: 'contained',
            func: handleDeleteOffer
          }
        ]}
        closeDialog={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};

export default RollingOffersTable;
