import { useEffect, useMemo, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import useSections from 'api/useSections';
import useTheme from 'api/useTheme';

import { defaultStoreTheme } from './defaults';
import { StoreThemeFormValues } from './storeTheme.types';

export const useGetStoreInitialValues = () => {
  const location = useLocation();

  const { publisherId } = useParams();

  const [initialValues, setInitialValue] =
    useState<StoreThemeFormValues>(defaultStoreTheme);
  const { getStoreTheme: storeTheme } = useTheme(publisherId);
  const { getSections } = useSections(publisherId);

  const storeScreenTheme = useMemo(
    () => ({ data: storeTheme?.data?.storeScreen }),
    [storeTheme?.data]
  );

  useEffect(() => {
    setInitialValue({
      defaultBanner:
        storeScreenTheme?.data?.defaultBanner ||
        defaultStoreTheme.defaultBanner,
      sections: getSections.data?.result || defaultStoreTheme.sections,
      buttonColor: {
        colorOne:
          storeTheme?.data?.general?.buttonColor?.colorOne ||
          defaultStoreTheme.buttonColor.colorOne,
        colorTwo:
          storeTheme?.data?.general?.buttonColor?.colorTwo ||
          defaultStoreTheme.buttonColor.colorTwo,
        gradientDirection:
          storeTheme?.data?.general?.buttonColor?.gradientDirection ||
          defaultStoreTheme.buttonColor.gradientDirection
      },
      buttonTextColor:
        storeTheme?.data?.general?.buttonTextColor ||
        defaultStoreTheme.buttonTextColor,
      addToHomeScreen: {
        active:
          storeScreenTheme?.data?.addToHomeScreen?.active ||
          defaultStoreTheme.addToHomeScreen.active,
        buttonImage:
          storeScreenTheme?.data?.addToHomeScreen?.buttonImage ||
          defaultStoreTheme.addToHomeScreen.buttonImage,
        iconImage:
          storeScreenTheme?.data?.addToHomeScreen?.iconImage ||
          defaultStoreTheme.addToHomeScreen.iconImage,
        shortcutName:
          storeScreenTheme?.data?.addToHomeScreen?.shortcutName ||
          defaultStoreTheme.addToHomeScreen.shortcutName,
        borderColor: {
          colorOne:
            storeScreenTheme?.data?.addToHomeScreen?.borderColor?.colorOne ||
            defaultStoreTheme.addToHomeScreen.borderColor.colorOne
        },
        backgroundColor: {
          colorOne:
            storeScreenTheme?.data?.addToHomeScreen?.backgroundColor
              ?.colorOne ||
            defaultStoreTheme.addToHomeScreen.backgroundColor.colorOne,
          colorTwo:
            storeScreenTheme?.data?.addToHomeScreen?.backgroundColor
              ?.colorTwo ||
            defaultStoreTheme.addToHomeScreen.backgroundColor.colorTwo,
          gradientDirection:
            storeScreenTheme?.data?.addToHomeScreen?.backgroundColor
              ?.gradientDirection ||
            defaultStoreTheme.addToHomeScreen.backgroundColor.gradientDirection
        }
      },
      noOffersTitleText:
        storeScreenTheme?.data?.noOffersTitleText ||
        defaultStoreTheme.noOffersTitleText,
      noOffersMessageText:
        storeScreenTheme?.data?.noOffersMessageText ||
        defaultStoreTheme.noOffersMessageText,
      bundleBorderColor: {
        colorOne:
          storeScreenTheme?.data?.bundleBorderColor?.colorOne ||
          defaultStoreTheme.bundleBorderColor?.colorOne,
        colorTwo:
          storeScreenTheme?.data?.bundleBorderColor?.colorTwo ||
          defaultStoreTheme.bundleBorderColor?.colorTwo,
        gradientDirection:
          storeScreenTheme?.data?.bundleBorderColor?.gradientDirection ||
          defaultStoreTheme.bundleBorderColor?.gradientDirection
      }
    });
  }, [storeScreenTheme?.data, storeTheme?.data, getSections?.data, location]);

  return { initialValues };
};
