import { Grid } from '@mui/material';
import useUsers from 'api/useUsers';
import { OfferUiProps } from 'common/contracts';
import { EAssetType, EFeatureFlag } from 'constants/enums';
import { useUploads } from 'hooks/useUpload';

import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcSelect from 'components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from 'components/AcSelect/AcSelect.types';

import PopupUiForm from '../Popups';
import { OfferDesignTypes } from '../types';
import { useParams } from 'react-router-dom';

const OfferUiAssets: React.FC<OfferUiProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  addPictureInputRef,
  getImages,
  setCurrentUploadingField,
  currentUploadingField,
  getStoreTheme,
  dup
}) => {
  const { publisherId } = useParams();
  const { fetchFeatureFlags } = useUsers({ publisherId });
  const hasFeatureFlagDailyBonusBadge =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_DAILY_BONUS_BADGE
    ];
  const { uploadImage, getAssetUploadMessage } = useUploads();

  return (
    <AcCard
      stackContainer={false}
      title="Offer Asset"
      description="Customize your store by adding brand assets that will be displayed across your web store, with the option to change them anytime."
      padded={false}
      className="offersUIFormCard"
    >
      <Grid container columnSpacing={{ xs: 1 }}>
        <Grid item xs={6}>
          <input
            style={{ display: 'none' }}
            type="file"
            onChange={(e) =>
              uploadImage(
                e,
                addPictureInputRef.current as HTMLInputElement,
                'backgroundImage',
                setFieldValue,
                setCurrentUploadingField,
                currentUploadingField
              )
            }
            ref={addPictureInputRef}
            accept="image/*"
          />
          <AcSelect
            required={values.offerUiType !== OfferDesignTypes.Bundle}
            header="Background Image"
            name="backgroundImage"
            value={values.backgroundImage}
            uploadConfig={{
              onUploadSuccess: async (uploadData: any) => {
                await getImages.refetch();
                setFieldValue('backgroundImage', uploadData!.data.url);
              },
              uploadType: EAssetType.BG_BUNDLE,
              uploadMessage: getAssetUploadMessage(values.offerUiType)
            }}
            renderType={EAcSelectItemRenderType.IMAGE}
            items={[
              {
                content: 'Default Appcharge background image',
                key: 'https://media.appcharge.com/defaults/background.png',
                value: 'https://media.appcharge.com/defaults/background.png',
                url: 'https://media.appcharge.com/defaults/background.png',
                name: 'Default Appcharge background image'
              },
              ...getImages.data
                .filter((p: any) => p.type === EAssetType.BG_BUNDLE)
                .map((picture: any) => {
                  return {
                    content: picture.name,
                    key: picture.url,
                    value: picture.url,
                    url: picture.url,
                    name: picture.name
                  };
                })
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={
              values.offerUiType === OfferDesignTypes.Bundle
                ? () => {
                    setFieldValue('backgroundImage', '');
                  }
                : undefined
            }
            error={touched.backgroundImage && Boolean(errors.backgroundImage)}
            helperText={
              touched.backgroundImage || dup
                ? errors.backgroundImage?.toString()
                : ''
            }
            imgHeight={'32'}
          />
        </Grid>
        {(values.offerUiType === OfferDesignTypes.Bundle ||
          getStoreTheme?.data?.general.specialOffersInternalViewModel !==
            'hug') && (
          <Grid item xs={6}>
            <AcGradientInput
              header="BG Color"
              name="specialOffer.backgroundColor"
              defaultValue={values.specialOffer.backgroundColor}
              onChange={handleChange}
              setValue={setFieldValue}
              onBlur={handleBlur}
              error={
                touched.specialOffer?.backgroundColor &&
                Boolean(errors.specialOffer?.backgroundColor)
              }
              helperText={
                touched.specialOffer?.backgroundColor
                  ? errors.specialOffer?.backgroundColor?.toString()
                  : ''
              }
            />
          </Grid>
        )}
      </Grid>
      {values.offerUiType === OfferDesignTypes.PopUp &&
        hasFeatureFlagDailyBonusBadge && (
          <PopupUiForm
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            addPictureInputRef={addPictureInputRef}
            getImages={getImages}
            setCurrentUploadingField={setCurrentUploadingField}
            currentUploadingField={currentUploadingField}
            getAssetUploadMessage={getAssetUploadMessage}
            dup={dup}
          />
        )}
    </AcCard>
  );
};

export default OfferUiAssets;
