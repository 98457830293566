import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LinkIcon from '@mui/icons-material/Link';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { CircularProgress } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import useDeeplinks from 'api/useDeeplinks';
import useOffers from 'api/useOffers';
import useUsers from 'api/useUsers';
import { Offer } from 'common/contracts';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import { DATE_TIME_FORMAT } from 'constants/constants';
import {
  EAppearanceStatus,
  EButtonColor,
  EFeatureFlag,
  ELocalStorageKeys,
  ENotificationType,
  EScheduleType,
  OfferType
} from 'constants/enums';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { EStatusLabel } from 'design-system/StatusLabel/types';
import { useNotifications } from 'hooks/useNotifications';
import { usePaginationFromURL } from 'hooks/usePaginationFromURL';
import { getConvertedPrice } from 'utils/getConvertedPrice';
import { permissionsUtil } from 'utils/permissionsUtil';
import { appearanceUtils } from 'utils/scheduleUtils';

import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';
import DataTable from 'components/DataTable/DataTable';
import DialogModal from 'components/Dialog/Dialog';
import FirstActionModal from 'components/FirstActionModal/FirstActionModal';
import { Product } from 'components/ProductsForm/ProductsForm.types';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';
import PageTopBar from 'components/Topbar/PageTopBar';

import { TableSearchFilters } from '../../components/TableSearchFilters/TableSearchFilters';
import {
  PublisherIdType,
  SearchKeyType,
  useTableSearch
} from '../../hooks/useTableSearch';

const dayjsUTC = dayjs.extend(utc);

const OffersTable = () => {
  const { publisherId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { generateOrGetDeeplink } = useDeeplinks(publisherId);
  const [generatingDeeplink, setGeneratingDeeplink] = useState(false);
  const { page, rows } = usePaginationFromURL('offers');
  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(rows);
  const {
    getOffers: offers,
    deleteOffer,
    updateOffer
  } = useOffers(undefined, OfferType.SPECIAL_OFFER, {
    currentPage,
    rowsPerPage
  });
  const totalCount = offers.data?.totalCount || 0;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
  const { enqueueSnackbar } = useNotifications();

  useEffect(() => {
    navigate(`${location.pathname}?page=${currentPage}&rows=${rowsPerPage}`, {
      replace: true
    });
    offers.refetch();
  }, [currentPage, rowsPerPage]);

  const { fetchFeatureFlags } = useUsers({ publisherId });

  const hasScheduleOfferFeatureFlag =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_SCHEDULED_OFFERS
    ];

  const hasDeeplinkOfferFeatureFlag =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_GENERATE_DEEPLINK
    ];

  const handleDeleteOffer = async () => {
    if (!selectedOffer) return;
    await deleteOffer.mutate(selectedOffer, {
      onSuccess: () => {
        enqueueSnackbar(
          'Promotion deleted successfully',
          ENotificationType.SUCCESS
        );
        offers.refetch();
      },
      onError: (e: any) => {
        enqueueSnackbar(
          'Failed to delete promotion',
          ENotificationType.ERROR,
          e.response.data.message ?? ''
        );
      },
      onSettled: () => {
        setIsDeleteDialogOpen(false);
        setSelectedOffer(null);
      }
    });
  };

  const RowContentWrapper = ({ children }: { children: React.ReactNode }) => {
    return <div className="row-content">{children}</div>;
  };

  const handleGetDeeplink = async (offerId: string) => {
    setGeneratingDeeplink(true);
    await generateOrGetDeeplink.mutateAsync(
      { offerId },
      {
        onSuccess: (data: any) => {
          // Create a temporary input element
          const tempInput = document.createElement('input');
          tempInput.setAttribute('readonly', ''); // Prevent virtual keyboard on iOS
          tempInput.value = data.deeplink;
          tempInput.style.position = 'absolute';
          tempInput.style.left = '-9999px'; // Move off-screen
          document.body.appendChild(tempInput);

          try {
            if (navigator.userAgent.match(/ipad|iphone/i)) {
              // iOS specific handling
              tempInput.contentEditable = 'true';
              tempInput.readOnly = false;

              // Select the text
              const range = document.createRange();
              range.selectNodeContents(tempInput);
              const selection = window.getSelection();
              selection?.removeAllRanges();
              selection?.addRange(range);
              tempInput.setSelectionRange(0, 999999);
            } else {
              // All other browsers
              tempInput.select();
            }

            document.execCommand('copy');
            enqueueSnackbar(
              'Deeplink generated successfully and copied to clipboard',
              ENotificationType.SUCCESS
            );
          } catch (err) {
            console.error('Copy failed:', err);
            enqueueSnackbar(
              'Deeplink generated successfully. Please copy it manually: ' +
                data.deeplink,
              ENotificationType.WARNING
            );
          } finally {
            document.body.removeChild(tempInput);
          }
        },
        onError: (error: any) => {
          console.log('error', error);
          enqueueSnackbar(
            'Something went wrong generating the deeplink',
            ENotificationType.ERROR
          );
        },
        onSettled: () => {
          setGeneratingDeeplink(false);
        }
      }
    );
  };

  const columns = [
    {
      field: 'offerId',
      flex: 1,
      headerName: 'ID',
      width: 100,
      renderCell: (params: any) => (
        <RowContentWrapper>{params.row.offerId}</RowContentWrapper>
      )
    },
    {
      field: 'publisherOfferId',
      flex: 1,
      headerName: 'SKU',
      width: 100,
      renderCell: (params: any) => (
        <RowContentWrapper>{params.row.publisherOfferId}</RowContentWrapper>
      )
    },
    {
      field: 'offerUi.name',
      flex: 1,
      headerName: 'Offer Design',
      width: 100,
      renderCell: (params: any) => {
        if (offers.isLoading) return '';
        return (
          <RowContentWrapper> {params.row.offerUi?.name} </RowContentWrapper>
        );
      }
    },
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      width: 100,
      renderCell: (params: any) => (
        <RowContentWrapper>{params.row.name}</RowContentWrapper>
      )
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Created at',
      width: 200,
      type: 'date',
      renderCell: (params: any) => {
        if (offers.isLoading) return '';
        return (
          <RowContentWrapper>
            {dayjsUTC.utc(params.row.createdAt).format(DATE_TIME_FORMAT)}
          </RowContentWrapper>
        );
      }
    },
    {
      field: 'isActive',
      flex: 0.5,
      headerName: 'Is Active',
      width: 150,
      renderCell: (params: any) => {
        const { offerId, active } = params.row;
        return (
          <CustomizedSwitch
            status={active}
            texts={[]}
            functions={[
              () => togglePromotionStatus(offerId, true),
              () => togglePromotionStatus(offerId, false)
            ]}
          />
        );
      }
    },
    {
      field: 'schedule',
      flex: 1,
      headerName: 'Schedule Status',
      width: 250,
      type: 'date',
      renderCell: (params: any) => {
        const { status } = appearanceUtils.getStrongestStatusWithTime(
          (params.row as Offer)?.schedule?.timeFrames
        );
        if (
          (params.row as Offer)?.schedule?.permanent ||
          !(params.row as Offer)?.schedule
        ) {
          return (
            <StatusLabel
              text={EScheduleType.PERMANENT}
              status={EStatusLabel.ACTIVE}
              prefixIcon={<CheckCircleOutlineIcon />}
            />
          );
        } else {
          return (
            <StatusLabel
              text={status}
              status={
                status === EAppearanceStatus.RUNNING
                  ? EStatusLabel.ACTIVE
                  : status === EAppearanceStatus.UPCOMING
                    ? EStatusLabel.PENDING
                    : EStatusLabel.FAILED
              }
              prefixIcon={
                status === EAppearanceStatus.RUNNING ? (
                  <CheckCircleOutlineIcon />
                ) : (
                  <ScheduleIcon />
                )
              }
            />
          );
        }
      },
      sortComparator: (
        a: number,
        b: number,
        params1: { id: string | number },
        params2: { id: string | number }
      ) => appearanceUtils.sortByStatusOrder(params1, params2, promotions)
    },
    {
      field: 'nextAppearance',
      flex: 1,
      headerName: 'Next Appearance',
      width: 200,
      renderCell: (params: any) => {
        const schedule = (params.row as Offer)?.schedule;

        const { status, appearanceTime } = appearanceUtils.getStrongestStatusWithTime(
          schedule?.timeFrames
        );

        if (schedule?.permanent && schedule?.interval) {
          return <RowContentWrapper>{appearanceUtils.getNextAppearanceByDailyRefreshTime(schedule.interval)}</RowContentWrapper>;
        }

        if ((schedule?.permanent && !schedule?.interval) || status !== EAppearanceStatus.UPCOMING) {
          return <RowContentWrapper>-</RowContentWrapper>; // Display "-" if permanent or not upcoming
        }
        return <RowContentWrapper>{appearanceTime}</RowContentWrapper>;
      }
    },
    {
      field: 'price',
      flex: 0.5,
      headerName: 'Price',
      width: 100,
      renderCell: (params: any) => {
        if (offers.isLoading) return '';
        return (
          <RowContentWrapper>{getConvertedPrice(params)}</RowContentWrapper>
        );
      }
    },
    {
      field: 'actions',
      flex: 0,
      type: 'actions',
      width: 80,
      disableReorder: true,
      getActions: (params: any) =>
        [
          <GridActionsCellItem
            icon={<EditTwoToneIcon />}
            label="Edit"
            onClick={() => {
              navigate(`./form/${params.id}`);
            }}
            disabled={
              !permissionsUtil.canUserEdit() ||
              !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.OFFERS,
                EPermissionAction.MANAGE,
                publisherId,
              )
            }
            showInMenu
          />,
          <GridActionsCellItem
            icon={<ContentCopyOutlinedIcon />}
            label="Duplicate"
            onClick={() => {
              navigate(`./dup/${params.id}`);
            }}
            disabled={
              !permissionsUtil.canUserEdit() ||
              !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.OFFERS,
                EPermissionAction.MANAGE,
                publisherId,
              )
            }
            showInMenu
          />,
          params.row.active &&
          params.row.productsSequence.some(
            ({ priceInUsdCents }: { priceInUsdCents: number }) =>
              priceInUsdCents > 0
          ) &&
          params.row.productsSequence?.some(
            ({ products }: { products: Product[] }) => products?.length > 0
          ) &&
          hasDeeplinkOfferFeatureFlag ? (
            <GridActionsCellItem
              icon={
                generatingDeeplink ? (
                  <CircularProgress size={20} />
                ) : (
                  <LinkIcon />
                )
              }
              label="Get Deeplink"
              onClick={(e: any) => {
                e.stopPropagation();
                handleGetDeeplink(params.row.offerId);
              }}
              disabled={generatingDeeplink}
              showInMenu
            />
          ) : null,
          <GridActionsCellItem
            className="danger"
            icon={<DeleteOutlineTwoToneIcon className="danger" />}
            label="Delete"
            onClick={() => {
              setIsDeleteDialogOpen(true);
              setSelectedOffer(params.id);
            }}
            disabled={
              !permissionsUtil.canUserEdit() ||
              !permissionsUtil.isActionEnabled(
                null,
                EPermissionGroup.OFFERS,
                EPermissionAction.MANAGE,
                publisherId,
              )
            }
            showInMenu
          />
        ].filter(Boolean)
    }
  ];

  const togglePromotionStatus = async (offerId: string, active: boolean) => {
    updateOffer.mutate(
      { offerId, form: { active: !active } },
      {
        onSuccess: () => {
          enqueueSnackbar(
            active
              ? 'Promotion deactivated successfully'
              : 'Promotion activated successfully',
            ENotificationType.SUCCESS
          );
          offers.refetch();
        },
        onError: () => {
          enqueueSnackbar(
            'Error editing special offer',
            ENotificationType.ERROR
          );
        }
      }
    );
  };

  const promotions: Offer[] =
    (!offers.isLoading &&
      offers?.data?.offers &&
      offers?.data?.offers.map((offer: Offer) => ({
        _id: offer.publisherOfferId,
        id: offer.offerId,
        ...offer
      }))) ||
    [];

  const {
    searchValue,
    filterValue,
    setSearchValue,
    setFilterValue,
    filteredData,
    isTableDataEmpty
  } = useTableSearch({
    data: offers.data?.offers,
    publisherId: PublisherIdType.OFFER_ID,
    searchKey: SearchKeyType.NAME,
    additionalPromotionFilter: (item) => {
      if ('schedule' in item) {
        return hasScheduleOfferFeatureFlag
          ? true
          : item.schedule?.permanent || !item.schedule;
      }
      return true;
    }
  });

  return (
    <>
      <AcViewWrapper
        header={
          <PageTopBar
            headline="Promotions"
            buttons={[
              {
                text: 'Add New Promotion',
                action: () => navigate('./form'),
                disabled: !permissionsUtil.canUserEdit(),
                hidden: !permissionsUtil.isActionEnabled(
                  null,
                  EPermissionGroup.OFFERS,
                  EPermissionAction.MANAGE,
                  publisherId,
                )
              }
            ]}
          />
        }
      >
        <AcContentWrapper>
          <TableSearchFilters
            searchValue={searchValue}
            filterValue={filterValue}
            setSearchValue={setSearchValue}
            setFilterValue={setFilterValue}
            tableVariant={'promotion'}
          />
          <DataTable
            pagination={true}
            rowIdIdentifier="offerId"
            columns={columns}
            rows={filteredData()}
            loading={offers.isLoading || offers.isFetching}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            onRowsPerPageChange={(newRowsPerPage) => {
              setRowsPerPage(newRowsPerPage);
              setCurrentPage(0);
            }}
            currentPage={currentPage}
            totalCount={totalCount}
            defaultHiddenFields={[]}
            localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
            hideFooter={false}
            error={false}
            initialSorting={{
              sortModel: [{ field: 'createdAt', sort: 'desc' }]
            }}
            onNoData={
              isTableDataEmpty ? (
                <FirstActionModal
                  headline="Promotions convert better"
                  text="Create your first one by hitting the “Add new Promotion” button on the top right corner of the screen"
                />
              ) : (
                <FirstActionModal
                  headline="No exact matches"
                  text="Try changing or removing your filter or adjusting your search area"
                />
              )
            }
            getRowClassName={appearanceUtils.getRowClassName}
          />
        </AcContentWrapper>
      </AcViewWrapper>

      <DialogModal
        isOpen={isDeleteDialogOpen}
        headline="Delete Promotion"
        text="Are you sure you want to delete this promotion?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setIsDeleteDialogOpen(false);
            }
          },
          {
            text: 'Delete',
            color: EButtonColor.ERROR,
            variant: 'contained',
            func: handleDeleteOffer
          }
        ]}
        closeDialog={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};

export default OffersTable;
