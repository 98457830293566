import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Stack, Typography } from '@mui/material';
import useImages from 'api/useImages';
import useTheme from 'api/useTheme';
import { EAssetType, ENotificationType } from 'constants/enums';
import { useFormikContext } from 'formik';
import { useNotifications } from 'hooks/useNotifications';
import { UploadsTooltips } from 'hooks/useUpload';

import AcCard from 'components/AcCard/AcCard';
import AcSelect from 'components/AcSelect/AcSelect';
import AcSolidInput from 'components/AcSolidInput/AcSolidInput';
import ActionButton from 'components/ActionButton/ActionButton';

import { ThemeLoader } from '../ThemeLoader';
import { validateImageRatio } from '../utils';

import CheckoutThemePreview from './CheckoutThemePreview';
import { CheckoutThemeFormValues } from './types';

import 'style/forms.scss';

const CheckoutTheme = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { values, handleBlur, handleChange, setFieldValue, errors, touched } =
    useFormikContext<CheckoutThemeFormValues>();
  const { publisherId } = useParams();

  const { getCheckoutTheme: checkoutTheme } = useTheme(
    publisherId
  );

  const pictures = useImages(publisherId).getImages;
  const [showPreview, setShowPreview] = useState(false);
  const { enqueueSnackbar } = useNotifications();

  const hasValues = useMemo(() => Object.keys(values).length > 0, [values]);

  useEffect(
    () =>
      setIsLoading(
        checkoutTheme.isLoading || !checkoutTheme.data || !hasValues
      ),
    [checkoutTheme?.isLoading, checkoutTheme?.data, hasValues]
  );

  if (isLoading) {
    return <ThemeLoader />;
  }

  return (
    <>
      {showPreview && (
        <CheckoutThemePreview
          logo={values?.logo}
          bgImage={values?.backgroundImage}
          bgMobileImage={values?.mobileBgImage}
          primaryColor={values?.primaryColor}
          textColor={values?.textColor}
          buttonColor={values?.buttonColor}
          buttonTextColor={values?.buttonTextColor}
          onClose={() => setShowPreview(false)}
        />
      )}
      {pictures.isLoading ? null : (
        <Stack className="formContent" width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Grid container>
              <Grid item xs={5}>
                <Stack gap={3}>
                  <AcCard stackContainer={false} title="Checkout Design">
                    <Grid container rowSpacing={2} columnSpacing={1.5}>
                      <Grid item xs={12}>
                        <AcSelect
                          header="Logo"
                          name="logo"
                          value={values.logo}
                          uploadConfig={{
                            onUploadSuccess: async (uploadData: any) => {
                              await pictures.refetch();
                              setFieldValue(
                                'checkout.logo',
                                uploadData!.data.url
                              );
                            },
                            uploadType: EAssetType.LOGO,
                            uploadMessage: UploadsTooltips[EAssetType.LOGO]
                          }}
                          items={[
                            {
                              content: 'Default Appcharge logo',
                              key: 'https://media.appcharge.com/defaults/logo.svg',
                              value:
                                'https://media.appcharge.com/defaults/logo.svg',
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src="https://media.appcharge.com/defaults/logo.svg"
                                      alt="Default Appcharge logo"
                                      style={{
                                        maxWidth: 26
                                      }}
                                    />
                                    <Typography>
                                      Default Appcharge logo
                                    </Typography>
                                  </Stack>
                                );
                              }
                            },
                            ...pictures.data
                              .filter((p: any) => p.type === EAssetType.LOGO)
                              .map((picture: any) => {
                                return {
                                  content: picture.name,
                                  key: picture.url,
                                  value: picture.url,
                                  renderFunction: () => {
                                    return (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        <img
                                          src={picture.url}
                                          alt={picture.name}
                                          style={{
                                            width: 26,
                                            maxHeight: 30
                                          }}
                                        />
                                        <Typography>{picture.name}</Typography>
                                      </Stack>
                                    );
                                  }
                                };
                              })
                          ]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.logo && Boolean(errors.logo)}
                          tooltip="This will be the logo of your checkout."
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AcSolidInput
                          header="Primary Color"
                          name="primaryColor"
                          defaultValue={values.primaryColor}
                          setValue={setFieldValue}
                          onBlur={handleBlur}
                          tooltip="This will be the primary color of your checkout. It will appear behind the background image in case no background image is present."
                          error={
                            touched.primaryColor && Boolean(errors.primaryColor)
                          }
                          helperText={
                            touched.primaryColor
                              ? errors.primaryColor?.toString()
                              : ''
                          }
                        />
                        <Box mt={3}>
                          <AcSolidInput
                            header="Font Color"
                            name="textColor"
                            defaultValue={values?.textColor}
                            setValue={setFieldValue}
                            onBlur={handleBlur}
                            error={
                              touched.textColor && Boolean(errors.textColor)
                            }
                            helperText={
                              touched.textColor
                                ? errors.textColor?.toString()
                                : ''
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <AcSelect
                          header="Background Image"
                          name="backgroundImage"
                          tooltip="Image should be 2:1 aspect ratio, max 2MB."
                          value={values?.backgroundImage}
                          onClear={() => {
                            setFieldValue('checkout.backgroundImage', '');
                          }}
                          uploadConfig={{
                            onUploadSuccess: async (uploadData: any) => {
                              await pictures.refetch();
                              setFieldValue(
                                'checkout.backgroundImage',
                                uploadData!.data.url
                              );
                            },
                            uploadType: EAssetType.BG_DESKTOP,
                            validator: async (file) => {
                              const isValid = await validateImageRatio(
                                file,
                                2 / 1
                              );
                              if (!isValid) {
                                enqueueSnackbar(
                                  'Image aspect ratio must be 2:1',
                                  ENotificationType.ERROR
                                );
                              }
                              return isValid;
                            }
                          }}
                          items={[
                            {
                              content: 'Default Appcharge background',
                              key: 'https://media.appcharge.com/defaults/background.png',
                              value:
                                'https://media.appcharge.com/defaults/background.png',
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src="https://media.appcharge.com/defaults/background.png"
                                      alt="Default Appcharge background"
                                      style={{
                                        width: 30,
                                        maxHeight: 30
                                      }}
                                    />
                                    <Typography>
                                      Default Appcharge background
                                    </Typography>
                                  </Stack>
                                );
                              }
                            },
                            ...pictures.data
                              .filter(
                                (p: any) => p.type === EAssetType.BG_DESKTOP
                              )
                              .map((picture: any) => {
                                return {
                                  content: picture.name,
                                  key: picture.url,
                                  value: picture.url,
                                  renderFunction: () => {
                                    return (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        <img
                                          src={picture.url}
                                          alt={picture.name}
                                          style={{
                                            width: 30,
                                            maxHeight: 30
                                          }}
                                        />
                                        <Typography>{picture.name}</Typography>
                                      </Stack>
                                    );
                                  }
                                };
                              })
                          ]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.backgroundImage &&
                            Boolean(errors.backgroundImage)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AcSelect
                          header="Background Image (Mobile)"
                          name="mobileBgImage"
                          tooltip="Image should be 1:2 aspect ratio, max 2MB."
                          displayEmpty
                          value={values?.mobileBgImage}
                          onClear={() => {
                            setFieldValue('checkout.mobileBgImage', '');
                          }}
                          uploadConfig={{
                            onUploadSuccess: async (uploadData: any) => {
                              await pictures.refetch();
                              setFieldValue(
                                'checkout.mobileBgImage',
                                uploadData!.data.url
                              );
                            },
                            uploadType: EAssetType.BG_MOBILE,
                            validator: async (file) => {
                              const isValid = await validateImageRatio(
                                file,
                                1 / 2
                              );
                              if (!isValid) {
                                enqueueSnackbar(
                                  'Image aspect ratio must be 1:2',
                                  ENotificationType.ERROR
                                );
                              }
                              return isValid;
                            }
                          }}
                          items={[
                            {
                              content: 'No Background (Taken from Desktop)',
                              key: '',
                              value: '',
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <Typography>
                                      No Background (Taken from Desktop)
                                    </Typography>
                                  </Stack>
                                );
                              }
                            },
                            ...pictures.data
                              .filter(
                                (p: any) => p.type === EAssetType.BG_MOBILE
                              )
                              .map((picture: any) => {
                                return {
                                  content: picture.name,
                                  key: picture.url,
                                  value: picture.url,
                                  renderFunction: () => {
                                    return (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        <img
                                          src={picture.url}
                                          alt={picture.name}
                                          style={{
                                            width: 30,
                                            maxHeight: 30
                                          }}
                                        />
                                        <Typography>{picture.name}</Typography>
                                      </Stack>
                                    );
                                  }
                                };
                              })
                          ]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.mobileBgImage &&
                            Boolean(errors.mobileBgImage)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} mt={0}>
                        <AcSolidInput
                          header="Button Color"
                          name="buttonColor"
                          defaultValue={values?.buttonColor}
                          setValue={setFieldValue}
                          onBlur={handleBlur}
                          error={
                            touched.buttonColor && Boolean(errors.buttonColor)
                          }
                          helperText={
                            touched.buttonColor
                              ? errors.buttonColor?.toString()
                              : ''
                          }
                        />
                        <Box mt={3}>
                          <AcSolidInput
                            header="Button Font Color"
                            name="buttonTextColor"
                            defaultValue={values.buttonTextColor}
                            setValue={setFieldValue}
                            onBlur={handleBlur}
                            error={
                              touched.buttonTextColor &&
                              Boolean(errors.buttonTextColor)
                            }
                            helperText={
                              touched.textColor
                                ? errors.textColor?.toString()
                                : ''
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </AcCard>
                </Stack>
                <Box mt={4}>
                  <ActionButton
                    variant="outlined"
                    size="small"
                    onClick={() => setShowPreview(true)}
                    sx={{
                      textTransform: 'capitalize'
                    }}
                    text="Preview (Web and Mobile)"
                  />
                </Box>
              </Grid>
            </Grid>
            <Stack gap={1} mr={-3}>
              {/* <ActionButton
                    variant="outlined"
                    size="small"
                    onClick={() => setShowPreview(true)}
                    sx={{
                        textTransform: 'capitalize'
                    }}
                    text={'Preview Mobile'}
                /> */}
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default CheckoutTheme;
